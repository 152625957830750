import React from 'react';
import { ModalContext } from '../contexts/ModalContext';
import styles from './Login.module.scss';

const CartControllerPanelErrorPassesCreditValueZero = ({ show, onDone}) => {


    const {modalOpen, modalClose} = React.useContext(ModalContext);
  


    React.useEffect(()=>{
        modalOpen();
    }, []);

    const handleClose = () => {
        modalClose();
        onDone();
    }



    return (
        <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, display: show ?'block':'none'}}>
            <div className={"modal fade-in2s "  + ( show? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                <div className={"modal-dialog "+ styles.p__modal} role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Error</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form >
                        {
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 mb-2 mt-2">
                                        <p className="mb-2">This event cannot be booked with a pass. Please select an alternative payment method if available or contact us.</p>
                                    </div>
                                </div>

                            </div>

                        }
                    </form>  
                    </div>
                    <div className="modal-footer">
                        <div className={styles.p__rVieModalFooterText}>What would you like to do?</div>
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>Cancel</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
  

  export default CartControllerPanelErrorPassesCreditValueZero;