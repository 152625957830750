

export const CookieReducer = (state, action) => {
    switch (action.type) {
        case "ALLOW_3RD_PARTY":
        {
                state.allowed = true;
                return {
                    ...state,
                }                 
        }
        case "DISALLOW_3RD_PARTY":
        {
                state.allowed = false;
                return {
                    ...state,
                }                 
        }


        default:
            return state

    }
}