import moment from 'moment';
import React, {createContext, useReducer } from 'react';
import {CartControllerReducer} from './CartControllerReducer';
import {filter} from "./CartControllerReducer";

export const CartControllerContext = createContext()
const hash = btoa(encodeURIComponent(window.location.hostname));

function sessionGet( what )
{
    try{
        if (typeof sessionStorage !== "undefined")
            return sessionStorage.getItem(what);
        else
            return  JSON.stringify({ card: [], pass: [], free:[], subscription:[]});
    }
    catch(e)
    {
        return  JSON.stringify({ card: [], pass: [], free:[], subscription:[]});
    }
}


const transactions =  JSON.parse(sessionGet(hash)) || { card: [], pass: [], free:[], subscription:[]}; 




export const OBJECT_TYPE_EVENT_INDIVIDUAL = 'OBJECT_TYPE_EVENT_INDIVIDUAL';
export const OBJECT_TYPE_EVENT_BLOCK      = 'OBJECT_TYPE_EVENT_BLOCK';
export const OBJECT_TYPE_EVENT_FLEXIBLE   = 'OBJECT_TYPE_EVENT_FLEXIBLE';


export const OBJECT_TYPE_PASS = 'OBJECT_TYPE_PASS';
export const OBJECT_TYPE_SUBSCRIPTION = 'OBJECT_TYPE_SUBSCRIPTION';
export const OBJECT_TYPE_VIDEO = 'OBJECT_TYPE_VIDEO';

const initialState = { 
    event: [],
    queue: [],
    wallets: filter(transactions),
    initialized: false,
  };

const CartControllerContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(CartControllerReducer, initialState);


    const initializeCart = payload => {
        dispatch({type: 'INITIALIZE_CART', payload})
    }

    const pushEvent = payload => {
        dispatch({type: "PUSH_EVENT", payload})
    }
    const emptyCart = () => {
        dispatch({type: "EMPTY_CART"})
    }

    const pushVideo = payload => {
        dispatch({type: "PUSH_VIDEO", payload})
    }

    const removeItem = (key_1, specifier) =>{
        //console.log('removing objects', key_1);
        var payload = {
            key_1: key_1,
            specifier: specifier
        };
        dispatch({type: 'POP_OBJECT_FROM_WALLET', payload})
    }
    



    const pushProcessedToCardWallet = ( payload ) => {
        dispatch({type: 'PUSH_OBJECT_TO_CARD_WALLET', payload})
    }

    const pushProcessedToPassWallet = ( payload ) => {
        dispatch({type: 'PUSH_OBJECT_TO_PASS_WALLET', payload})
    }

    const attachPassWallet = ( payload ) => {
        dispatch({type: 'ATTACH_PASS_WALLET', payload})
    }

    const attachSubscriptionWallet = ( payload ) => {
        dispatch({type: 'ATTACH_SUBSCRIPTION_WALLET', payload})
    }

    

    const switchPassPendingMode = (  ) => {
        dispatch({type: 'SWITCH_PENDING_PASS_WALLET'})
    }

    const clearPassPending= (  ) => {
        dispatch({type: 'CLEAR_PENDING_PASS_WALLET'})
    }

    const popEvent = () => {
        dispatch({type: 'POP_EVENT'})
    }


    const sumItems = () => {

        let passItemCount = 0;
        let subscriptionItemCount = 0;
        let cartItemCount = 0;
        let cardItemCount = 0;
        let freeItemCount = 0;
        let cardTotal = 0;
        let passTotal = 0.00;
        let subscriptionTotal = 0.00
        cardItemCount = state?.wallets?.card.reduce((cardItemCount, transaction) => {
            let t = 0
            t = transaction.objects.reduce((t, product) => t + parseInt(product.qty), 0);
            return cardItemCount+ t;
        }, 0);

        for(var _zz = 0; _zz < state?.wallets?.pass.length; _zz++ )
        {
            if(state.wallets.pass[_zz].transactions.length > 0)
            {
                passItemCount += state.wallets.pass[_zz].transactions.reduce((passItemCount, transaction) => {
                    let t = 0
                    t = transaction.objects.reduce((t, product) => t + parseInt(product.qty), 0);
                    return passItemCount+ t;
                }, 0);            
            }
        }
        freeItemCount = state?.wallets?.free.reduce((freeItemCount, product) => freeItemCount + product.qty, 0);

        passItemCount += state?.wallets?.pass.reduce((passItemCount, product) => {
            if(product.mode === 'debit' || product.mode === 'pending')
                return passItemCount;
            else
                return passItemCount + 1
        }, 0);            
        
        subscriptionItemCount += state?.wallets?.subscription.reduce((subscriptionItemCount, product) => {
            return subscriptionItemCount + 1
        }, 0);  

        //console.log("subscription items count", subscriptionItemCount, state.wallets.subscription);

        cartItemCount += passItemCount;
        cartItemCount += freeItemCount;
        cartItemCount += subscriptionItemCount;
        cartItemCount += cardItemCount;

        cardTotal = state?.wallets?.card.reduce((cardTotal, transaction) => {
            let t = 0;
            t = transaction.objects.reduce((t, product) => {
                return (t + parseFloat(product.key_3.price) * parseInt(product.qty))    
            }, 0);               
            return t + parseFloat(cardTotal);
        }, 0);



   
        passTotal = state?.wallets?.pass.reduce((passTotal, product) => {
//            console.log("product.mode", product.mode, passTotal);
            if(product.mode === 'credit')
            {
                const t = parseFloat(passTotal) +  parseFloat(product.price);
//                console.log( "pass total ", t);
                return t;
            }
            else if(product.mode === 'debit')
            {

//                console.log( "pass total invalid");
                return parseFloat(passTotal);
            }
        }, 0);
    
        subscriptionTotal = state?.wallets?.subscription.reduce((subscriptionTotal, product) => {                
            const t = parseFloat(subscriptionTotal) +  parseFloat(product.price);
            return t;
        }, 0);

  //      console.log("final pass total", passTotal);

        return { cartItemCount, passItemCount, cardItemCount, cardTotal, passTotal, subscriptionItemCount, subscriptionTotal };
    }

    const isPassInCart = (key_1) => {

        //console.log('looking for ', key_1);
        let search = state.wallets.pass.find( pass => {
            //console.log('checking match', pass.id, key_1);
            return pass.parent === key_1 && pass.mode === 'credit';
        });
        return !!search;
    }

    const countPassInCart = (key_1) => {

        //console.log('looking for ', key_1);
        const l = state.wallets.pass.filter( pass => {
           // console.log('is',  pass.parent, key_1, pass.mode);
            return pass.parent === key_1 && pass.mode === 'credit';
        }).length;

        //console.log('length', key_1, l);
        return l;
    }


    const isVideoInCart = ( key ) => {
        let search = state.wallets.card.find( transaction => {
            return !!transaction.objects.find( item => item.key_1 === key );
        });
        return !!search;
        /// add pass here
    }


    const isInCart = (key_1, key_2) => {
        let search = state.wallets.card.find( transaction => {
            return !!transaction.objects.find( item => item.key_1 === key_1 && item.key_2 === key_2 );
        });
        if(!!!search)
        {

            search = state.wallets.pass.find( pass => {
                        return pass.transactions.find( transaction => {
                            return !!transaction.objects.find( item => item.key_1 === key_1 && item.key_2 === key_2 );
                        });
                    });
            if(!!search)
            {
                search = search.transactions.find(transaction => {
                    return !!transaction.objects.find( item => item.key_1 === key_1 && item.key_2 === key_2 );
                });                
            }

        }
        if(!!!search)
        {
            search = state.wallets.free.find( item => item.key_1 === key_1 && (item.key_2 === key_2  ));
        }
       // console.log("got match", search, key_1, key_2);
        return search;
    }

    const countInCart = (key_1, key_2) => {

        //console.log('wallet', state.wallets);
        let t = 0;
        t =  state.wallets.card.reduce((t, o) => {
            //console.log('wallet', o);
            return t + o.objects.reduce((x, item) => {
                //console.log('searching for ', item.key_1, key_1);
                if(item.key_1 === key_1 && item.key_2 === key_2)
                {
                    return x + 1;
                }
                return x;
            }, 0);
        }, 0);

        t +=  state.wallets.pass.reduce((t, o) => {
            //console.log(' pass wallet', o);
            return t + o.transactions.reduce((x, transaction) => {
                //console.log(' pass wallet transactions', transaction);
                return x + transaction.objects.reduce((z, item) => {
                    //console.log(' pass searching for ', item, key_1);
                    if(item.key_1 === key_1 && item.key_2 === key_2)
                    {
                        return z + 1;
                    }
                    return z;
                }, 0);    
            }, 0);
        }, 0);

        //console.log("returning for ", key_1, key_2, t);
        return t;
    } 
    
    const countInCartStrict = (key_1, key_2, key_3) => {

        //console.log('wallet', state.wallets);
        let a =  state.wallets.card.reduce((t, o) => {
            //console.log('wallet', o);
            let x = 0;
            return t + o.objects.reduce((x, item) => {
                //console.log('searching for ', item.key_1, key_1);
                if(item.key_1 === key_1 && item.key_2 === key_2 && item.key_3.id === key_3.id)
                {
                    return x + 1;
                }
                return x;
            }, 0);
        }, 0);

        let b =  state.wallets.pass.reduce((t, o) => {
            //console.log(' pass wallet', o);
            let x = 0;
            return t + o.transactions.reduce((x, transaction) => {
                let z = 0
                //console.log(' pass wallet transactions', transaction);
                return x + transaction.objects.reduce((z, item) => {
                    //console.log(' pass searching for ', item, key_1);
                    if(item.key_1 === key_1 && item.key_2 === key_2 && item.key_3.id === key_3.id)
                    {
                        return z + 1;
                    }
                    return z;
                }, 0);    
            }, 0);
        }, 0);

        //console.log("returning for ", key_1, key_2, t);
        return a + b;
    }     

    const getCardTransactionValue = ( id, index ) => {
        const search = state.wallets.card.findIndex( (transaction, i) => transaction.id === id && i === index);
        let t = 0;  
        if(search >= 0)
        {
            t = state.wallets.card[search].objects.reduce((t, object) => 
                { 
                    return t + (parseFloat(object.key_3.price) * parseInt(object.qty))
                }
            , 0);
        }
        return t;
    }


    const fetchPassAvailability = (key_1) => {
        let search = state.wallets.pass.find( (item ) => {
               return item.id == key_1;
        });

        if(!!search)
        {
            let used = 0;
            const credits_available = parseInt(search.credits);

            used = search.transactions.reduce((used, transaction) => {
                let t = 0
                t = transaction.objects.reduce((t, product) => t + (parseInt( product.key_3.credits ) * parseInt(product.qty)), 0);
                return used+ t;
            }, 0);  

            return credits_available - used;            
        }

        return 0;
    }

    const isWalletInCart = (key_1) => {
        let search = state.wallets.pass.find( pass => pass.parent === key_1 );
        return !!search;
    }   
    
    const isSpecificWalletInCartWithPassMode = (key_1, mode) => {
        let search = state.wallets.pass.find( pass => pass.id === key_1 && pass.mode === mode );
        return !!search;
    }      


    const getWalletFromCartIfAvailable = (key_1, credits, expires) => {
        //console.log("searching for", key_1, credits)
        const search = state.wallets.pass.sort((a, b) => moment(a.expires, 'DD-MM-YYYY').isBefore(moment(b.expires, 'DD-MM-YYYY')) ? -1 : 1, ).find( pass => {
            if(pass.parent === key_1)
            {

                if(pass.expires !== null)
                {
                    if(moment(pass.expires).isBefore(expires))
                    {
                        return false;
                    }
                }
                if(pass.starts !== null)
                {
                    // does the pass start after the event date
                    if(moment(pass.starts).isAfter(expires))
                    {
                        return false;
                    }
                }

                let credits_used        = 0;
                const credits_available = parseInt(pass.credits);
                credits_used = pass.transactions.reduce((credits_used, transaction) =>{
                    let t = 0;
                    t = transaction.objects.reduce((t, product) => {
                        return t + (parseInt(product.qty) * parseInt(product.key_3.credits))
                    }, 0);
                    return t + credits_used;
                } , 0);


                return ((credits_available - credits_used) >= credits)
    
            }
            return false;
         } );
        //console.log('search result', search);   
        return search;
    } 

 


    const fetchSpecificWalletIfAvailable = (key_1, credits) => {
        const search = state.wallets.pass.find( pass => pass.id === key_1 );

        if(!!search)
        {
            let credits_used        = 0;
            const credits_available = parseInt(search.credits);
            credits_used = search.transactions.reduce((credits_used, product) => credits_used + (parseInt(product.qty) * parseInt(product.key_3.credits)), 0);

            if( ((credits_available - credits_used) >= credits))
            {
                return search;
            }

        }
        return false;
    } 

    const isEligiblePass = (key_1, credits, date) => {
        const search = fetchSpecificWalletIfAvailable(key_1, credits);
        if(!!search)
        {
            if(search.mode === 'debit')
            {
                const e = moment(search.expires);
                if(e.isBefore(moment(date)))
                {
                    return false
                }
                return true;
            }
            else if(search.mode === 'credit')
            {
                const e = moment().add(search.expires, 'days');
                if(e.isBefore(moment(date)))
                {
                    return false
                }
                return true;
            }
        }

    }

    const findEligiblePassFromSet = (passes, credits, date) => {
        
       const search = passes.find( pass => isEligiblePass(pass.id, credits, date));
       return search;

    }

    const fetchPendingPassWallets = () => {
        return state.wallets.pass.filter( pass => pass.mode === 'pending');
    }

    const detachPass = ( payload ) => {
        dispatch( {type: 'DETACH_PASS_WALLET', payload})
    }

    const emptyPass = ( payload ) => {
        dispatch( {type: 'EMPTY_PASS_WALLET', payload})
    }

    const detachSubscription = ( payload ) => {
        dispatch( {type: 'DETACH_SUBSCRIPTION_WALLET', payload})

    }


    const decrementPassEventQty = ( payload ) => {
        dispatch( {type: 'DECREMENT_PASS_WALLET_EVENT_QTY', payload})
    }


    const incrementPassEventQty = ( payload ) => {
        dispatch( {type: 'INCREMENT_PASS_WALLET_EVENT_QTY', payload})
    }

    const decrementCardEventQty = ( payload ) => {
        dispatch( {type: 'DECREMENT_CARD_WALLET_EVENT_QTY', payload})
    }


    const incrementCardEventQty = ( payload ) => {
        dispatch( {type: 'INCREMENT_CARD_WALLET_EVENT_QTY', payload})
    }

    

    const contextValues = {
        initializeCart,
        emptyCart,
        pushEvent,
        pushVideo,
        popEvent,
        removeItem,
        pushProcessedToCardWallet,
        attachPassWallet,
        attachSubscriptionWallet,
        pushProcessedToPassWallet,
        isInCart,
        isVideoInCart,
        isPassInCart,
        countInCart,
        countInCartStrict,
        countPassInCart,
        isWalletInCart,
        getWalletFromCartIfAvailable,       
        fetchPassAvailability,
        isEligiblePass,
        findEligiblePassFromSet,
        fetchPendingPassWallets,
        detachPass,
        emptyPass,
        detachSubscription,
        decrementPassEventQty,
        incrementPassEventQty,
        decrementCardEventQty,
        incrementCardEventQty,
        switchPassPendingMode,
        clearPassPending,
        isSpecificWalletInCartWithPassMode,
        getCardTransactionValue,
        ...sumItems(),
        ...state
    } 

    return ( 
        <CartControllerContext.Provider value={contextValues} >
            <React.Fragment>
            { children }
            </React.Fragment>
        </CartControllerContext.Provider>
     );
}
 
export default CartControllerContextProvider;