import React, { createContext, useReducer } from 'react';
import { JwtReducer } from './JwtReducer';

export const JwtContext = createContext();


function getJwt(){
    try{
        if(typeof sessionStorage !== 'undefined' )
        {
            return sessionStorage.getItem('rvAT') || '';
        }
        return 'not defined';
    }

    catch(e)
    {
        return 'not defined';
    }
}


function setJWT( jwt ){
    //console.log('saving token', jwt);
    try{
        if(typeof sessionStorage !== 'undefined' )
        {
            sessionStorage.setItem('rvAT', jwt);
        }
    }
    catch(e)
    {
        console.error('session storage unavailable');
    }        
}


const initialState = { 
                        jwt: getJwt()
                     };



const JwtContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(JwtReducer, initialState);

    const store = (payload, persist) => {
        setJWT(payload);
        if ( window.location !== window.parent.location ) {
            // The page is in an iframe
            window.parent.postMessage({'message':'rvToken', 'value': payload, 'persist': persist}, '*');
          } else {
            // The page is not in an iframe
            
          }
        dispatch({type: 'UPDATE_JWT', payload})
    }


    const storeNoBroadCast = payload => {
        dispatch({type: 'UPDATE_JWT', payload})
    }

    const contextValues = {
        store,
        storeNoBroadCast,
        ...state
    } 

    return ( 
        <JwtContext.Provider value={contextValues} >
            { children }
        </JwtContext.Provider>
     );
}
 
export default JwtContextProvider;
