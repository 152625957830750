import React, { useContext } from 'react';
import Layout from '../../components/Layout';

import CartProducts from './CartProducts';
import { PreferenceContext } from '../../contexts/PreferenceContext';
import { useHistory } from 'react-router-dom';

const Cart = () => {

   // const { total, credits, cartItems, itemCount, clearCart, checkout, handleCheckout, creditsonly, cardonly, free, cardonlyCount, passonlyCount } = useContext(CartContext);
    //const { wallets } = useContext(CartControllerContext);
    const { preference } = useContext(PreferenceContext);
    const history = useHistory();



    const onCheckout = () =>{
        history.push('/checkout');
    }

    
    return ( 
        <Layout title="Cart" description="This is the Cart page" >
            
            <div className="text-left mt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 mb-1">
                            <h1>Cart</h1>
                            <p>The following items are ready for booking and or payment.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 mb-1">
                            <CartProducts />
                        </div>
                    </div>
                </div>
            </div>    
        </Layout>
     );
}
 
export default Cart;