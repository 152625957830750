import React, { useContext} from 'react';
import { PreferenceContext } from '../../contexts/PreferenceContext';
import { locationList } from '../../services/scheduleList';
import { isCardandPass, isCardOnly, isFree, isPassOnly } from '../../contexts/ScheduleContext';
import { formatNumber } from '../../helpers/utils';
import styles from './ProductsGrid.module.scss';
import { ModalContext } from '../../contexts/ModalContext';
import Parser from 'html-react-parser';

const DetailsPanel = ({ show, onCancel, object}) => {

    const { preference } = useContext(PreferenceContext);
    const location = locationList.find( l => l.id === object.locationid);
    const {modalOpen, modalClose} = React.useContext(ModalContext);

    console.log(location);

    const nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    const handleClose = () => {
        modalClose();
        onCancel()
    }

    React.useEffect(()=>{
        if(show === true)
        {
            modalOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <div className="overlay" style={{'zIndex': 999, 'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, display: show ?'block':'none'}}>
            <div className={"modal fade-in2s "  + ( show? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                <div className={"modal-dialog modal-lg "+ styles.p__modal} role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Event details</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form >
                        {
                            <div className="container-fluid">
                                <div className={"row "}>
                                    <div className="card col-12 p-2 m-2 box-shadow border-1 text-left"  >
                                        <div className="card-body  d-flex flex-column pt-4 pb-2">
                                            <h5 className="card-title">{object.name}</h5>
                                            <div className="card-text">
                                                <div className="container-fluid no-gutters">
                                                    <div className="row mb-4">
                                                        <div className="col-12 mb-2">
                                                            {Parser(nl2br(object.description, false))} 
                                                        </div>    
                                                        <div className="col-12 mb-1">
                                                            <span className={styles.p__rVieStrong}>Type:</span> {isPassOnly(object.payment_type) && <span>Pass payment required</span>} {isCardOnly(object.payment_type) && <span>Card payment required</span>} {isCardandPass(object.payment_type) && <span>Card or Pass payment required</span>} {isFree(object.payment_type) && <span>Free class</span>}   
                                                        </div>                                                    
                                                        <div className="col-12">
                                                            <div className={styles.p__rVieStrong}>Ticket options:</div> 
                                                            <ul className="mb-1">
                                                                {isPassOnly(object.payment_type) && object.filtered_object.filter( o => {return o.deleted !== true }).map((po) => <li key={po.id}>{po.name + ': '}{po.credits} credit(s)</li>)}
                                                                {isCardOnly(object.payment_type) && object.filtered_object.filter( o => {return o.deleted !== true }).map((po) => <li key={po.id}>{po.name + ': '}{formatNumber(parseFloat(po.price), preference.currency)}</li>)}
                                                                {isFree(object.payment_type) && object.filtered_object.filter( o => {return o.deleted !== true }).map((po) => <li key={po.id}>{po.name + ': '}{formatNumber(0, preference.currency)}</li>)}
                                                                {isCardandPass(object.payment_type) && object.filtered_object.filter( o => {return o.deleted !== true }).map((po) => <li key={po.id}>{po.name + ': '}{formatNumber(parseFloat(po.price), preference.currency)} or {po.credits} credit(s)</li>)}
                                                            </ul>
                                                        </div>
                                                        {
                                                            parseInt(object.duration) > 0 &&
                                                            <div className="col-12 mb-1">
                                                                <span className={styles.p__rVieStrong}>Duration: </span>  <span>{Math.floor(parseInt(object.duration)/60)} hour(s)</span>  
                                                                {
                                                                    <React.Fragment>
                                                                        {
                                                                             (parseInt(object.duration)%60) > 0 &&
                                                                            <span>{parseInt(object.duration)%60} minute(s)</span>  
                                                                        }
                                                                    </React.Fragment>
                                                                    
                                                                }
                                                            </div> 
                                                        } 
                                                        {
                                                            object.facilitators && object.facilitators.length > 0 &&
                                                            <div className="col-12 mb-1">
                                                                <div className={styles.p__rVieStrong}>Teacher:</div> 
                                                                <ul>
                                                                    {object.facilitators.map(f => <li key={f.id}>{f.firstname} {f.lastname}</li>)}
                                                                </ul>
                                                            </div>                                                                           
                                                            
                                                                    }

                                                    </div>
                                                    {
                                                    (typeof location === 'object') &&
                                                    <div className="row mt-2 mb-2">
                                                        <div className="col-12 mb-2"><h5>Location:</h5></div>
                                                        
                                                        <div className="col-12">{location.name}</div>
                                                        <div className="col-12">{location.address_1}</div>
                                                        <div className="col-12">{location.address_2}</div>
                                                        <div className="col-12">{location.town}</div>
                                                        <div className="col-12">{location.country}</div>
                                                        <div className="col-12">{location.postcode}</div>
                                                    </div>
                                                    }

                                                    <div className="row mb-4 mt-2 no-gutters">
                                                        <div className="col-12">
                                                            {
                                                                (typeof location === 'object') && (location.enable === "1") &&
                                                                <iframe src={window.rv_reservie_address + 's-map/' + object.locationid } title="Location" id="rvCartCheckout" scrolling="no" className={styles.p__rvFrame} style={{height: 300 + 'px', 'width': '100%', 'border': 'none' }} /> 
                                                            }                                                                        
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>                                                                                                                                                                                 
                                        </div>
                                    </div> 
             
                                </div>

            
                            </div>

                        }
                    </form>  
                    </div>
                    <div className="modal-footer">
                        &nbsp;
                    </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
  

  export default DetailsPanel;