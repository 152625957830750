import React from 'react';
import styles from './footer.module.scss';

const Footer = () => {
    var d = new Date();
    return ( 
        <div>
        {
            window.location === window.parent.location && 
            <footer className={`${styles.footer}  mt-5 p-3`}>
                2015 - {d.getFullYear()} Powered by reservie Ltd (reservie.net) 
            </footer>
        }
        </div>

     );
}
 
export default Footer;