import React, { useContext} from 'react';
import styles from './Subscriptions.module.scss';


const ConfirmSubscriptionsNotEmpty = ({show, onContinue, onCancel}) => {





    const handleClose = () => {
        onContinue();
    }


    return (
        <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, display: show ?'block':'none'}}>
            <div className={"modal fade-in2s "  + ( show? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                <div className={"modal-dialog modal-lg "+ styles.p__modal} role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add to cart</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    {
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 mb-1">
                                    <p className="mb-2">You already have items in your cart. A subscription can only be used to book events after it's setup has been completed.</p>
                                    <p>If you would like to use this subscription to book events, please complete the subscription setup first. You may need to remove one or more events from your cart.</p>
                                </div>
                            </div>
                        </div>

                    }
                         
                    </div>
                    <div className="modal-footer">
                        <div className={styles.p__rVieModalFooterText}>What would you like to do?</div>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onCancel}>Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={onContinue}>Continue</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
  

  export default ConfirmSubscriptionsNotEmpty;