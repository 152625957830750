import React, { useContext} from 'react';
import styles from './Login.module.scss';
import Moment from 'react-moment';
import { formatNumber } from '../helpers/utils';
import { PreferenceContext } from '../contexts/PreferenceContext';
import { ModalContext } from '../contexts/ModalContext';


const CartControllerPanelConfirmSpaces = ({show, objects, onAddDates, max, onCancel}) => {

   // const { cache, clearCache, paymentOptions } = useContext(CartContext);

   const { preference } = useContext(PreferenceContext);
   const [q, setQ] = React.useState(max>0?1:0);
   const {modalOpen, modalClose} = React.useContext(ModalContext);
  


   React.useEffect(()=>{
       modalOpen();
   }, []);

    const calculatePriceCard = () => {
        let t = 0;
        t = objects.reduce( (t, o)=>{
            return t + (parseFloat(o.key_3.price) * parseFloat(o.qty));
        }, 0);
        return t;
    } 


    const calculatePassCard = () => {
        let t = 0;
        t = objects.reduce( (t, o)=>{
            return t + (parseFloat(o.key_3.credits) * parseFloat(o.qty));
        }, 0);
        return t;
    } 




    const handleClose = () => {
        modalClose();
        onCancel();
    }

    const onContinue = () => {

        const date_objects_to_add = objects.map( o => {
                return {
                    key_1: o.key_1,
                    key_2: o.key_2,
                    key_3: o.key_3,
                }
        });        
        modalClose();
        onAddDates(date_objects_to_add, q);
    }
  
    return (
        <form>
            <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, display: show ?'block':'none'}}>
                <div className={"modal fade-in2s "  + ( show? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 mb-2 mt-2">
                                        <p>Thank you, please confirm the following booking. </p>
                                    </div>
                                </div>
                                <div className="card card-body border-1">
                                    <div className="row">
                                        <div className="col-3 mb-2">
                                            <h6 className={styles.p__strong}>Date</h6>
                                        </div>
                                        <div className="col-6 text-left mb-2">
                                            <h6 className={styles.p__strong}>Time</h6>
                                        </div>                                   
                                        <div className="col-3 text-center mb-2">
                                            <h6 className={styles.p__strong}>Price</h6>
                                        </div>
                                    </div>
                                    {
                                        objects.map( object => (
                                                
                                            <div className="row" key={object.key_1 + '-' + object.key_2}>
                                                <div className="col-3 mb-1"><Moment format="LL">{object.date + "T" + object.time }</Moment></div>
                                                <div className="col-6 text-left mb-1"><Moment format="hh:mm a">{object.date + "T" + object.time}</Moment></div>
                                                {
                                                    parseInt(object.payment_type) === 0x01 &&
                                                    <div className="col-3 text-center mb-1">{object.key_3.credits}</div>
                                                }
                                                {
                                                    parseInt(object.payment_type) === 0x02 &&
                                                    <div className="col-3 text-center mb-1">{formatNumber(object.key_3.price, preference.currency)}</div>
                                                }
                                                {
                                                    parseInt(object.payment_type) === 0x03 &&
                                                    <div className="col-3 text-center mb-1">{formatNumber(object.key_3.price, preference.currency)} or {object.key_3.credits} credits</div>
                                                }
                                                {
                                                    parseInt(object.payment_type) === 0x04 &&
                                                    <div className="col-3 text-center mb-1">{formatNumber(0, preference.currency)}</div>
                                                }                                            
                                            </div>
                                        ))

                                    }
                                    <div className="row border-top pt-3">
                                        <div className="col-9 mb-1 text-right">
                                            <p className={styles.p__quantity}>How many people are you booking for?</p>
                                        </div>                                  
                                        <div className="col-3 mb-1">
                                            <div className="form-group text-left">
                                                <input type="number" autoFocus className={"form-control text-center " + styles.p__quantityValue} value={q} onChange={(e)=>{setQ(e.target.value)}} min="1" max={max} required /> 
                                            </div>                                                                              
                                        </div>

                                    </div> 
                                    <div className="row border-top pt-2">
                                        <div className="col-9 mb-2 text-right">
                                            <p className={styles.p__strong}>Total</p>
                                        </div>                                  
                                        <div className="col-3 text-center mb-2">
                                            {
                                                    parseInt(objects[0].payment_type) === 0x01 &&
                                                    <p className={styles.p__strong}>{ q * calculatePassCard()}</p>
                                            }
                                            {
                                                    parseInt(objects[0].payment_type) === 0x02 &&
                                                    <p className={styles.p__strong}>{formatNumber(q *calculatePriceCard(), preference.currency)}</p>
                                            }
                                            {
                                                    parseInt(objects[0].payment_type) === 0x03 &&
                                                    <p className={styles.p__strong}>{q * formatNumber(q *calculatePriceCard(), preference.currency)} or {calculatePassCard()}</p>
                                            }  
                                            {
                                                    parseInt(objects[0].payment_type) === 0x04 &&
                                                    <p className={styles.p__strong}>Free</p>
                                            }                                                                                
                                        </div>
                                    </div>                                 
                                    </div>          
                                </div>
                        </div>
                        <div className="modal-footer">
                            <div className={styles.p__rVieModalFooterText}>What would you like to do?</div>
                            <button type="submit" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>Cancel</button>
                            <button type="submit" className="btn btn-primary" onClick={onContinue}>Add to cart</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    );
  }
  

  export default CartControllerPanelConfirmSpaces;