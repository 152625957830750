import React, { useContext, useState} from 'react';
import styles from './Login.module.scss';
import { formatNumber } from '../helpers/utils';
import { PreferenceContext } from '../contexts/PreferenceContext';
import { ModalContext } from '../contexts/ModalContext';


const CartControllerPanelSelectAcceptedPass = ({ show, acceptedPasses, onContinue, onCancel}) => {

    const { preference } = useContext(PreferenceContext);
    const [selectedPass, setSelectedPass] = useState( acceptedPasses[0] );
    const {modalOpen, modalClose} = React.useContext(ModalContext);
  


    React.useEffect(()=>{
        modalOpen();
    }, []);


    const handleClose = () => {
        modalClose();
        onCancel();
    }

    const onChange = ( event ) => {
        let search = acceptedPasses.find((item) => item.id === event.target.value);
        if(!!search)
        {
            setSelectedPass( search );
        }
    }


    const onDoContinue = ( ) => {
        modalClose();
        onContinue(selectedPass);
    }

    return (
        <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, display: show ?'block':'none'}}>
            <div className={"modal fade-in2s "  + ( show? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                <div className={"modal-dialog modal-lg "+ styles.p__modal} role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Select pass</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    {
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 mb-1">
                                    <p>This selection requires a pass. Select one of the following:</p>
                                </div>
                            </div>
                            <div className="card card-body border-1">
                                <div className="row">
                                    <div className="col-sm-12 mt-2 mb-2">                                                                       
                                        <h6>Buy a new pass</h6>

                                        {
                                            acceptedPasses.length > 0 && (
                                                <div>   
                                                    <p>To buy a pass now, please select one of the following options below:</p>
                                                    {
                                                        acceptedPasses.filter(pass => {return parseFloat(pass.price) >= 0 && parseInt(pass.subscriptionlimited) === 0} ).map( (item, index) => (
                                                            <React.Fragment key={`pass-${item.id}`}>
                                                            {
                                                                parseInt(item.period) === 0 && (
                                                                <div className="form-check" key={'accepted-pass-' + item.id}>
                                                                    <input className="form-check-input" type="radio" name="passRadios" id={"passRadio-" + item.id} value={item.id} checked={selectedPass.id === item.id} onChange={onChange} />
                                                                    <label className="form-check-label" htmlFor={"passRadio-" + item.id}>
                                                                        {item.name}. Expires: +{item.expires} days - activated on the date of the first event selected or the date of a video being purchased. Credits: {item.credits}. Price: {formatNumber(parseFloat(item.price), preference.currency)}
                                                                    </label>
                                                                </div>) 
                                                            }
     
                                                            {
                                                                parseInt(item.period) === 1 && (
                                                                <div className="form-check" key={'accepted-pass-' + item.id}>
                                                                    <input className="form-check-input" type="radio" name="passRadios" id={"passRadio-" + item.id} value={item.id} checked={selectedPass.id === item.id} onChange={onChange} />
                                                                    <label className="form-check-label" htmlFor={"passRadio-" + item.id}>
                                                                        {item.name}. Expires: +{item.expires} months - activated on the date of the first event selected or the date of a video being purchased. Credits: {item.credits}. Price: {formatNumber(parseFloat(item.price), preference.currency)}
                                                                    </label>
                                                                </div>)   
                                                            }
                                                            </React.Fragment>                                                                                                                       
                                                        ))   
                                                    }
                                                </div>
                                            )
                                        }                                        
                                    </div>
                                </div>
                            </div>



                        </div>

                    }
                         
                    </div>
                    <div className="modal-footer">
                        <div className={styles.p__rVieModalFooterText}>What would you like to do?</div>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={onDoContinue} disabled={selectedPass.type === 'pending'}>Proceed</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
  

  export default CartControllerPanelSelectAcceptedPass;