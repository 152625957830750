import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { CartControllerContext } from '../../contexts/CartControllerContext';
import { CookieContext } from '../../contexts/CookieContext';
import {CartIcon} from '../icons';
import styles from './header.module.scss';
import {passList, videoList, subscriptionList} from '../../services/scheduleList'

const Header = () => {

    const {cartItemCount} = useContext(CartControllerContext);
    const {allow3rdparty, disallow3rdparty} = useContext(CookieContext);
    const [registered, setRegistered] = React.useState(false);
    const disabled = React.useState(true);
    const schedule_text = window.rvconfig?.schedule?.text?.schedule || 'Schedule';
    const pass_text = window.rvconfig?.schedule?.text?.passes || 'Passes';
    const subscriptions_text = window.rvconfig?.schedule?.text?.subscriptions || 'Subscriptions';
    const cart_text = window.rvconfig?.schedule?.text?.cart || 'Cart';
    const video_text = window.rvconfig?.schedule?.text?.videos || 'Videos';
    const loggedIn   = sessionStorage.getItem('rvLoggedIn') || false;

    console.log("logged in ", loggedIn);
    return ( 
        <header className={styles.header}>
            {
                <div>
                    <Link className="link" to='/'>{schedule_text}</Link>
                    {
                        (typeof window.rv_sched_data?.settings?.meta?.custom_view_passes === "undefined" || window?.rv_sched_data?.settings?.meta?.custom_view_passes === true) &&  passList.length > 0 &&
                        <Link className="link" to='/passes'>{pass_text}</Link>
                    }
                    {
                        (typeof window.rv_sched_data?.settings?.meta?.custom_view_videos === "undefined" || window?.rv_sched_data?.settings?.meta?.custom_view_videos === true) && videoList.length > 0 &&
                        <Link className="link" to='/videos'>{video_text}</Link>
                    }
                    {
                        (typeof window.rv_sched_data?.settings?.meta?.custom_view_subscriptions === "undefined" || window?.rv_sched_data?.settings?.meta?.custom_view_subscriptions === true) && subscriptionList.length > 0 &&
                        <Link className="link" to='/subscriptions'>{subscriptions_text}</Link>
                    }                    
                    <Link className="link" to='/cart'> <CartIcon/> {cart_text} ({cartItemCount})</Link>

                </div>
            }


        </header>
     );
}
 
export default Header;