import React, { createContext, useReducer } from 'react';
import { WaitReducer } from './WaitReducer';

export const WaitContext = createContext()

function sessionGet( what )
{
    try{
        if (typeof sessionStorage !== "undefined")
            return JSON.parse(sessionStorage.getItem(what)) || [];
        else
            return  [];
    }
    catch(e)
    {
        return [];
    }
}


const wait = sessionGet('rvwait');




const initialState = { 
                        waiting: null, 
                        waitList: wait
                     };

const WaitContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(WaitReducer, initialState);

    const setWait = payload => {
        dispatch({type: 'SET_WAIT', payload})
    }


    const clearWait = () => {
        dispatch({type: 'CLEAR_WAIT'})
    }
    const commitWait =  ()  => {
        dispatch({type: 'COMMIT_WAIT'})
    }
    


    const contextValues = {
        setWait,
        clearWait,
        commitWait,
        ...state
    } 

    return ( 
        <WaitContext.Provider value={contextValues} >
            { children }
        </WaitContext.Provider>
     );
}
 
export default WaitContextProvider;
