import React, { useContext, useState } from 'react';
import { WaitContext } from '../../contexts/WaitContext';
import styles from './ProductsGrid.module.scss';
import {ModalContext} from '../../contexts/ModalContext';


const WaitBlock = () => {
    const {modalOpen, modalClose} = React.useContext(ModalContext);

    const { waiting, clearWait } = useContext(WaitContext);
    const [ frameHeight, setFrameHeight ] = React.useState(20);
    const [ iframeReady, setIframeReady ] = React.useState(false);

    const handleClose = () => {
        modalClose();
        clearWait();
    }


    React.useEffect(() => {
        modalOpen();
    }, [])


    React.useEffect(() => {

        const childFrameObj = document.getElementById('rvCartCheckout');
            // we are in client mode         
        const onIFrameMessage = event => {
            console.log('proxy message', event);
            try{
                const data = JSON.parse(event.data);
                switch(data.message)
                {
                    case 'rvLoaded':
                        console.log("Loaded?", data);
                        setFrameHeight(data.height);
                        childFrameObj.contentWindow.postMessage(JSON.stringify({'message': 'rvInitialize', 'data': []}), '*');
                    break;
                    case 'rvReady':
                        console.log("rvReady?", data);
                        setFrameHeight(data.height);
                        setIframeReady(true);                        
                    break;
                    case 'rvBusy':
                        console.log("rvBusy?", data);
                        setFrameHeight(data.height);
                        setIframeReady(true);                        

                        //setShowSpinner(true);
                    break;
    
                    case 'rvIdle':
                        console.log("rvIdle?", data);

                        //setShowSpinner(false);
                        setFrameHeight(data.height);
                        setIframeReady(true);                        

                    break;  

                    case 'rvLogin':
                    {
                        setFrameHeight(data.height);
                        setIframeReady(true);                        
                    }
                    break;
    
                    case 'rvLoggedIn':
                    {
                        setFrameHeight(data.height);
                        setIframeReady(true);                        

                    }
                    break;

                    default:
                        console.log('Unexpected proxy message', data.message);
                    
                }
            }
            catch(e)
            {
               // console.log(e);
            }

        }

        window.addEventListener("message", onIFrameMessage)
    
        // clean up
        return () => {
            window.removeEventListener("message", onIFrameMessage) 
        }
   


    }, []);

    const url = window.rv_server_override? window.rv_server_override + '?eid=' + waiting.object.eid + '&did=' + waiting.object.did  + '&sid='  +  window.rv_sched_data?.id + '#/waitlist-proxy' : window.rv_reservie_address + 'cart/server?' + 'eid=' + waiting.object.eid + '&did=' + waiting.object.did + '&sid='  +  window.rv_sched_data?.id  + '#/waitlist-proxy';

    return (
        <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, bottom: 0, "left": 0, 'right': 0, display: waiting !==null?'block':'none'}}>
            <div className={"modal "  + ( waiting !==null? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Waiting list</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <iframe src={url} title="rv proxy frame" id="rvCartWaitList" scrolling="no" className={styles.p__rvFrame} style={{'visibility': iframeReady?'visible':'hidden', height: frameHeight + 'px', 'minHeight': (200) + 'px' }} /> 
                    
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary rv-sched-booknow rvSecondaryColorBtn" data-dismiss="modal" onClick={handleClose}>Cancel</button>
 

                        
                    </div>
                    </div>
                </div>
            </div>

        </div>

    );
  }
  

  export default WaitBlock;