import React, { createContext, useReducer } from 'react';
import { ModalReducer } from './ModalReducer';

export const ModalContext = createContext()

const initialState = { 
                        open: false 
                     };



const ModalContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(ModalReducer, initialState);

    const modalOpen = payload => {

        if ( window.location !== window.parent.location ) {
            // The page is in an iframe
            window.parent.postMessage('rvModal', '*');
          } else {
            // The page is not in an iframe
            
          }

        dispatch({type: 'OPEN_MODAL', payload})
        document.body.classList.add('rvModal');

    }


    const modalClose = () => {
        dispatch({type: 'CLOSE_MODAL'})
        document.body.classList.remove('rvModal');
    }

    const isOpen = () => {
        return state.open;
    }


    const contextValues = {
        modalOpen,
        modalClose,
        isOpen,
        ...state
    } 

    return ( 
        <ModalContext.Provider value={contextValues} >
            { children }
        </ModalContext.Provider>
     );
}
 
export default ModalContextProvider;
