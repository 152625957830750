import React, { useContext } from 'react';
import ProductSection from './ProductsSection';
import { ScheduleContext } from '../../contexts/ScheduleContext';
import WaitBlock from './WaitBlock';
import { WaitContext } from '../../contexts/WaitContext';

import styles from './ProductsGrid.module.scss';


const ProductsGrid = () => {

    const { schedule, start} = useContext(ScheduleContext)
    const { waiting } = useContext(WaitContext);

    return ( 
        <div className={styles.p__container}>
            <div className="row">
                <div className="col-sm-8">
                    <div className="py-3">
                        {schedule.length} <span className="rv-event-title">Events</span>
                    </div>
                </div>
            </div>

            {
                schedule.map(el => (
                    <ProductSection key={el.date} data={el}/>
                ))
            }
            {waiting && <WaitBlock />}
        </div>
     );
}
 
export default ProductsGrid;