

export const BookingFilterReducer = (state, action) => {
    switch (action.type) {
        case "ADD_FILTER":
        {
                state.filterlist = Object.assign({}, action.payload );
                return {
                    ...state,
                }                 
        }


        default:
            return state

    }
}