import React, {useContext} from 'react';
import Layout from '../../components/Layout';
import styles from './Video.module.scss';
import {videoList} from '../../services/scheduleList'
import { v4 as uuidv4 } from 'uuid';
import { CartControllerContext, OBJECT_TYPE_PASS } from '../../contexts/CartControllerContext';
import {BookingFilterContext} from '../../contexts/BookingFilterContext'
import { PreferenceContext } from '../../contexts/PreferenceContext';
import VideoCard from './VideoCard';

const Videos = () => {
    const { pushEvent, isPassInCart, countPassInCart } = useContext(CartControllerContext);
    const max = 100; // to do
    const { preference } = useContext(PreferenceContext);
    const [alert, showAlert] = React.useState(false);
    const { isPassFiltered } = useContext(BookingFilterContext);
    const [filteredVideos, setFilteredVideos] = React.useState(videoList || []);

    const onAddToCart = (o) => {

        var x = {
            type: OBJECT_TYPE_PASS,
            key_1: o.id,
            key_2: null,
            key_3: null,
            id: uuidv4(),
            object: o
        }
        pushEvent(x)           
    }
 

    const onPopPass = () => {
        showAlert(true)
    }

    
    return ( 
        <Layout title="Videos" description="Video options" >

                <div className={"videos row " + styles.p__rowCenter}>
                {
                    
                    filteredVideos.map( video => (
                        <VideoCard video={video} key={video.id} />
                    ))
                }
                </div>

   

        </Layout>
     );
}
 
export default Videos;