import React, { useContext } from 'react';
import styles from './Login.module.scss';
import {configuration} from '../../services/scheduleList';
import { CookieService } from '../../services/cookie.service';
import {ModalContext} from '../../contexts/ModalContext';
import { CookieContext } from '../../contexts/CookieContext';
import Redirect from './Redirect';
import { JwtContext } from '../../contexts/JwtContext';


const LOGIN_MODE_SIGN_IN = 'LOGIN_MODE_SIGN_IN';
const LOGIN_MODE_REGISTER = 'LOGIN_MODE_REGISTER';
const LOGIN_MODE_RESET = 'LOGIN_MODE_RESET';
const LOGIN_MODE_SIGNIN_ERROR = 'LOGIN_MODE_SIGNIN_ERROR';


const getNavigatorLanguage = () => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
}

const geoFindMe = () => {
    const country = getNavigatorLanguage().split('-');
    if(country.length > 1)
    {
        if(!!country[1])
        {
          console.log(country[1].toUpperCase());
          return country[1].toUpperCase();
        }
        else
        {
          // defaulting
          console.error('Failed to get country code', country);
        }
    }
    return 'GB';
}

const LogInPanel = ({show, onChildIdle, onChildBusy, onReady, onCancel}) => {
    const {isAllowed} = React.useContext(CookieContext);
    const {jwt, store} = React.useContext(JwtContext);

    const {modalOpen, modalClose} = React.useContext(ModalContext);
    const [password, setPassword] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [passwordType, setPasswordType] = React.useState('password');
    const [passwordText, setPasswordText] = React.useState('Show password');
    const [mode, setMode] = React.useState(LOGIN_MODE_SIGN_IN);
    const [remember, setRemember] = React.useState(false);
    const [ frameHeight, setFrameHeight ] = React.useState(20);
    const [ iframeReady, setIframeReady ] = React.useState(false);
    const onShowPassword = (event) => {
        event.preventDefault();
        if(passwordType === 'password')
        {
            setPasswordType('text');
            setPasswordText('Hide password');
        }
        else
        {
            setPasswordType('password');
            setPasswordText('Show password');
        }
    }

    const onSetUsername = ( event ) =>{
        setUsername(event.target.value);
    }

    const onSetPassword = ( event ) =>{
        setPassword(event.target.value);
    }
    React.useEffect(() => {
        modalOpen();
    }, [])



    React.useEffect(() => {

        const childFrameObj = document.getElementById('rvCartCheckout');
            // we are in client mode         
        const onIFrameMessage = event => {
            console.log('proxy message', event);
            try{
                const data = JSON.parse(event.data);
                switch(data.message)
                {
                    case 'rvLoaded':
                        //console.log("Loaded?", data);
                        setFrameHeight(data.height);
                        childFrameObj.contentWindow.postMessage(JSON.stringify({'message': 'rvInitialize', 'data': [],  'country': geoFindMe(), 'token': 'NS', 'jwt': jwt}), '*');
                    break;
                    case 'rvReady':
                        //console.log("rvReady?", data);
                        setFrameHeight(data.height);
                        setIframeReady(true);                        
                        onChildIdle();
                    break;
                    case 'rvBusy':
                       // console.log("rvBusy?", data);
                        //setShowSpinner(true);
                        onChildBusy();
                    break;
    
                    case 'rvIdle':
                        //console.log("rvIdle?", data);
                        //setShowSpinner(false);
                        onChildIdle();
                    break;  

                    case 'rvLogin':
                    {
                        setMode(LOGIN_MODE_SIGN_IN);
                    }
                    break;
    
                    case 'rvLoggedIn':
                    {
                        setMode(LOGIN_MODE_SIGN_IN);
                        setFrameHeight(data.height);
                        if(data.data)
                        {
                            if(data.data.token)
                            {
                                onLogin(data.data.email, data.data.password, data.data.at, true);
                            }
                            else
                            {
                                onLogin(data.data.email, data.data.password, data.data.at, false);
                            }
                        }

                    }
                    break;

                    default:
                        console.log('Unexpected proxy message', data.message);
                    
                }
            }
            catch(e)
            {
               // console.log(e);
            }

        }

        window.addEventListener("message", onIFrameMessage)
    
        // clean up
        return () => {
            window.removeEventListener("message", onIFrameMessage) 
        }
   


    }, [mode]);


    const onLogin = (u, p, t, r) => {
        onChildBusy();

        var chain = '&email=' + u;
        chain = chain + '&password=' + p;

        fetch(window.rv_reservie_address + "api/ajax-do-customer-log-in", {
            method: "POST",
            body: configuration.tokenname + '=' + configuration.tokenvalue + chain,
            credentials: 'include',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Authorization': 'Bearer ' + t
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                if(typeof t !== 'undefined')
                {
                    store(response.autoken, r === true ? 1:0);
                }


                if(r === true)
                {

                    if(response.token)
                    {
                        CookieService.setCookie('rvli', response.token, 365);
                    }
                    else
                    {
                        CookieService.setCookie('trvli', response.token, 1);
                    }
                }
                modalClose();
                onReady();                
                onChildIdle();
            }
            else
            {
                onChildIdle();
                //setMode(LOGIN_MODE_SIGNIN_ERROR);
            }
        }).catch(function (err) {
            onChildIdle();
        });
    }



    const onDoCancel = () => {
        modalClose();
        setMode()
        onCancel(LOGIN_MODE_SIGN_IN);
    }





    return ( 
        <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, "display": show?'block':'none', 'zIndex': 9999}}>
            <div className="modal show" style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} >
                <div className={"modal-dialog modal-xl " + styles.p__modal} role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">&nbsp;</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onDoCancel}>
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            isAllowed() === true && <iframe src={window.rv_server_override? window.rv_server_override + '/' + window.rv_sched_data?.id + '/#/login-proxy' : window.rv_reservie_address + 'cart/server/' + window.rv_sched_data?.id + '/#/login-proxy' } title="rv proxy frame" id="rvCartCheckout" scrolling="no" className={styles.p__rvFrame} style={{'visibility': iframeReady?'visible':'hidden', height: frameHeight + 'px', 'minHeight': (200) + 'px' }} /> 
                                        }
                                        {
                                            isAllowed() === false && (
                                                <Redirect />
                                            )
                                        }

                                    </div>                                        
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
     );
}
 
export default LogInPanel;