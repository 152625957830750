import moment from "moment";
import { OBJECT_TYPE_VIDEO } from "./CartControllerContext";
import {settings} from '../services/scheduleList';

function sessionSet( what, s )
{    
    if ( window.location !== window.parent.location ) {
        // The page is in an iframe
        window.parent.postMessage({'message':'rvCart', 'value': s}, '*');
    } else {
        // The page is not in an iframe
        
    }


    try{
        if (typeof sessionStorage !== "undefined")
            sessionStorage.setItem(what, s);
    }
    catch(e)
    {
        /* do nothing */
    }
    return
}


const Storage = (transactions) =>{
    const hash = btoa(encodeURIComponent(window.location.hostname));
    const t = filter(transactions);
    const s = JSON.stringify(t);
    sessionSet(hash, s);
   // StorageRepeater(transactions);
}


export const filter = (  transactions ) => {


    /*
    const offset = parseInt(settings.checkout_timeout);
    transactions.card = transactions.card.map( transaction =>{

        if(transaction.type !== OBJECT_TYPE_VIDEO)
        {
            transaction.objects = transaction.objects.filter( item => {
                let d   = moment(item.date + " " + item.time);    
                const n = moment().add(offset, 'minutes');
                const isbefore = n.isBefore(d);
                return isbefore;
            });
        }
        return transaction;
    })

    transactions.pass = transactions.pass.map( pass => {
        pass.transactions = pass.transactions.map( transaction =>{
            
            if(transaction.type !== OBJECT_TYPE_VIDEO)
            {
                transaction.objects = transaction.objects.filter( item => {
                    const d   = moment(item.date + " " + item.time); 
                    return moment().isBefore(d);
                });
            }
            return transaction;           
        });
        return pass;
    }); 

    */

    return transactions;

}

export const CartControllerReducer = (state, action) => {

    
    switch (action.type) {

        case "INITIALIZE_CART":
        {
            state.wallets = action.payload; 
            state.initialized = true;           
            return {
                ...state,
                ... Storage(state.wallets)

            } 
                       
        }
        break;

        case "EMPTY_CART":
        {   
            state.wallets = [];
            state.initialized = false;
            
            return {
                ...state,
                ... Storage(state.wallets)

            }

        }
        break;

        case "PUSH_EVENT":
        {        
            state.event.push( action.payload );
            return {
                ...state
            }                
        }
        break;


        case "POP_EVENT":
        {
            // pop off the head 
            //console.log("Popping event", state.event);
            state.event = state.event.filter( (event, index) => index === 0? false: true);
            if(state.queue.length > 0)
            {
                state.event = state.queue.filter( (event, index) => index === 0? true: false);
            }
            state.queue = state.queue.filter( (event, index) => index === 0? false: true);

            //console.log("Popped event", state.event);
            return {
                ...state
            }              
        }
        break;
       
        case "ATTACH_PASS_WALLET":
        {
            /* Transition cart item to stage 2 */

            var search = state.wallets.pass.findIndex( (item) => 
            {
                //console.log("is in wallet", item.id, action.payload.id);   
                return item.id === action.payload.id
            });


            //console.log("attached state wallett", search);
            if(search < 0)
            {
                /* now present - attach */
                //console.log("attaching to wallett", action.payload);
                state.wallets.pass.push(Object.assign({}, action.payload ));
                return {
                    ...state,
                    ... Storage(state.wallets)
                } 
            }
            else
            {
                state.wallets.pass[search].credits = action.payload.credits;
                state.wallets.pass[search].expires = action.payload.expires;
                return state;
            }
               
        }


        case "ATTACH_SUBSCRIPTION_WALLET":
        {
            /* Transition cart item to stage 2 */
            var search = state.wallets.subscription.findIndex( (item) => item.id === action.payload.id);
            if(search < 0)
            {
                /* now present - attach */
                state.wallets.subscription.push(Object.assign({}, action.payload ));
                return {
                    ...state,
                    ... Storage(state.wallets)
                } 
            }
            else
            {
                state.wallets.subscription[search].credits = action.payload.credits;
                state.wallets.subscription[search].expires = action.payload.expires;
                return state;
            }
               
        }


        case "SWITCH_PENDING_PASS_WALLET":
        {
            /* Transition cart item to stage 2 */
       
            /* now present - attach */
            state.wallets.pass = state.wallets.pass.map( pass => {
                if(pass.mode === 'pending')
                {
                    let p = Object.assign({}, pass);
                    p.mode = 'debit';
                    return p;
                }
                else
                {
                    return pass;
                }
            })
            return {
                ...state,
                ... Storage(state.wallets)
            }  
        }
        break;
        
        
        case "CLEAR_PENDING_PASS_WALLET":
            {
                /* Transition cart item to stage 2 */
           
                /* now present - attach */
                state.wallets.pass = state.wallets.pass.filter( pass => pass.mode !== 'pending');
                return {
                    ...state,
                    ... Storage(state.wallets)
                }
            }
            break;                  


        case "PUSH_OBJECT_TO_CARD_WALLET":
        {
            /* Transition cart item to stage 2 */
                // first up recurse the store and search for matching objects

                var found   = false;
                var j       = 0;
                var k       = 0;
                var l       = 0;
               // for(j = 0; j < state.wallets.card.length; j++)
               // {
               //     if(action.payload.id === state.wallets.card[j].id)
                //    {
                        // we have a match - so this is another person - has that person already been added ?
                //        found = true;
                //    }

                //}

                //if(!found)
                for(l = 0; l < action.payload.objects.length && found === false; l++)
                {
                    switch(action?.payload?.type)
                    {
                        case 'OBJECT_TYPE_EVENT_INDIVIDUAL':
                        case 'OBJECT_TYPE_EVENT_FLEXIBLE':
                        {
                            found = false;
                            var f = [];
                            // ok so not found so we need to look through the cart and see if there is a match in key 1 and key 3 i.e. eid and po
                            for(j = 0; j < state.wallets.card.length && found === false; j++)
                            {
                                for(k = 0; k < state.wallets.card[j].objects.length; k++)
                                {
                                        // if there is already a match - we cannot add thi here - so need to check. 
                                        // However if key 1 and key 3  matches - we know we have the right transaction but the slot is empty for this date
                                    if( state.wallets.card[j].objects[k].key_1 ===  action.payload.objects[l].key_1 && 
                                        state.wallets.card[j].objects[k].key_2 ===  action.payload.objects[l].key_2 &&
                                        state.wallets.card[j].objects[k].key_3.id ===  action.payload.objects[l].key_3.id
                                        )
                                    {
                                        // ok the item is already in this cart object - lets skip ahead
                                        f.push(j);
                                        //in_transaction = true;
                                        break;
                                    }
                                }        
                            }
                            for(j = 0; j < state.wallets.card.length && found === false; j++)
                            {
                                // is there an exact match ? 
                                if(f.includes(j))
                                {
                                    continue;
                                }
        
                                for(k = 0; k < state.wallets.card[j].objects.length && found === false; k++)
                                {
                                    if( state.wallets.card[j].objects[k].key_1 ===  action.payload.objects[l].key_1 && 
                                        state.wallets.card[j].objects[k].key_2 !==  action.payload.objects[l].key_2 &&
                                        state.wallets.card[j].objects[k].key_3.id ===  action.payload.objects[l].key_3.id
                                        )
                                    {
                                        // so we did not find an exact match is there a partial one?
                                        found = true;
                                        state.wallets.card[j].objects.push(action.payload.objects[l]);  
                                    }
        
                                }
                            }
        
                            if(!found)
                            {
                                state.wallets.card.push(Object.assign({}, action.payload));
                                // add the entire payload and done
                                found = true;
                                break;
                            }                            
                        }
                        break;

                        default:
                        {
                            state.wallets.card.push(Object.assign({}, action.payload));
                            found = true;
                        }
                        break;


                    }

                }

                //state.wallets.card.push(Object.assign({}, action.payload));
                //state.event = null;
                return {
                    ...state,
                    ... Storage(state.wallets)
                }                
                
                
               
        } 
        break;


        case "POP_OBJECT_FROM_WALLET":
        {
            /* Transition cart item to stage 2 */
            const rcw = state.wallets.card.reverse();
            //console.log("start set", rcw);

            let index = rcw.findIndex( (transaction) => transaction.id === action.payload.key_1);
            if(index >= 0)
            {
                //console.log("deleting card index", index);
                switch(action?.payload?.specifier?.type)
                {
                    case 'OBJECT_TYPE_EVENT_INDIVIDUAL':
                    {
                        let found = false
                        state.wallets.card = rcw.map( c => {
                            c.objects = c.objects.filter( o => {
                                if(found === false)
                                {
                                    found = (o.key_1 === action.payload.specifier.key_1 && o.key_2 === action.payload.specifier.key_2);
                                    return !found;
                                }
                                return true;
                                
                            });
                            return c;
                        }).filter( o => o.objects.length > 0).reverse();  
                    }
                    break;

                    default:
                    {
                        state.wallets.card = rcw.filter((tx, i) => index !== i).reverse();
                    }
                }

            }
            else
            {
                let found = false;
                for(var i = 0; i < state.wallets.pass.length && found === false; i++)
                {
                    const pcw = state.wallets.pass[i].transactions.reverse();
                    index = pcw.findIndex(transaction => {
                        //console.log("comparing", transaction.id,  action.payload.key_1);
                        return transaction.id === action.payload.key_1;
                    });

                    if(index >= 0)
                    {
                        state.wallets.pass[i].transactions =  pcw.filter((tx, i) => index !== i).reverse(); 
                        //console.log("splicing at ", index, state.wallets.pass[i].transactions);
                        found = true;                     
                    }
                }
            }

            //console.log("final set", state.wallets);
            
            //state.event = null;
            
            return {    
                ...state,
                ...Storage(state.wallets)
            }

            //console.log('ready to return');
                                    
        } 
        break;



        case "PUSH_OBJECT_TO_PASS_WALLET":
        {
            var search = state.wallets.pass.findIndex( (item) => item.id === action.payload.id);
            if(search >= 0)
            {
                //console.log('adding', action.payload.object);
                const o = Object.assign({}, action.payload.object);

                var found = false;
                var f = [];
                var l, j, k = 0;
                var starts = action?.payload?.start_override || false;
                var expires = action?.payload?.expires_override || false;

                for(l = 0; l < action?.payload?.object?.objects.length; l++)
                {
                    switch(action?.payload?.object?.type)
                    {
                        case 'OBJECT_TYPE_EVENT_INDIVIDUAL':
                        case 'OBJECT_TYPE_EVENT_FLEXIBLE':
                        {

                            found = false;


                            for(j = 0; j < state.wallets.pass[search].transactions.length && found === false ; j++)
                            {
                                for(k = 0; k < state.wallets.pass[search].transactions[j].objects.length; k++)
                                {
                                        // if there is already a match - we cannot add thi here - so need to check. 
                                        // However if key 1 and key 3  matches - we know we have the right transaction but the slot is empty for this date
                                    if( state.wallets.pass[search].transactions[j].objects[k].key_1 ===  action.payload.object.objects[l].key_1 && 
                                        state.wallets.pass[search].transactions[j].objects[k].key_2 ===  action.payload.object.objects[l].key_2 &&
                                        state.wallets.pass[search].transactions[j].objects[k].key_3.id ===  action.payload.object?.objects[l].key_3.id
                                        )
                                    {
                                        // ok the item is already in this cart object - lets skip ahead
                                        f.push(j);
                                        //in_transaction = true;
                                        break;
                                    }
                                }        
                            }
                            for(j = 0; j <  state.wallets.pass[search].transactions.length && found === false ; j++)
                            {
                                // is there an exact match ? 
                                if(f.includes(j))
                                {
                                    continue;
                                }
            
                                for(k = 0; k <  state.wallets.pass[search].transactions[j].objects.length && found === false ; k++)
                                {
                                    if(  state.wallets.pass[search].transactions[j].objects[k].key_1 ===  action?.payload?.object?.objects[l]?.key_1 && 
                                        state.wallets.pass[search].transactions[j].objects[k].key_2 !==  action?.payload?.object?.objects[l]?.key_2 &&
                                        state.wallets.pass[search].transactions[j].objects[k].key_3.id ===  action?.payload?.object?.objects[l]?.key_3.id
                                        )
                                    {
                                        // so we did not find an exact match is there a partial one?
                                        found = true;
                                        state.wallets.pass[search].transactions[j].objects.push(action.payload.object.objects[l]);  
                                    }
            
                                }
                            }
                        }
                        break;

                        default:
                        {
                            // its a block - so a transaction in its own right
                        }
                        break;
                    }
                }


                for(j = 0; j < state.wallets.pass[search].transactions.length; j++)
                {
                    state.wallets.pass[search].transactions[j].objects.sort((a, b) => {
                        // Concatenate date and time for comparison
                        const dateTimeA = new Date(`${a.date}T${a.time}`).getTime();
                        const dateTimeB = new Date(`${b.date}T${b.time}`).getTime();
                  
                        // Compare and return the result for sorting
                        return dateTimeA - dateTimeB;
                    });
                }


                if(starts)
                {
                    state.wallets.pass[search].starts = state.wallets.pass[search].transactions.reduce((earliestDateTime, transaction) => {
                        return transaction.objects.reduce((innerEarliestDateTime, object) => {
                          const currentDateTime = new Date(`${object.date} ${object.time}`);
                          
                          if (!innerEarliestDateTime || currentDateTime < innerEarliestDateTime) {
                            return currentDateTime;
                          } else {
                            return innerEarliestDateTime;
                          }
                        }, earliestDateTime);
                      }, starts);
                }
                if(expires)
                {
                    state.wallets.pass[search].expires = state.wallets.pass[search].transactions.reduce((latestDateTime, transaction) => {
                        return transaction.objects.reduce((innerLatestDateTime, object) => {
                          const currentDateTime = new Date(`${object.date} ${object.time}`);
                          
                          if (!innerLatestDateTime || currentDateTime > innerLatestDateTime) {
                            return currentDateTime;
                          } else {
                            return innerLatestDateTime;
                          }
                        }, latestDateTime);
                    }, expires);
                }

                if(!found)
                {
                    state.wallets.pass[search].transactions.push(o);
                }


                return {
                    ...state,
                    ...Storage(state.wallets)
                }            
                
            }
            else
            {
                return state;
            }

              
        }
        break;

        case 'DETACH_PASS_WALLET':
        {
            state.wallets.pass = state.wallets.pass.filter( (item) => !(item.id === action.payload.id));
            return {
                ...state,
                ...Storage(state.wallets)
            }                        
        }

        case 'EMPTY_PASS_WALLET':
            {
                state.wallets.pass = state.wallets.pass.map( (item) => {
                    if(item.id === action.payload.id)
                    {
                        item.transactions = [];
                    }
                    return item;
                });
                return {
                    ...state,
                    ...Storage(state.wallets)
                }                        
            }

        case 'DETACH_SUBSCRIPTION_WALLET':
        {
            state.wallets.subscription = state.wallets.subscription.filter( (item) => !(item.id === action.payload.id));
            return {
                ...state,
                ...Storage(state.wallets)
            }                        
        }



        case 'DECREMENT_PASS_WALLET_EVENT_QTY':
        {

            state.wallets.pass =  state.wallets.pass.map( tx => {
                tx.transactions = tx.transactions.map( transaction => {
                    transaction.objects = transaction.objects.map( object => {
                        if(tx.id === action.payload.wallet_id && transaction.id === action.payload.id)
                        {
                            object.qty -= 1;
                        }
                        return object;
                    }).filter(object => object.qty > 0);
                    return transaction;
                }).filter( transaction => transaction.objects.length > 0);
                if(tx.start_mode === 1)
                {
                    if(!tx?.has_bookings)
                    {
                        tx.starts = tx.expires = null;
                    }
                }
                return tx;
            });


            return {
                ...state,
                ...Storage(state.wallets)
            }  
        }


        case 'INCREMENT_PASS_WALLET_EVENT_QTY':
        {
            state.wallets.pass =  state.wallets.pass.map( tx => {
                tx.transactions = tx.transactions.map( transaction => {
                    transaction.objects = transaction.objects.map( object => {
                        if(tx.id === action.payload.wallet_id && transaction.id === action.payload.id)
                        {
                            object.qty -= 1;
                        }
                        return object;
                    }).filter(object => object.qty > 0);
                    return transaction;
                }).filter( transaction => transaction.objects.length > 0);
                return tx;
            });

            return {
                ...state,
                ...Storage(state.wallets)
            }  
        }



        case 'DECREMENT_CARD_WALLET_EVENT_QTY':
        {

            state.wallets.card =  state.wallets.card.map( (transaction, idex) => {
                transaction.objects = transaction.objects.map( object => {
                    if((transaction.id === action.payload.id) && (idex === action.payload.instance))
                    {
                        object.qty -= 1;
                    }
                    return object;
                }).filter(object => object.qty > 0);
                return transaction;
            }).filter( transaction => transaction.objects.length > 0);
        
            return {
                ...state,
                ...Storage(state.wallets)
            }  
        }


        case 'INCREMENT_CARD_WALLET_EVENT_QTY':
        {
            state.wallets.card =  state.wallets.card.map( transaction => {
                transaction.objects = transaction.objects.map( object => {
                    if(transaction.id === action.payload.id)
                    {
                        object.qty += 1;
                    }
                    return object;
                }).filter(object => object.qty > 0);
                return transaction;
            }).filter( transaction => transaction.objects.length > 0);
        
            return {
                ...state,
                ...Storage(state.wallets)
            }  
        }


        default:
            return state

    }
}