
import React from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop = (props) => {
  const location = useLocation();
  const myRef = React.createRef()  
  const [loc, setLoc] = React.useState(location);  

  React.useEffect(() => {
    if(loc !== location)
    {
      myRef.current.scrollIntoView();
    }
    setLoc(location);
  }, [location]);

  return (
    <div ref={myRef}>
        {props.children}
    </div>)
   
};

export default ScrollToTop;