import React, {useContext} from 'react';
import Layout from '../../components/Layout';
import styles from './Passes.module.scss';
import {passList} from '../../services/scheduleList'
import { v4 as uuidv4 } from 'uuid';
import { CartControllerContext, OBJECT_TYPE_PASS } from '../../contexts/CartControllerContext';
import {BookingFilterContext} from '../../contexts/BookingFilterContext'

import DeleteInCart from './DeleteInCart';

import { formatNumber } from '../../helpers/utils';
import { PreferenceContext } from '../../contexts/PreferenceContext';
import { PlusCircleIcon, MinusCircleIcon } from '../../components/icons'
import Parser from 'html-react-parser';

const Passes = () => {
    const { pushEvent, isPassInCart, countPassInCart } = useContext(CartControllerContext);
    const max = 100; // to do
    const { preference } = useContext(PreferenceContext);
    const [alert, showAlert] = React.useState(false);
    const { isPassFiltered } = useContext(BookingFilterContext);
    const nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    const onAddToCart = (o) => {

        var x = {
            type: OBJECT_TYPE_PASS,
            key_1: o.id,
            key_2: null,
            key_3: null,
            id: uuidv4(),
            object: o
        }
        pushEvent(x)           
    }
 

    const onPopPass = () => {
        showAlert(true)
    }

    
    return ( 
        <Layout title="Passes" description="Pass options" >

                    <div className={"row " + styles.p__rowCenter}>
                            {
                                passList.filter(pass => {return parseFloat(pass.price) >= 0 && parseInt(pass.subscriptionlimited) === 0 && isPassFiltered(pass.id) === false} ).sort((a, b) => a.credits - b.credits).map( pass => (
                                        <div className="card col-12 col-md-3 p-4 m-4 box-shadow border-1" key={pass.id} >
                                            <div className="card-body  d-flex flex-column">
                                                <h5 className="card-title">{pass.name}</h5>
                                                <p className="card-text">
                                                    {Parser(nl2br(pass.description, false))} 
                                                </p>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-text"><b>Credits:</b> {pass.credits} </div>
                                                {
                                                    parseInt(pass.start_mode) === 0 && 
                                                    parseInt(pass.period) === 0 && 
                                                    <div className="card-text"><b>Expires:</b> +{pass.expires} days</div>
                                                }
                                                {
                                                    parseInt(pass.start_mode) === 0 && 
                                                    parseInt(pass.period) === 1 && 
                                                    <div className="card-text"><b>Expires:</b> +{pass.expires} month(s)</div>
                                                }     
                                                {
                                                    parseInt(pass.start_mode) === 1 && 
                                                    parseInt(pass.period) === 0 && 
                                                    <div className="card-text"><b>Expires:</b> +{pass.expires} days from date/time of first booking.</div>
                                                }
                                                {
                                                    parseInt(pass.start_mode) === 1 && 
                                                    parseInt(pass.period) === 1 && 
                                                    <div className="card-text"><b>Expires:</b> +{pass.expires} month(s) from date/time of first booking.</div>
                                                }                                                                                             
                                                <div className="card-text"><b>Price:</b>  {formatNumber(pass.price, preference.currency)} </div>
                                            </div>
                                            <div className={styles.p__outerButtonContainer}>
                                            {
                                                !isPassInCart( pass.id ) &&
                                                <button type="button" className={"btn rv-sched-booknow rvPrimaryColorBtn  align-self-end " + styles.p__rVieBtn} onClick={() => onAddToCart(pass)}>Select</button>
                                            }
                                            {
                                                isPassInCart( pass.id ) &&
                                                <div className="align-self-end">
                                                    <div className={"clearfix " + styles.p__qtyContainer}>
                                                        <button 
                                                        onClick={() =>  { onAddToCart(pass)}}
                                                        disabled={countPassInCart(pass.id) >= max ? true: false}
                                                        className={"btn btn-sm rv-sched-booknow rvPrimaryColorBtn float-left mb-1 " + styles.p__qtyMore}>
                                                            <PlusCircleIcon width={"20px"}/>
                                                        </button>
                                                        <div className={"text-center " + styles.p__qtyDigit}><div>{countPassInCart(pass.id, null)}</div><div className={styles.p__places}>pass(es)</div></div>
                                                        <button
                                                            onClick={() => {onPopPass(pass)}}
                                                            disabled={countPassInCart(pass.id) < 1 ? true: false}
                                                            className={"btn btn-sm rv-sched-booknow rvPrimaryColorBtn mb-1 float-right " +  styles.p__qtyLess}>
                                                                <MinusCircleIcon width={"20px"}/>
                                                        </button>
                                                    </div>  
                                                </div>
                                            }
                                            </div>

                                        </div> 
                                ))
                            }
   

                            {
                                alert && <DeleteInCart show={alert} onContinue={()=>{showAlert(false)}} />
                            }
                    </div>
        </Layout>
     );
}
 
export default Passes;