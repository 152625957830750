import React from 'react';
import ProductOption from './ProductOption';
import Moment from 'react-moment';
import { BiCircle, BiTimeFive, BiUserPlus, BiMap, BiPlus, BiCalendarCheck } from "react-icons/bi";
import styles from './ProductsGrid.module.scss';
import { paymentOptions } from '../../contexts/ScheduleContext';
import { settings } from '../../services/scheduleList';

const ProductItem = ({product}) => {

    const options = paymentOptions( product );
    const format = settings.clock === '12hour'?'hh:mm a': 'HH:mm';
    const convertToHoursMinutes =  x => {
    
        const hours = Math.floor(x / 60); 
        const minutes = x % 60;  
        if(minutes > 0)
        {
            if(hours > 1)
            {
                return hours + ' hrs ' + minutes.toString().padStart(2, '0') + ' min(s)';      
            }
            else
            {
                return hours + ' hr ' + minutes.toString().padStart(2, '0') + ' min(s)';      

            }
        } 
        else
        {
            if(hours > 1)
            {
                return hours + ' hours ';
            }
            else
            {
                return hours + ' hour ';
            }
        }  
    }


    const roundToZero = x => {
        if(x < 0)
        {
            return 0;
        }
        return x;
    }

    const availability =  el => {

        if(window.rv_sched_settings.show_places)
        {
            switch(el.is_block)
            {
                case "3":
                case "0":
                {
                    let stock = el.stock;
                    let v = roundToZero(stock - el.sold);
   
                    return 'Spaces: ' + v;
                }
                
                case "1":
                {

                    let stock = el.stock;                   
                    let v =  roundToZero(stock - el.max_assoc_tx_count);
                          
                    return 'Spaces: ' + v;
                }
                
                default:
                    return '';
                  
            }

        }
        else
        {
            switch(el.is_block)
            {
                case "3":
                case "0":
                {

                    let stock = el.stock;
          

                    let v = roundToZero(stock - el.sold);
        
                    if( v > 0)
                        return 'Space available';
                    else
                        return 'Fully booked';
                }

    
            
                case "1":
                {

                    let stock = el.stock;
                    let v = roundToZero(el.stock - el.max_assoc_tx_count);
            

                    if(v > 0)
                        return 'Space available';
                    else
                        return 'Fully booked';
                }

                default:
                    return '';                              
            }
        
        }

    }

    return ( 
        <React.Fragment>
        {
            options?.length > 0 &&
            <div className={styles.p__grid + " " + product.mode}>
            <div className="row">
                <div className="d-none d-md-block col-lg-2">
                    <div className="rvProductTime">
                    <Moment format={format}>{product.date + ' ' + product.time}</Moment>
                    </div>
                    <div className={styles.p__rVieInfo}>
                        {
                            (((product.is_block === "0") && (window.rv_sched_vocab.coursetype.payg)) ||
                            ((product.is_block === "1") && (window.rv_sched_vocab.coursetype.block)) ||
                            ((product.is_block === "3") && (window.rv_sched_vocab.coursetype.flex)))

                        }
                    </div>
                    
                </div>
                <div className="col-sm-12 col-md-7 no-gutters">
                    <div className={styles.p__rVieEventtitle + "  rVieItemTitle"}>

                         <BiCircle />
                         <span  className="d-md-none d-lg-none d-xl-none rvProductTime">
                            &nbsp; <Moment format="hh:mma">{product.date + ' ' + product.time}</Moment>
                         </span>
                         &nbsp;{product.name}
                    </div>
                    {product.facilitators.length > 0 && product.facilitators.map( el => (
                        <div key={el.id} className={styles.p__rVieInfo + " rvSchedFacilitator"}> <BiUserPlus /> Teacher: {el.firstname} {el.lastname}</div>

                    ))}
                    {(((product.duration/60)>0) && (
                    <div className={styles.p__rVieInfo  + " rvSchedDuration"}> <BiTimeFive /> Duration: {convertToHoursMinutes(product.duration)}</div>
                    ))}
                    {((product.locationname) && (
                    <div className={styles.p__rVieInfo  + " rvSchedLocation"}> <BiMap /> Location: {product.locationname}</div>
                    ))}
                    <div className={styles.p__rVieInfo  + " rvSchedAvailability"}> <BiPlus /> Availability: {availability(product)}</div>                    
                    <div className={"d-md-none d-lg-none " + styles.p__rVieInfo}>
                    <BiCalendarCheck/> Book as: {
                            (((product.is_block === "0") && (window.rv_sched_vocab.coursetype.payg)) ||
                            ((product.is_block === "1") && (window.rv_sched_vocab.coursetype.block)) ||
                            ((product.is_block === "3") && (window.rv_sched_vocab.coursetype.flex)))

                        }
                    </div>
                </div>
                <div className="col-md-3 col-sm-12 no-gutters mt-sm-2">
                    {
             
                        <ProductOption key={product.did} product={product} options={options}  />
                   
                    }
                    
                </div>

            </div>
        </div>
        }
        </React.Fragment>


     );
}
 
export default ProductItem;