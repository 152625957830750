import React, { useContext } from 'react';
import { CartControllerContext, OBJECT_TYPE_EVENT_INDIVIDUAL, OBJECT_TYPE_EVENT_BLOCK, OBJECT_TYPE_EVENT_FLEXIBLE, OBJECT_TYPE_PASS, OBJECT_TYPE_SUBSCRIPTION, OBJECT_TYPE_VIDEO } from '../contexts/CartControllerContext';
import {ScheduleContext} from '../contexts/ScheduleContext';
import { JwtContext } from '../contexts/JwtContext';
import { BookingFilterContext } from '../contexts/BookingFilterContext';

import { ImSpinner2 } from 'react-icons/im';
import LogInPanel from '../components/shared/LogInPanel';
import Schedule from "../pages/schedule";
import Passes from "../pages/passes";
import {CookieService} from "../services/cookie.service"

import CartControllerPanelSelectAssociated from './CartControllerPanelSelectAssociated';
import CartControllerPanelErrorPassesNotAvailableWithExpiry from './CartControllerPanelErrorPassesNotAvailableWithExpiry';
import CartControllerPanelConfirmPassesAdded from './CartControllerPanelPassesAdded';
import CartControllerPanelSelectAcceptedPass from './CartControllerPanelSelectAcceptedPass';
import CartControllerPanelSelectCourseAssociated from './CartControllerPanelSelectCourseAssociated';
import CartControllerPanelConfirmSpaces from './CartControllerPanelConfirmSpaces';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import CartControllerPanelPassesImportAvailable from './CartControllerPassesImportAvailable';
import Cart from '../pages/cart';
import Checkout from '../pages/action/checkout';
import CartControllerPanelSelectPassOrCard from './CartControllerPanelSelectPassOrCard';
import CartControllerPanelErrorPassesNotAvailableWithCredits from './CartControllerPanelErrorPassesNotAvailableWithCredits';
import CartControllerPanelSelectTicket from './CartControllerPanelSelectTicket';
import CartControllerPanelErrorPassesCreditValueZero from './CartControllerPanelErrorPassesCreditValueZero';
import CartControllerPanelOnDone from './CartControllerPanelOnDone';
import Subscriptions from '../pages/subscriptions';
import {settings} from '../services/scheduleList';
import Videos from '../pages/videos';
import CartControllerPanelVideoSelectPassOrCard from './CartControllerPanelVideoSelectPassOrCard';
import CartControllerPanelErrorPassesNotForAccount from './CartControllerPanelErrorPassesNotAvailableForAccount';

const EVENT_CHECK_OBJECT_TYPE  = 'EVENT_CHECK_OBJECT_TYPE';
const VIDEO_CHECK_OBJECT_TYPE  = 'VIDEO_CHECK_OBJECT_TYPE';

const OBJECTSTATE_EVENT_WAIT_FOR_EVENT_ASSOCIATED  = 'OBJECTSTATE_EVENT_WAIT_FOR_EVENT_ASSOCIATED';
const OBJECTSTATE_EVENT_CHECK_PAYMENT_TYPE  = 'OBJECTSTATE_EVENT_CHECK_PAYMENT_TYPE';
const OBJECTSTATE_EVENT_INDIVIDUAL_CONFIRM_SPACES  = 'OBJECTSTATE_EVENT_INDIVIDUAL_CONFIRM_SPACES';
const OBJECTSTATE_EVENT_PAYMENT_BY_CARD_ONLY = 'OBJECTSTATE_EVENT_PAYMENT_BY_CARD_ONLY';
const OBJECTSTATE_EVENT_CHECKOUT_DONE = 'OBJECTSTATE_EVENT_CHECKOUT_DONE';
const OBJECTSTATE_EVENT_PAYMENT_BY_PASS_ONLY = 'OBJECTSTATE_EVENT_PAYMENT_BY_PASS_ONLY';
const OBJECTSTATE_EVENT_PASS_CHECK_NEXT_ACTION = 'OBJECTSTATE_EVENT_PASS_CHECK_NEXT_ACTION';
const OBJECTSTATE_EVENT_CHECKOUT_CANCEL = 'OBJECTSTATE_EVENT_CHECKOUT_CANCEL';

const OBJECTSTATE_EVENT_PASS_BUY_PASS_NOW = 'OBJECTSTATE_EVENT_PASS_BUY_PASS_NOW';
const OBJECTSTATE_EVENT_ANALYZE_PASS_CAN_BE_USED = 'OBJECTSTATE_EVENT_ANALYZE_PASS_CAN_BE_USED';
const OBJECTSTATE_EVENT_PAYMENT_CARD_OR_PASS = 'OBJECTSTATE_EVENT_PAYMENT_CARD_OR_PASS';
const OBJECTSTATE_EVENT_REMOTE_PASSES_ADDED = 'OBJECTSTATE_EVENT_REMOTE_PASSES_ADDED';
const OBJECTSTATE_EVENT_ADD_BLOCK_ASSOCIATED_DATES = 'OBJECTSTATE_EVENT_ADD_BLOCK_ASSOCIATED_DATES';
const OBJECTSTATE_EVENT_PASS_BUY_ERROR_PASSES_EXPIRES_TOO_SOON = 'OBJECTSTATE_EVENT_PASS_BUY_ERROR_PASSES_EXPIRES_TOO_SOON';
const OBJECTSTATE_EVENT_PASS_BUY_PASS_NOT_ENOUGH_CREDITS = 'OBJECTSTATE_EVENT_PASS_BUY_PASS_NOT_ENOUGH_CREDITS';
const OBJECTSTATE_EVENT_PASS_BUY_PASS_NOT_AVAILABLE = 'OBJECTSTATE_EVENT_PASS_BUY_PASS_NOT_AVAILABLE';
const OBJECTSTATE_EVENT_PASS_BUY_PASS_CREDIT_VALUE_ZERO = 'OBJECTSTATE_EVENT_PASS_BUY_PASS_CREDIT_VALUE_ZERO';
const OBJECTSTATE_EVENT_REQUEST_TICKET_TYPE = "OBJECTSTATE_EVENT_REQUEST_TICKET_TYPE";


const OBJECTSTATE_VIDEO_CHECK_PAYMENT_TYPE  = 'OBJECTSTATE_VIDEO_CHECK_PAYMENT_TYPE';


const OBJECTSTATE_VIDEO_PAYMENT_CARD_OR_PASS = 'OBJECTSTATE_VIDEO_PAYMENT_CARD_OR_PASS';
const OBJECTSTATE_VIDEO_PAYMENT_BY_CARD_ONLY = 'OBJECTSTATE_VIDEO_PAYMENT_BY_CARD_ONLY';
const OBJECTSTATE_VIDEO_PAYMENT_BY_PASS_ONLY = 'OBJECTSTATE_VIDEO_PAYMENT_BY_PASS_ONLY';
const OBJECTSTATE_MEDIA_PASS_CHECK_NEXT_ACTION = 'OBJECTSTATE_MEDIA_PASS_CHECK_NEXT_ACTION';
const OBJECTSTATE_MEDIA_PASS_BUY_PASS_NOW      = 'OBJECTSTATE_MEDIA_PASS_BUY_PASS_NOW';

const CART_OPEN_STATE_INTITIALISING = 0;
const CART_OPEN_STATE_OPEN = 1;
const CART_OPEN_STATE_CLOSED = 2;
const CART_OPEN_STATE_PASS_ACTION = 3;


const CartController = ({mode}) => {
    const [busy, setBusy] = React.useState(false); 
    const {event,  pushProcessedToCardWallet, attachPassWallet, attachSubscriptionWallet, getWalletFromCartIfAvailable, pushProcessedToPassWallet, fetchPendingPassWallets, switchPassPendingMode, clearPassPending, popEvent } = useContext(CartControllerContext);
    const {findEvent} = useContext(ScheduleContext);
    const [showLogIn, setShowLogIn] = React.useState(false); 
    const [object, setObject] = React.useState({state: null, transaction: {}}); 
    const [accountConnectState, setAccountConnectState] = React.useState(CART_OPEN_STATE_INTITIALISING); 
    const [allowedPasses, setAllowedPasses] = React.useState([]); 
    const [pos, setPos] = React.useState([]); // set allowed pricing option 
    const history = useHistory();
    const {jwt, storeNoBroadCast, store} = useContext(JwtContext);
    const { initializeCart } = useContext(CartControllerContext);
    const {addFilter, isEventFiltered, isPassFiltered} = useContext(BookingFilterContext);


    const isBusy = () => {
        setBusy(true);
    }

    const isIdle = () => {
        setBusy(false);
    }

    const isLoggedIn = ( cart ) => {
        setBusy(false);
        setShowLogIn(false);
        //openAccountConnection( );
        setAccountConnectState(CART_OPEN_STATE_INTITIALISING)   
    }

    const onAccountConnectAddPasses = () => {
        switchPassPendingMode();
        setAccountConnectState(CART_OPEN_STATE_OPEN)   
    }
    const onAccountConnectIgnorePasses = () => {
        clearPassPending();
        setAccountConnectState(CART_OPEN_STATE_OPEN)   

    }


    const onResetState = () => {
        setAccountConnectState(CART_OPEN_STATE_INTITIALISING)   
    }

    const onPanelCancel = () => {
        var o = Object.assign({}, object);
        //o.state = OBJECTSTATE_EVENT_CHECKOUT_DONE;
        o.state = OBJECTSTATE_EVENT_CHECKOUT_CANCEL;
        setObject(o);
    }


    const onPanelObjectEnd = () => {

        var o = Object.assign({}, object);
        o.state = OBJECTSTATE_EVENT_CHECKOUT_CANCEL;        
        setObject(o);
    }


    const onPanelRedirectToCart = () => {
        var o = Object.assign({}, object);
        o.state = OBJECTSTATE_EVENT_CHECKOUT_CANCEL;        
        setObject(o);
        history.push('/cart');        
    }


    const onAddCourseDates = ( dates, q ) =>
    {

        var o = Object.assign({}, object);
        //console.log('Initial list', o.transaction.objects)

        //let txobject = {person: i, objects: []}
        const objects = dates.filter(date => findEvent( date.key_1, date.key_2)).map( date => {
            const search = findEvent( date.key_1, date.key_2);
            const object = {
                key_1: date.key_1,
                key_2: date.key_2,
                key_3: date.key_3,
                qty: q,
                date: search.date, 
                time: search.time, 
                name: search.name, 
                payment_type: search.payment_type
            }
            return object;
        });
        
        o.transaction.objects = objects;
        o.state = OBJECTSTATE_EVENT_CHECK_PAYMENT_TYPE;
        setObject(o);

    }



    const onCartControllerPassSelectPaymentTypeContinue = ( new_pass ) => {
            
            var obj = Object.assign({}, object);
            obj.state = OBJECTSTATE_EVENT_ANALYZE_PASS_CAN_BE_USED;
            setObject(obj);        
            setBusy(false);

    }


    const onSelectEventPassPayment = () => {
        var obj = Object.assign({}, object);
        obj.state = OBJECTSTATE_EVENT_PASS_CHECK_NEXT_ACTION;
        setObject(obj);        

    }

    const onSelectMediaPassPayment = () => {
        var obj = Object.assign({}, object);
        obj.state = OBJECTSTATE_MEDIA_PASS_CHECK_NEXT_ACTION;
        setObject(obj);        

    }

    const onSelectMediaCardPayment = () => {
  
        var o = Object.assign({}, object);
        o.state = OBJECTSTATE_VIDEO_PAYMENT_BY_CARD_ONLY;
        setObject(o);
    }

    

    const onSelectEventCardPayment = () => {
        var obj = Object.assign({}, object);
        obj.state = OBJECTSTATE_EVENT_PAYMENT_BY_CARD_ONLY;
        setObject(obj);        
    
    }



    const roundToZero = x => {
        if(x < 0)
        {
            return 0;
        }
        return x;
    }


    const maxSpaces = ( el  ) => {

        switch(el.is_block)
        {
            case "3":
            case "0":
                return roundToZero(parseInt(el.stock) - parseInt(el.sold));
            
            case "1":
                return roundToZero(parseInt(el.stock) - parseInt(el.max_assoc_tx_count));
            default:
                return 0;     
        }

    }


    const onCartControllerPassPaymentContinue = ( o ) => {

        const start_mode = parseInt(o.start_mode);
    
        console.log("start mode", start_mode);
        if(start_mode !== 1 /*|| !!o.expires*/)
        {
            var x = {
                type: 'pass',
                mode: 'credit',
                id: uuidv4(),
                parent: o.id,
                name: o.name,
                price: o.price,
                credits: parseInt(o.credits),
                start_mode: parseInt(o.start_mode),
                period: parseInt(o.period),
                expiry_range: parseInt(o.expires),
                starts: (new Date()).toISOString(),
                expires: parseInt(o.period) === 0 ? moment().add(o.expires, 'days') : moment().add(o.expires, 'months'),
                start_mode:  parseInt(o.start_mode),
                has_bookings: false,
                transactions: [],
            };
            attachPassWallet( x );

            var z = {
                id: x.id,
                object: object.transaction,
            }
            pushProcessedToPassWallet( z );
            var o = Object.assign({}, object);
            //o.state = OBJECTSTATE_EVENT_PAYMENT_BY_PASS_ONLY;
            o.state = OBJECTSTATE_EVENT_CHECKOUT_DONE;

            setObject(o);        


        }
        
        else
        {
            var x = {
                type: 'pass',
                mode: 'credit',
                id: uuidv4(),
                parent: o.id,
                name: o.name,
                price: o.price,
                credits: parseInt(o.credits),
                start_mode: parseInt(o.start_mode),
                period: parseInt(o.period),
                expiry_range: parseInt(o.expires),
                starts: null,
                expires: null,
                start_mode:  parseInt(o.start_mode),
                has_bookings: false,
                transactions: [],
            };

            attachPassWallet( x );

            if(object?.transaction?.type === OBJECT_TYPE_VIDEO )
            {
                const starts = moment();
                const ends   = moment()
                if(x.period === 0)
                {
                    ends.add(x.expiry_range, 'days') 
                }
                else
                {
                    ends.add(x.expiry_range, 'months') 
                }
                var z = {
                    id: x.id,
                    object: object.transaction,
                    start_override:  starts,
                    expires_override: ends,
                }

                pushProcessedToPassWallet( z );
            }
            else
            {
                const dates = object?.transaction?.objects?.sort( (a, b) => {
                    const x = new Date(`${b.date} ${b.time}`);
                    const y = new Date(`${a.date} ${a.time}`);
                    return y - x;
                });
    
                const starts = moment(`${dates[0].date} ${dates[0].time}`)
                const ends = moment(`${dates[0].date} ${dates[0].time}`)
                if(x.period === 0)
                {
                    ends.add(x.expiry_range, 'days') 
                }
                else
                {
                    ends.add(x.expiry_range, 'months') 
                }
                var z = {
                    id: x.id,
                    object: object.transaction,
                    start_override:  starts,
                    expires_override: ends,
                }
    
    
                pushProcessedToPassWallet( z );
            }

            var o = Object.assign({}, object);
            //o.state = OBJECTSTATE_EVENT_PAYMENT_BY_PASS_ONLY;
            o.state = OBJECTSTATE_EVENT_CHECKOUT_DONE;

            setObject(o);        

        }
    }


    const onTicketUpdate = (tickets) => {
        switch(object.transaction.type)
        {
            case OBJECT_TYPE_EVENT_INDIVIDUAL:
            {
                if(tickets.length > 0)
                {

                    // clone the transaction object...
                    var o = Object.assign({}, object);
                    o.transaction.objects = tickets.map( ticket =>{
                        let obj = Object.assign({}, o.transaction.objects[0]);
                        obj.key_3 = ticket;
                        return obj;
                    });

                    o.state = OBJECTSTATE_EVENT_CHECK_PAYMENT_TYPE;
                    setObject(o);                    

                }
                else
                {
                    // error 
                }


            }
            break;

            case OBJECT_TYPE_EVENT_FLEXIBLE:
            {
                if(tickets.length > 0)
                {

                    // clone the transaction object...
                    var o = Object.assign({}, object);
                    o.transaction.objects = tickets.map( ticket =>{
                        let obj = Object.assign({}, o.transaction.objects[0]);
                        obj.key_3 = ticket;
                        return obj;
                    });

                    o.state = OBJECTSTATE_EVENT_WAIT_FOR_EVENT_ASSOCIATED;
                    setObject(o);                    

                }
                else
                {
                    // error 
                }


            }
            break;
            
            
            case OBJECT_TYPE_EVENT_BLOCK:
            {
                if(tickets.length > 0)
                {

                    // clone the transaction object...
                    var o = Object.assign({}, object);
                    o.transaction.objects = tickets.map( ticket =>{
                        let obj = Object.assign({}, o.transaction.objects[0]);
                        obj.key_3 = ticket;
                        return obj;
                    });
                    o.state = OBJECTSTATE_EVENT_ADD_BLOCK_ASSOCIATED_DATES;
                    setObject(o);                    

                }
                else
                {
                    // error 
                }
            }
            break;

        }
    }


    const onCartControllerPassConfirmContinue = ( ) => {
        setBusy(false);
        var o = Object.assign({}, object);
        o.state = OBJECTSTATE_EVENT_CHECKOUT_DONE;
        setObject(o);  

    }


    /*

    const onCartControllerPassConfirmCheckout = ( ) => {
        setBusy(false);
        var o = Object.assign({}, object);
        o.state = OBJECTSTATE_EVENT_CHECKOUT_DONE;
        setObject(o);        
        history.push('/checkout');        
    }
  
    */

    React.useEffect(() => {

            // we are in client mode  
            
        if(window.location !== window.parent.location)
        {
            window.parent.postMessage('rvReady', '*');
        }     


        openAccountConnection(); // on initial load only

        const onListen = event => {
            try{
                const data = JSON.parse(event.data);
                console.log('received', event);
                switch(data.message)
                {
    
                    case 'rvExtInitialize':
                        // server has asked client to update
                        initializeCart( data.data.cart || { card: [], pass: [], free:[], subscription:[]});
                        storeNoBroadCast( data.data.token || '' );
                    break;
                    
                    
                    default:
                    {

                    }
                    break;

                }
            }
            catch(e)
            {
                
            }

        }

        window.addEventListener("message", onListen)
    
        // clean up
        return () => {
            window.removeEventListener("message", onListen) 
        }
        



    }, []);


    const openAccountConnection =  ( ) => {
        //setBusy(true);
        /* Send a message to the server to see if we are logged in */
        var chain = '';
        setBusy(true);    

        if(CookieService.getCookie('rvli') !== null)
        {
            chain = 'token=' + CookieService.getCookie('rvli');
        }
        else
        {
            chain = 'token=' + 'nada';
        }



        fetch(window.rv_reservie_address + "api/ajax-get-customer-data/" + settings.id, {
            method: "POST",
            credentials: 'include',
            body: chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Authorization': 'Bearer ' + jwt

            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            if( response.status === 'OK')
            {
                /* Update the token */
                store(response.autoken, 2);
                addFilter( response.data?.filters || [] );
                if(response.data.passes.data.length > 0)
                {
                    for(var i = 0; i < response.data.passes.data.length; i++)
                    {
           
                            var x = {
                                type: 'pass',
                                mode: 'debit',
                                id: response.data.passes.data[i].id,
                                parent: response.data.passes.data[i].pass,
                                name: response.data.passes.data[i].name,
                                credits: parseInt(response.data.passes.data[i].credit_available),
                                period: parseInt(response?.data?.passes?.data[i]?.period),
                                expiry_range: parseInt(response?.data?.passes?.data[i]?.expiry_range),
                                start_mode: parseInt(response?.data?.passes?.data[i]?.start_mode),
                                starts: response?.data?.passes?.data[i]?.starts ? moment(response.data.passes.data[i].starts):null,
                                expires: response.data.passes.data[i].expires ? moment(response.data.passes.data[i].expires) : null,
                                starts: response?.data?.passes?.data[i]?.starts ? moment(response.data.passes.data[i].starts) : null,
                                has_bookings: response?.data?.passes?.data[i]?.has_bookings ? true : false,
                                transactions: [],
                            };
                            attachPassWallet( x );

                    }

                    setAccountConnectState(CART_OPEN_STATE_OPEN)   
                    setBusy(false);    


                }
                else
                {
                    setAccountConnectState(CART_OPEN_STATE_OPEN)   
                    setBusy(false);    
                }
            }                
            else
            {
                store(response.autoken, 2);
                setAccountConnectState(CART_OPEN_STATE_CLOSED)   
                setBusy(false);
            }
        }).catch(function (err) {
            setAccountConnectState(CART_OPEN_STATE_CLOSED)   
            setBusy(false);
        }); 
        

    }


    React.useEffect(() => { 

        if(event.length > 0 && accountConnectState === CART_OPEN_STATE_OPEN)
        {
            setShowLogIn(false);      
            switch(event[0].type)
            {
                case OBJECT_TYPE_EVENT_INDIVIDUAL:
                case OBJECT_TYPE_EVENT_BLOCK:
                case OBJECT_TYPE_EVENT_FLEXIBLE:
                {
                    if(!isEventFiltered(event[0].key_1, event[0].key_2) || (settings.event_book_multiples === true) || (settings.event_book_others === true))
                    {
                        isBusy();
                        const x = { key_1: event[0].key_1, key_2: event[0].key_2, key_3: event[0].key_3, qty: 1, date: event[0].object.date, time: event[0].object.time, name: event[0].object.name, payment_type: event[0].object.payment_type}; 
                        const o  = {state: EVENT_CHECK_OBJECT_TYPE, transaction: {objects: [x], type: event[0].type, id: event[0].id /*uuidv4()*/}, cache: null };
                        setObject( o );
                    }
                    else
                    {

                        // this is filtered - drop request
                        const x = { key_1: event[0].key_1, key_2: event[0].key_2, key_3: event[0].key_3, qty: 1, date: event[0].object.date, time: event[0].object.time, name: event[0].object.name, payment_type: event[0].object.payment_type}; 
                        const o  = {state: OBJECTSTATE_EVENT_CHECKOUT_CANCEL, transaction: {objects: [x], type: event[0].type, id: event[0].id /*uuidv4()*/}, cache: null };
                        setObject(o);                            
                    }

                }
                break;

                case OBJECT_TYPE_VIDEO:
                {
                    isBusy();
                    // we are using the same cart items here as the event - so we need to use the same constructs..
                    const x = { key_1: event[0].key_1, key_2: 'video-media', key_3: {price: event[0].object.price, credits: event[0].object.credits,id: 0}, object: event[0].object, qty: 1}; 
                    const o  = {state: VIDEO_CHECK_OBJECT_TYPE, transaction: {objects: [x], type: event[0].type, id: event[0].id/*uuidv4()*/}, cache: null };
                    setObject( o );
                }
                break;

                case OBJECT_TYPE_PASS:
                {
                    if(isPassFiltered(event[0].object.id))
                    {
                        var o = Object.assign({}, object);
                        o.state = OBJECTSTATE_EVENT_PASS_BUY_PASS_NOT_AVAILABLE;
                        setObject(o);   
                        return;                        
                    }

                    console.log("start mode", event[0].object.start_mode);
                    if(event[0].object.start_mode !== 1 /*|| !!event[0].object.expires*/)
                    {
            
                        var x = {
                            type: 'pass',
                            mode: 'credit',
                            id: uuidv4(),
                            parent: event[0].object.id,
                            name: event[0].object.name,
                            price: event[0].object.price,
                            credits: parseInt(event[0].object.credits),
                            period: parseInt(event[0].object.period),
                            expiry_range: parseInt(event[0].object.expires),
                            start_mode:  parseInt(event[0].object.start_mode),
                            starts: (new Date()).toISOString(),
                            expires: parseInt(event[0].object.period) === 0?moment().add(event[0].object.expires, 'days') : moment().add(event[0].object.expires, 'months') ,
                            has_bookings: false,
                            transactions: [],
                        };

                        attachPassWallet( x );  
                        const o  = {state: OBJECTSTATE_EVENT_CHECKOUT_DONE, transaction: {objects: [x], type: event[0].type, id: event[0].id /*uuidv4()*/}, cache: null };

                        setObject(o);                      
    
                    }
                    else
                    {
                        var x = {
                            type: 'pass',
                            mode: 'credit',
                            id: uuidv4(),
                            parent: event[0].object.id,
                            name: event[0].object.name,
                            price: event[0].object.price,
                            credits: parseInt(event[0].object.credits),
                            period: parseInt(event[0].object.period),
                            expiry_range: parseInt(event[0].object.expires),
                            start_mode:  parseInt(event[0].object.start_mode),
                            starts: null,
                            expires: null ,
                            has_bookings: false,
                            transactions: [],
                        };

                        attachPassWallet( x );                          
                        const o  = {state: OBJECTSTATE_EVENT_CHECKOUT_DONE, transaction: {objects: [x], type: event[0].type, id: event[0].id /*uuidv4()*/}, cache: null };

                        setObject(o);                      
    
                    }              

                }
                break;


                case OBJECT_TYPE_SUBSCRIPTION:
                {
                    var x = {
                        type: 'subscription',
                        id: uuidv4(),
                        iid: event[0].object.id,
                        parent: event[0].object.foreign_key,
                        name: event[0].object.name,
                        price: parseFloat(event[0].object.price_initial_base100>0?event[0].object.price_initial_base100:event[0].object.price_override_base100)/100,
                        credits: parseInt(event[0].object.credits),
                        period: parseInt(event[0].object.period),
                        expires: parseInt(event[0].object.period) === 0 ? moment().add(event[0].object.expires, 'days') : moment().add(event[0].object.expires, 'months') ,
                        expire_days: event[0].object.expires,
                        transactions: [],
                    };
            
                    attachSubscriptionWallet( x );

                    const o  = {state: OBJECTSTATE_EVENT_CHECKOUT_DONE, transaction: {objects: [x], type: event[0].type, id: event[0].id /*uuidv4()*/}, cache: null };
                    setObject(o);                      

                }
                break;


            }


    
            setBusy(false);


        }
        else if(event.length > 0 && accountConnectState === CART_OPEN_STATE_INTITIALISING)
        {
            openAccountConnection();
        }
        else if(event.length > 0 && accountConnectState === CART_OPEN_STATE_CLOSED)
        {
            setShowLogIn(true);      
        }
  
    }, [event.length, accountConnectState]);



    
    React.useEffect(() => {
        if(object !== null)
        {
            switch(object.state)
            {
                case EVENT_CHECK_OBJECT_TYPE:
                {
                    switch(object.transaction.type)
                    {
                        case OBJECT_TYPE_EVENT_INDIVIDUAL:
                        {
                            var o = Object.assign({}, object);

                            if(o.transaction.objects[0].key_3 === null)
                            {
                                let po = event[0].object.filtered_object.filter( o => {
                                    return o.deleted !== true 
                                }).map( o =>{

                                    if((parseInt(o.stock) - parseInt(o.sold)) > 0)
                                    {
                                        o.soldout = false;
                                    } 
                                    else
                                    {
                                        o.soldout = true;
                                    }
                                    return o;
                                });
                                setPos(po);
    
                                if(po.length === 1)
                                {
                                    var o = Object.assign({}, object);
                                    o.transaction.objects[0].key_3 = po[0];
                              
                                    o.state = OBJECTSTATE_EVENT_CHECK_PAYMENT_TYPE;
                                    setObject(o);
                                }
                                else if(po.length > 1)
                                {
                                    var o = Object.assign({}, object);
                                    o.state = OBJECTSTATE_EVENT_REQUEST_TICKET_TYPE;
                                    setObject(o);                                
                                }
                                else
                                {
                                    // error - add this to tell the user we are out of stock
                                    var o = Object.assign({}, object);
                                    //o.state = OBJECTSTATE_EVENT_CHECKOUT_DONE;
                                    o.state = OBJECTSTATE_EVENT_CHECKOUT_CANCEL;
                                    setObject(o);                                
                                }
                            }
                            else
                            {
                                o.state = OBJECTSTATE_EVENT_CHECK_PAYMENT_TYPE;
                                setObject(o);                                
                            }

                        }
                        break;

                        case OBJECT_TYPE_EVENT_FLEXIBLE:
                        {
                            var o = Object.assign({}, object);

                            if(o.transaction.objects[0].key_3 === null)
                            {
                                let po = event[0].object.filtered_object.filter( o => {
                                    return o.deleted !== true 
                                }).map( o =>{

                                    if((parseInt(o.stock) - parseInt(o.sold)) > 0)
                                    {
                                        o.soldout = false;
                                    } 
                                    else
                                    {
                                        o.soldout = true;
                                    }
                                    return o;
                                });
                                setPos(po);
    
                                if(po.length === 1)
                                {
                                    var o = Object.assign({}, object);
                                    o.transaction.objects[0].key_3 = po[0];
                              
                                    o.state = OBJECTSTATE_EVENT_WAIT_FOR_EVENT_ASSOCIATED;
                                    setObject(o);
                                }
                                else if(po.length > 1)
                                {
                                    var o = Object.assign({}, object);
                                    o.state = OBJECTSTATE_EVENT_REQUEST_TICKET_TYPE;
                                    setObject(o);                                
                                }
                                else
                                {
                                    // error - add this to tell the user we are out of stock
                                    var o = Object.assign({}, object);
                                    //o.state = OBJECTSTATE_EVENT_CHECKOUT_DONE;
                                    o.state = OBJECTSTATE_EVENT_CHECKOUT_CANCEL;
                                    setObject(o);                                
                                }
                            }
                            else
                            {
//                                o.state = OBJECTSTATE_EVENT_CHECK_PAYMENT_TYPE;
                                o.state = OBJECTSTATE_EVENT_WAIT_FOR_EVENT_ASSOCIATED;
                                setObject(o);                                
                            }

                        }
                        break;

                        
                        case OBJECT_TYPE_EVENT_BLOCK:
                        {
                            var o = Object.assign({}, object);

                            if(o.transaction.objects[0].key_3 === null)
                            {

                                let po = event[0].object.filtered_object.filter( o => {
                                    return o.deleted !== true 
                                }).map( o =>{

                                    if((parseInt(o.stock) - parseInt(o.sold)) > 0)
                                    {
                                        o.soldout = false;
                                    } 
                                    else
                                    {
                                        o.soldout = true;
                                    }
                                    return o;
                                });

                                //console.log('ticket selected', po);
                                setPos(po);

                                if(po.length === 1)
                                {
                                    var o = Object.assign({}, object);
                                    o.transaction.objects[0].key_3 = po[0];
                                    o.state = OBJECTSTATE_EVENT_ADD_BLOCK_ASSOCIATED_DATES;
                                    setObject(o);
                                }
                                else if(po.length > 1)
                                {
                                    var o = Object.assign({}, object);
                                    o.state = OBJECTSTATE_EVENT_REQUEST_TICKET_TYPE;
                                    setObject(o);                                
                                    
                                }
                                else
                                {
                                    // error 
                                    var o = Object.assign({}, object);
                                    //o.state = OBJECTSTATE_EVENT_CHECKOUT_DONE;
                                    o.state = OBJECTSTATE_EVENT_CHECKOUT_CANCEL;
                                    setObject(o);                                
                                }
                            }
                            else
                            {
                                o.state = OBJECTSTATE_EVENT_ADD_BLOCK_ASSOCIATED_DATES;
                                setObject(o);
                            }
                        }
                        break;                        
                    }
                    console.log(object);
                }
                break;


                case OBJECTSTATE_EVENT_REQUEST_TICKET_TYPE:
                {
                    // need to display tickets to the the customer to allow them to choose

                }
                break;


                case OBJECTSTATE_EVENT_ADD_BLOCK_ASSOCIATED_DATES:
                {
                    // need to display dates to the the customer

                }
                break;



                case OBJECTSTATE_EVENT_CHECK_PAYMENT_TYPE:
                {
                    switch(parseInt(event[0].object.payment_type))
                    {
                        case 0x01:
                        {
                            // pass only 
                            var o = Object.assign({}, object);
                            o.state = OBJECTSTATE_EVENT_PASS_CHECK_NEXT_ACTION;
                            setObject(o);
                        }
                        break;
                        case 0x02:
                        {
                            // card only 
                            var o = Object.assign({}, object);
                            o.state = OBJECTSTATE_EVENT_PAYMENT_BY_CARD_ONLY;
                            setObject(o);
                        }
                        break;  
                        case 0x03:
                        {
                            // card or pass 
                            var o = Object.assign({}, object);
                            o.state = OBJECTSTATE_EVENT_PAYMENT_CARD_OR_PASS;
                            setObject(o);
                        }
                        break;                        
                        
                    } 
                }
                break;

                case OBJECTSTATE_EVENT_PAYMENT_BY_CARD_ONLY:
                {
                    pushProcessedToCardWallet( object.transaction );
                    var o = Object.assign({}, object);
                    o.state = OBJECTSTATE_EVENT_CHECKOUT_DONE;
                    setObject(o);
                }
                break;
                

                case OBJECTSTATE_MEDIA_PASS_CHECK_NEXT_ACTION:
                {
                        // OK first job - check if there is a wallet that can be used in the cart
    
                        //console.log('Checking objects', object.transaction.objects);
                        object.transaction.objects.sort((first, second) => {
                            const f = moment(first.date + "T" + first.time);
                            const s = moment(second.date + "T" + second.time);
    
                            if(f.isBefore(s))
                            {
                                return -1;
                            }
                            else if(f.isAfter(s))
                            {
                                return 1;
                            }
                            else
                            {
                                return 0;
                            }
                        });
    
    
    
                        //console.log("sorted dates", object.transaction.objects);
                        let credits = 0;
                        credits = object.transaction.objects.reduce( ( credits, o) => {
                            return credits + (parseInt(o.key_3.credits));
                        }, 0);
    
                        if(credits <= 0)
                        {
                            var o = Object.assign({}, object);
                            o.state = OBJECTSTATE_EVENT_PASS_BUY_PASS_CREDIT_VALUE_ZERO;
                            setObject(o);   
                            console.error("Pass is not there - error - not enough credits 0x02");
                            return;
    
                        }
                        
                        const expires = moment();                    
                        var search = false;

                        for(let i = 0; i < event[0]?.object?.passes?.length && !search; i++)
                        {
                             var pass = event[0]?.object?.passes[i];
                             search = getWalletFromCartIfAvailable(pass.id, credits, expires);
                        }
    
                        //console.log('Need pass?', search, credits, expires);
                        if(!!search)
                        {           
                            if(!search?.expires)
                            {
                                const starts = moment();
                                const ends = moment();
                                if(search.period === 0)
                                {
                                    ends.add(search.expiry_range, 'days') 
                                }
                                else
                                {
                                    ends.add(search.expiry_range, 'months') 
                                }

                                var z = {
                                    id: search.id,
                                    object: object.transaction,
                                    start_override:  starts,
                                    expires_override: ends,
                                }
        
                                pushProcessedToPassWallet( z );                                
                            }
                            else
                            {
                                var z = {
                                    id: search.id,
                                    object: object.transaction,
                                    start_override:  null,
                                    expires_override: null
                                }
        
                                pushProcessedToPassWallet( z );                                
                            }
                            
                
                            var o = Object.assign({}, object);
                            //o.state = OBJECTSTATE_EVENT_PAYMENT_BY_PASS_ONLY;
                            o.state = OBJECTSTATE_EVENT_CHECKOUT_DONE;
    
                            setObject(o);                           
                            //console.log("Pass is there");
                        }
                        else
                        {
                  
    
                            // OK we need to buy a pass - but is there a pass with a long enough expiration date? 
                            let passes = event[0]?.object?.passes?.filter( pass => {
                                if(parseInt(pass.start_mode) !== 1)
                                {
                                    const n = parseInt(pass.period) === 0 ? moment().add(pass.expires, 'days') : moment().add(pass.expires, 'months');
                                    if(n.isBefore(expires))
                                    {
                                        return false;
                                    }
                                }
                                else
                                {
                                    return true
                                }

                                return true;
                            });
    
                            passes = passes?.filter( pass => {
                                return parseFloat(pass.price) >= 0 && parseInt(pass.subscriptionlimited) === 0 && isPassFiltered(pass.id) === false
                            });
                            


    
                            if(passes?.length > 0)     
                            {    
                                
                                /* OK so passes are available that will allow us to fit all events in. So now lets see if this transaction will fit inside a single pass */
                                // ask user what to do
    
                                passes = passes.filter( pass => {
                                    //console.log("checking credits", pass, parseInt(pass.credits), credits);
                                    if(parseInt(pass.credits) >= credits)
                                    {
                                        //console.log("found pass", pass, credits);
                                        return true;
                                    }
                                    return false;
                                });
    
    
                                if(passes.length > 0)   
                                {
                                    setAllowedPasses(passes);
                                    var o = Object.assign({}, object);
                                    o.state = OBJECTSTATE_MEDIA_PASS_BUY_PASS_NOW;
                                    setObject(o);   
                                    //console.log("Pass is not there - buy");
                                }
                                else
                                {
                                    
    
    
    
                                    var o = Object.assign({}, object);
                                    o.state = OBJECTSTATE_EVENT_PASS_BUY_PASS_NOT_ENOUGH_CREDITS;
                                    setObject(o);   
        
                                    //console.error("Pass is not there - error - not enough credits 0x01");
                                }                     
    
                            }  
                            else
                            {
                                var o = Object.assign({}, object);
                                o.state = OBJECTSTATE_EVENT_PASS_BUY_ERROR_PASSES_EXPIRES_TOO_SOON;
                                setObject(o);   
    
                                //console.error("Pass is not there - error - expires too soon");
                            }                   
    
                        }
                    }
                    break;

                case OBJECTSTATE_EVENT_PASS_CHECK_NEXT_ACTION:
                {
                    // OK first job - check if there is a wallet that can be used in the cart

                    //console.log('Checking objects', object.transaction.objects);
                    object.transaction.objects.sort((first, second) => {
                        const f = moment(first.date + "T" + first.time);
                        const s = moment(second.date + "T" + second.time);

                        if(f.isBefore(s))
                        {
                            return -1;
                        }
                        else if(f.isAfter(s))
                        {
                            return 1;
                        }
                        else
                        {
                            return 0;
                        }
                    });



                    //console.log("sorted dates", object.transaction.objects);
                    let credits = 0;
                    credits = object.transaction.objects.reduce( ( credits, o) => {
                        return credits + (parseInt(o.key_3.credits) * parseInt(o.qty));
                    }, 0);

                    if(credits <= 0)
                    {
                        var o = Object.assign({}, object);
                        o.state = OBJECTSTATE_EVENT_PASS_BUY_PASS_CREDIT_VALUE_ZERO;
                        setObject(o);   
                        console.error("Pass is not there - error - not enough credits 0x02");
                        return;

                    }
                    
                    const expires = moment( object.transaction.objects[object.transaction.objects.length-1].date + "T" + object.transaction.objects[object.transaction.objects.length-1].time );   
                                        

                    var search = false;

                    for(let i = 0; i < event[0]?.object?.passes?.length && !search; i++)
                    {
                            var pass = event[0]?.object?.passes[i];
                            search = getWalletFromCartIfAvailable(pass.id, credits, expires);
                    }
                   
                    //console.log('Need pass?', search, credits, expires);
                    if(!!search)
                    {       
                        if(!search?.expires)
                        {
                            const dates = object?.transaction?.objects?.sort( (a, b) => {
                                const x = new Date(`${b.date}T${b.time}`);
                                const y = new Date(`${a.date}T${a.time}`);
                                return y - x;
                            });


                            if(object?.transaction?.type === OBJECT_TYPE_VIDEO )
                            {
                                const starts = moment();
                                const ends   = moment()
                                if(search.period === 0)
                                {
                                    ends.add(search.expiry_range, 'days') 
                                }
                                else
                                {
                                    ends.add(search.expiry_range, 'months') 
                                }
                                var z = {
                                    id: search.id,
                                    object: object.transaction,
                                    start_override:  starts,
                                    expires_override: ends,
                                }
        
                                pushProcessedToPassWallet( z );
                            }
                            else
                            {
                                const starts = moment(`${dates[0].date} ${dates[0].time}`)
                                const ends = moment(`${dates[0].date} ${dates[0].time}`)
                                if(search.period === 0)
                                {
                                    ends.add(search.expiry_range, 'days') 
                                }
                                else
                                {
                                    ends.add(search.expiry_range, 'months') 
                                }
                                var z = {
                                    id: search.id,
                                    object: object.transaction,
                                    start_override:  starts,
                                    expires_override: ends,
                                }
        
                                pushProcessedToPassWallet( z );
                            }
                            

                        }
                        else
                        {
                            var z = {
                                id: search.id,
                                object: object.transaction,
                                start_override: null,
                                expires_override: null,
                            }
    
                            pushProcessedToPassWallet( z );
                        }

                        var o = Object.assign({}, object);
                        //o.state = OBJECTSTATE_EVENT_PAYMENT_BY_PASS_ONLY;
                        o.state = OBJECTSTATE_EVENT_CHECKOUT_DONE;

                        setObject(o);                           
                        //console.log("Pass is there");
                    }
                    else
                    {
              

                        // OK we need to buy a pass - but is there a pass with a long enough expiration date? 
                        let passes = event[0].object.passes.filter( pass => {
                            if(parseInt(pass.start_mode) !== 1)
                            {
                                const n = parseInt(pass.period) === 0 ? moment().add(pass.expires, 'days') : moment().add(pass.expires, 'months');
                                if(n.isBefore(expires))
                                {
                                    return false;
                                }
                            }
                            return true;
                        });

                        passes = passes.filter( pass => {
                            return parseFloat(pass.price) >= 0 && parseInt(pass.subscriptionlimited) === 0 && isPassFiltered(pass.id) === false
                        });

                        passes = passes?.filter( pass => {
                            console.log("checking pass filter", pass?.is_filtered);
                            return !pass?.is_filtered;
                        });

                        if(passes.length > 0)     
                        {    
                            
                            /* OK so passes are available that will allow us to fit all events in. So now lets see if this transaction will fit inside a single pass */
                            // ask user what to do

                            passes = passes.filter( pass => {
                                //console.log("checking credits", pass, parseInt(pass.credits), credits);
                                if(parseInt(pass.credits) >= credits)
                                {
                                    //console.log("found pass", pass, credits);
                                    return true;
                                }
                                return false;
                            });


                            if(passes.length > 0)   
                            {
                                setAllowedPasses(passes);
                                var o = Object.assign({}, object);
                                o.state = OBJECTSTATE_EVENT_PASS_BUY_PASS_NOW;
                                setObject(o);   
                                //console.log("Pass is not there - buy");
                            }
                            else
                            {
                                



                                var o = Object.assign({}, object);
                                o.state = OBJECTSTATE_EVENT_PASS_BUY_PASS_NOT_ENOUGH_CREDITS;
                                setObject(o);   
    
                                //console.error("Pass is not there - error - not enough credits 0x01");
                            }                     

                        }  
                        else
                        {
                            var o = Object.assign({}, object);
                            o.state = OBJECTSTATE_EVENT_PASS_BUY_ERROR_PASSES_EXPIRES_TOO_SOON;
                            setObject(o);   

                            //console.error("Pass is not there - error - expires too soon");
                        }                   

                    }
                }
                break;


                case OBJECTSTATE_EVENT_PAYMENT_CARD_OR_PASS:
                case OBJECTSTATE_EVENT_PAYMENT_BY_PASS_ONLY:
                case OBJECTSTATE_EVENT_PASS_BUY_PASS_NOW:
                case OBJECTSTATE_EVENT_PASS_BUY_ERROR_PASSES_EXPIRES_TOO_SOON:
                case OBJECTSTATE_EVENT_PASS_BUY_PASS_NOT_ENOUGH_CREDITS:
                case OBJECTSTATE_EVENT_PASS_BUY_PASS_CREDIT_VALUE_ZERO:
                case OBJECTSTATE_EVENT_PASS_BUY_PASS_NOT_AVAILABLE:
                {

                }
                break;

                case OBJECTSTATE_EVENT_CHECKOUT_DONE:
                {
  
                }
                break;

                case OBJECTSTATE_EVENT_CHECKOUT_CANCEL:
                {
                    setObject({state: null, transaction: {}});
                    popEvent();
                }
                break;


                case VIDEO_CHECK_OBJECT_TYPE:
                {
                    var o = Object.assign({}, object);              
                    o.state = OBJECTSTATE_VIDEO_CHECK_PAYMENT_TYPE;
                    setObject(o);
                    console.log(o);
                }
                break;


                case OBJECTSTATE_VIDEO_CHECK_PAYMENT_TYPE:
                {
                    if(event[0].object.pass && event[0].object.card)
                    {
                        var o = Object.assign({}, object);
                        o.state = OBJECTSTATE_VIDEO_PAYMENT_CARD_OR_PASS;
                        setObject(o);
                    }
                    else if(event[0].object.card)
                    {
                        var o = Object.assign({}, object);
                        o.state = OBJECTSTATE_VIDEO_PAYMENT_BY_CARD_ONLY;
                        setObject(o);
                    }
                    else if(event[0].object.pass)
                    {
                        var o = Object.assign({}, object);
                        o.state = OBJECTSTATE_VIDEO_PAYMENT_BY_PASS_ONLY;
                        setObject(o);
                    }
                    console.log(o);

                }
                break;


                case OBJECTSTATE_VIDEO_PAYMENT_CARD_OR_PASS:
                {
               
                }
                break;

                case OBJECTSTATE_VIDEO_PAYMENT_BY_CARD_ONLY:
                {
                    pushProcessedToCardWallet( object.transaction );
                    var o = Object.assign({}, object);
                    o.state = OBJECTSTATE_EVENT_CHECKOUT_DONE;
                    setObject(o);
                    console.log(o);     
                }
                break;  

                case OBJECTSTATE_VIDEO_PAYMENT_BY_PASS_ONLY:
                {
                    var o = Object.assign({}, object);
                    o.state = OBJECTSTATE_MEDIA_PASS_CHECK_NEXT_ACTION;
                    setObject(o);
                    console.log(o);
                }
                break;   

            }
        }
    }, [object.state])

    
    return ( 
        <div>
                {
                    mode === 'schedule' && <Schedule />
                }
                {
                    mode === 'passes' && <Passes />
                } 
                {
                    mode === 'subscriptions' && <Subscriptions />
                }                 
                {
                    mode === 'cart' && <Cart />
                } 
                {
                    mode === 'checkout' && <Checkout onFlush={onResetState} />
                } 
                {
                    mode === 'videos' && <Videos />
                }                                                               
                {
                    showLogIn && <LogInPanel onChildBusy={isBusy} onChildIdle={isIdle} onReady={isLoggedIn} show={showLogIn} onCancel={()=>{setShowLogIn(false); onPanelCancel()}}/>
                }
                {
                    busy && <div className="spin-overlay"><ImSpinner2  className="spinner dark" /></div>
                } 

            
                <CartControllerPanelPassesImportAvailable
                            passes={fetchPendingPassWallets()}
                            show={accountConnectState === CART_OPEN_STATE_PASS_ACTION}
                            onAddPasses={onAccountConnectAddPasses}
                            onIgnorePasses={onAccountConnectIgnorePasses}
                            onDone={onPanelCancel}
                />  


                {
                    object !== null && object.state === OBJECTSTATE_EVENT_REQUEST_TICKET_TYPE && 
                    <CartControllerPanelSelectTicket 
                    show={true}
                    key_1={event[0].key_1}
                    key_2={event[0].key_2}
                    booking_type={event[0].object.is_block}
                    override={parseInt(event[0].object.overridemax)}
                    poollimit={event[0].object.stock}
                    tickets={pos} 
                    payment_type={event[0].object.payment_type}
                    onUpdate={onTicketUpdate}
                    onCancel={onPanelCancel}
                    />
                    }

                {
                    object !== null && object.state === OBJECTSTATE_EVENT_ADD_BLOCK_ASSOCIATED_DATES &&
                    <CartControllerPanelSelectCourseAssociated 
                    show={true} 
                    max={event[0].object?maxSpaces(event[0].object):0}
                    dates={event[0].object?event[0].object.associated:[]} 
                    objects={object.transaction.objects}                
                    onAddDates={onAddCourseDates}
                    onCancel={onPanelCancel}
                    />    
                }

                {
                    object !== null && object.state === OBJECTSTATE_EVENT_REMOTE_PASSES_ADDED && 
                    <CartControllerPanelConfirmPassesAdded 
                            passes={object.cache}
                            show={true}
                            onContinue={onCartControllerPassSelectPaymentTypeContinue}
                            onCancel={onPanelCancel}
                        />

                }

                {
                    object !== null && object.state === OBJECTSTATE_EVENT_PASS_BUY_PASS_NOW && 
                    <CartControllerPanelSelectAcceptedPass 
                        show={true} 
                        acceptedPasses={ allowedPasses } 
                        onContinue={onCartControllerPassPaymentContinue}
                        onCancel={onPanelCancel} />                
                }

                {
                    object !== null && object.state === OBJECTSTATE_EVENT_PASS_BUY_ERROR_PASSES_EXPIRES_TOO_SOON && 
                    <CartControllerPanelErrorPassesNotAvailableWithExpiry 
                            show={true}
                            onDone={onPanelCancel}
                        />                    
                }
                
                {
                    object !== null && object.state === OBJECTSTATE_EVENT_PASS_BUY_PASS_NOT_AVAILABLE && 
                    <CartControllerPanelErrorPassesNotForAccount 
                            show={true}
                            onDone={onPanelCancel}
                        />                    
                }
                {
                    object !== null && object.state === OBJECTSTATE_EVENT_PASS_BUY_PASS_NOT_ENOUGH_CREDITS && 
                    <CartControllerPanelErrorPassesNotAvailableWithCredits 
                            show={true}
                            onDone={onPanelCancel}
                        />                    
                }

                {
                    object !== null && object.state === OBJECTSTATE_EVENT_PASS_BUY_PASS_CREDIT_VALUE_ZERO && 
                    <CartControllerPanelErrorPassesCreditValueZero
                            show={true}
                            onDone={onPanelCancel}
                        />                    
                }

            


                {
                    object !== null && object.state === OBJECTSTATE_EVENT_INDIVIDUAL_CONFIRM_SPACES && 
                    <CartControllerPanelConfirmSpaces  
                    show={true} 
                    max={event[0].object?maxSpaces(event[0].object):0}
                    objects={object.transaction.objects}                
                    onAddDates={onAddCourseDates}
                    onCancel={onPanelCancel}
                    />                        
                }

                {
                    object !== null && object.state === OBJECTSTATE_EVENT_WAIT_FOR_EVENT_ASSOCIATED && 
                    <CartControllerPanelSelectAssociated 
                                show={true} 
                                this_obj={ {key_1: object.transaction.objects[0].key_1, key_2: object.transaction.objects[0].key_2, key_3: object.transaction.objects[0].key_3} } 
                                max={event[0].object?maxSpaces(event[0].object):0}            
                                dates={event[0].object?event[0].object.associated:[]} 
                                attached={object.transaction.attached}
                                onCancel={onPanelCancel}
                                onAddDates={onAddCourseDates}
                                />
                            } 

                {
                    object !== null && object.state === OBJECTSTATE_EVENT_PAYMENT_CARD_OR_PASS &&
                    <CartControllerPanelSelectPassOrCard show={true}
                    onCancel={onPanelCancel}
                    onSelectPass={onSelectEventPassPayment}
                    onSelectCard={onSelectEventCardPayment}
                    />
                }

                {
                    object !== null && object.state === OBJECTSTATE_EVENT_CHECKOUT_DONE && 
                    <CartControllerPanelOnDone show={true}   onCancel={onPanelObjectEnd} onCheckout={onPanelRedirectToCart} /> 
                }

                {
                    object !== null && object.state === OBJECTSTATE_VIDEO_PAYMENT_CARD_OR_PASS  &&
                    <CartControllerPanelVideoSelectPassOrCard 
                    show={true}
                    onCancel={onPanelCancel}
                    onSelectPass={onSelectMediaPassPayment}
                    onSelectCard={onSelectMediaCardPayment}
                    />
                    
                }

                {
                    object !== null && object.state === OBJECTSTATE_MEDIA_PASS_BUY_PASS_NOW && 
                    <CartControllerPanelSelectAcceptedPass 
                        show={true} 
                        acceptedPasses={ allowedPasses } 
                        onContinue={onCartControllerPassPaymentContinue}
                        onCancel={onPanelCancel} />                
                }

        </div>

        );
    


}
 
export default CartController;