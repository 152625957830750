import React, { useContext, useState} from 'react';
import styles from './Login.module.scss';
import {CartIcon} from '../components/icons';
import { ModalContext } from '../contexts/ModalContext';

const CartControllerPanelOnDone = ({ show, onCancel, onCheckout}) => {

    const {modalOpen, modalClose} = React.useContext(ModalContext);
  


    React.useEffect(()=>{
        modalOpen();
    }, [])


    const handleClose = () => {
        modalClose();
        onCancel()
    }

    const handleCheckout = () => {
        modalClose();
        onCheckout();
    }



    return (
        <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, display: show ?'block':'none'}}>
            <div className={"modal fade-in2s "  + ( show? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                <div className={"modal-dialog modal-lg "+ styles.p__modal} role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Cart updated</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form >
                        {
                            <div className="container-fluid">
                                <div className={"row " + styles.p__rowCenter}>
                                    <div className="col-sm-12 mb-1">
                                        <p>Thank your your cart has been updated. You may either proceed to checkout or continue booking. You can proceed to the checkout at any time by clicking cart <span className="ico"><CartIcon/></span> above.</p>
                                    </div>
                                </div>
                            </div>

                        }
                    </form>  
                    </div>
                    <div className="modal-footer">
                        <div className={styles.p__rVieModalFooterText}>What would you like to do?</div>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>Continue shopping</button>
                        <button type="button" className="btn btn-primary" onClick={handleCheckout}>Proceed to Checkout</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
  

  export default CartControllerPanelOnDone;