import React, {useContext} from 'react';
import Layout from '../../components/Layout';
import styles from './Subscriptions.module.scss';
import {subscriptionList} from '../../services/scheduleList'
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { CartControllerContext, OBJECT_TYPE_SUBSCRIPTION } from '../../contexts/CartControllerContext';
import { formatNumber } from '../../helpers/utils';
import { PreferenceContext } from '../../contexts/PreferenceContext';
import { useHistory } from 'react-router-dom';
import ConfirmSubscriptionDuplicate from './ConfirmSubscriptionsDuplicate';
import ConfirmSubscriptionsNotEmpty from './ConfirmSubscriptionsNotEmpty';

const template = {show: false, name: '', price: '', credits: '', expires: moment(), expire_days: 0};

const STATE_INITIALISE = 1;
const STATE_DISPLAY_EMPTY_CART_ADVISOR = 2;
const STATE_CHECK_DUPLICATE = 3;
const STATE_DISPLAY_DUPLICATE_ADVISOR = 4;


const Subscriptions = () => {
    const { pushEvent, subscriptionItemCount, passItemCount, cardItemCount} = useContext(CartControllerContext);

    //const [added, setAdded] = React.useState(template);
    const [duplicateConfirm, setDuplicateConfirm] = React.useState(null);
    const [emptyConfirm, setEmptyConfirm] = React.useState(null);
    const [state, setState] = React.useState({ s: null, object: null});
    const { preference } = useContext(PreferenceContext);
    const history = useHistory();

    


    const onAddToCart = (o) => {

        var x = {
            type: OBJECT_TYPE_SUBSCRIPTION,
            key_1: o.id,
            key_2: null,
            key_3: null,
            id: uuidv4(),
            object: o
        }
        console.log('Adding to cart', x);
        pushEvent(x)           
    }

    const onAddToCartInitialize = (o) =>{
        var x = {
            type: OBJECT_TYPE_SUBSCRIPTION,
            key_1: o.id,
            key_2: null,
            key_3: null,
            id: uuidv4(),
            object: o
        }
        setState({s: STATE_INITIALISE, object: x});
    }


    React.useEffect(() =>{
        switch(state.s)
        {
            case STATE_INITIALISE:
            {
                if(passItemCount > 0 ||  cardItemCount > 0)
                {
                    let o = Object.assign({}, state);
                    o.s = STATE_DISPLAY_EMPTY_CART_ADVISOR;
                    setState(o);
                }
                else
                {
                    let o = Object.assign({}, state);
                    o.s = STATE_CHECK_DUPLICATE;
                    setState(o);
                }
            }
            break;

            case STATE_DISPLAY_EMPTY_CART_ADVISOR:
            {

            }
            break;

            case STATE_CHECK_DUPLICATE:
            {
                if(subscriptionItemCount > 0)
                {
                    let o = Object.assign({}, state);
                    o.s = STATE_DISPLAY_DUPLICATE_ADVISOR;
                    setState(o);

                }
                else
                {
                    //onAddToCart(state.object);
                    pushEvent(state.object)           
                    setState({ s: null, object: null});
                    
                }

            }
            break;       
            
            case STATE_DISPLAY_DUPLICATE_ADVISOR:
            {

            }
            break;            

        }

    }, [state.s]);



    const onDuplicateConfirmDuplicate = () => {
        pushEvent(state.object)           
        setState({ s: null, object: null});
    }


    const onConfirmContinueNotEmpty = () =>{
        let o = Object.assign({}, state);
        o.s = STATE_CHECK_DUPLICATE;
        setState(o);
    }
 

    const onCancel = () => {
        setState({ s: null, object: null});
    }
 

    console.log('subscription', subscriptionList );
    return ( 
        <Layout title="Subscriptions" description="Pass options" >
                    <div className={"row"}>
                        <div className="col-12 mt-4 mb-4 text-center">Subscriptions offer membership which allow passes to be automatically renewed on a recurring basis. </div>
                    </div>

                    <div className={"row " + styles.p__rowCenter}>
                            {
                                subscriptionList.filter(subscription => parseFloat(subscription.price_override_base100) > 0 && subscription.state === "1").map( subscription => (
                                        <div id={'sub-' + subscription.id} className="card col-12 col-md-3 p-4 m-4 box-shadow border-1" key={subscription.id} >
                                            <div className="card-body  d-flex flex-column">
                                                <h5 className="card-title">{subscription.name}</h5>
                                                <p className="card-text">
                                                {subscription.description}
                                                </p>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-text"><b>Credits:</b> {subscription.credits} </div>
                                                {
                                                    parseInt(subscription.period) === 0 &&
                                                    <div className="card-text"><b>Expires:</b> +{subscription.expires} day(s)</div>
                                                }
                                                {
                                                    parseInt(subscription.period) === 1 &&
                                                    <div className="card-text"><b>Expires:</b> +{subscription.expires} month(s)</div>
                                                }
                                                <div className="card-text"><b>Initial price:</b>  {formatNumber(parseFloat(subscription.price_initial_base100 > 0 ? subscription.price_initial_base100:subscription.price_override_base100)/100, preference.currency)} </div>
                                                <div className="card-text"><b>Standard ongoing price:</b>  {formatNumber(parseFloat(subscription.price_override_base100)/100, preference.currency)} </div>
                                            </div>
       

                                            {
                                                <button type="button" className={"align-self-end btn rv-sched-booknow rvPrimaryColorBtn  " + styles.p__rVieBtn} onClick={() => onAddToCartInitialize(subscription)}>Select</button>
                                            }
                      
                                        </div> 
                                ))
                            }
   


                    </div>
                    {
                        state.s === STATE_DISPLAY_EMPTY_CART_ADVISOR && <ConfirmSubscriptionsNotEmpty onContinue={onConfirmContinueNotEmpty} onCancel={onCancel} show={true}/>
                    }
                    {
                        state.s === STATE_DISPLAY_DUPLICATE_ADVISOR && <ConfirmSubscriptionDuplicate onContinue={onDuplicateConfirmDuplicate} onCancel={onCancel} show={true}/>
                    }                        
        </Layout>
     );
}
 
export default Subscriptions;