

export const ModalReducer = (state, action) => {
    switch (action.type) {
        case "OPEN_MODAL":
        {
                state.open = true;
                return {
                    ...state,
                }                 
        }

        case "CLOSE_MODAL":
            state.open = false;
            return {
                ...state,
        }


        default:
            return state

    }
}