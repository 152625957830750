import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import NotFound from '../pages/NotFound';
import CartController from "../controllers/CartController";
import ScrollToTop from "./ScrollToTop";

console.log("config", window.rvconfig?.schedule?.config?.ignoreinvalidroutes);

const Routes = () => {
  return (
    <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" render={(props) => (<CartController {...props} mode="schedule" />)}/>
            <Route path="/cart" render={(props) => (<CartController {...props} mode="cart" />)} />
            {
              (typeof window.rv_sched_data?.settings?.meta?.custom_view_subscriptions === "undefined" || window?.rv_sched_data?.settings?.meta?.custom_view_subscriptions === true) && 
              <Route path="/subscriptions" render={(props) => (<CartController {...props} mode="subscriptions" />)} />
            }
            {
              (typeof window.rv_sched_data?.settings?.meta?.custom_view_passes === "undefined" || window?.rv_sched_data?.settings?.meta?.custom_view_passes === true) && 
              <Route path="/passes" render={(props) => (<CartController {...props} mode="passes" />)} />
            }
            {

            (typeof window.rv_sched_data?.settings?.meta?.custom_view_videos === "undefined" || window?.rv_sched_data?.settings?.meta?.custom_view_videos === true) && 
            <Route path="/videos" render={(props) => (<CartController {...props} mode="videos" />)} />
            } 
                       
            <Route path="/checkout" render={(props) => (<CartController {...props} mode="checkout" />)} />
            {
              (!!window.rvconfig?.schedule?.config?.ignoreinvalidroutes) &&
              <Route  path="*" render={(props) => (<CartController {...props} mode="schedule" />)}/>
            }
            {
              (!!!window.rvconfig?.schedule?.config?.ignoreinvalidroutes) &&
              <Route path="*" component={NotFound} />
            }

          </Switch>
        </ScrollToTop>

    </Router>
  );
}

export default Routes;