import React from 'react';
import ProductItem from './ProductItem';
import styles from './ProductsGrid.module.scss';
import Moment from 'react-moment';

const ProductsSection = ({data}) => {


    return ( 
        <div className={styles.p__container}>

            <div className="row">
                <div className="col-sm-12 col-md-2 rv-date-col">
                    <div className={styles.p__outline}><Moment format="ddd">{data.date}</Moment> <Moment format="ll">{data.date}</Moment></div>
                </div>
                <div className="col-sm-12 col-md-10">
                    {
                        data.events.map(event => (  
                            <div key={event.did} className={"container-fluid " + styles.p__Innercontainer}>
                                <ProductItem product={event}></ProductItem>
                            </div>
                        ))
                    }
                </div>
            </div>

        </div>
     );
}
 
export default ProductsSection;