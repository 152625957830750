import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import ModalContextProvider from './contexts/ModalContext';
import CookieContextProvider from './contexts/CookieContext';
import JwtContextProvider from './contexts/JwtContext';

import App from './App';

window.onload = function (){

    ReactDOM.render(
      <JwtContextProvider>
      <CookieContextProvider>
        <ModalContextProvider>
          <App />
        </ModalContextProvider>
      </CookieContextProvider>
      </JwtContextProvider>
      ,
    document.getElementById(window.rv_sched_id)
  );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
