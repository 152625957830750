import React from 'react';
import Header from './shared/header';
import Footer from './shared/footer';
import styles from './Layout.module.scss';
import { Helmet } from 'react-helmet-async';


//import 'bootswatch/dist/lux/bootstrap.css'
//import './../index.scss';


const Layout = ({title, description, children}) => {

    return ( 
        <>
        <Helmet>
        </Helmet>
        <Header/>
            <div className={styles.p__rVieWrapper}>    
                <div className="container-fluid">
                    {children}
                </div>
            </div>

        <Footer/>
        </>
     );
}
 
export default Layout;