import React, { useContext} from 'react';
import styles from './Login.module.scss';
import { formatNumber } from '../helpers/utils';
import { PreferenceContext } from '../contexts/PreferenceContext';
import { isCardandPass, isCardOnly, isPassOnly, isFree } from '../contexts/ScheduleContext';
import { CartControllerContext } from '../contexts/CartControllerContext';
import { ScheduleContext } from '../contexts/ScheduleContext';
import { ModalContext } from '../contexts/ModalContext';


const CartControllerPanelSelectTicket = ({show, key_1, key_2, override, poollimit, booking_type, tickets, payment_type, onUpdate, onCancel}) => {

   // const { cache, clearCache, paymentOptions } = useContext(CartContext);
   const { preference } = useContext(PreferenceContext);
   const {countInCartStrict} = useContext(CartControllerContext);
   const [attachedTickets, setAttachedTickets] = React.useState([]);
   const {getMaxAvailableFromSet, getMaxSoldFromSet} = useContext(ScheduleContext);
   const {modalOpen, modalClose} = React.useContext(ModalContext);
   const [ticketpool, setTicketPool] = React.useState(poollimit)
  


   React.useEffect(()=>{
       modalOpen();

       let s = 0;
       if(override)
       {
            s = tickets.reduce((s, ticket) => {
                    switch(booking_type)
                    {
                        case "3": // flexible
                        case "0": // individual
                            return s + parseInt(ticket.sold) + countInCartStrict(key_1, key_2, ticket);
                            
                        case "1": // block
                            // what about the reset of the block
                            let sold = s + getMaxSoldFromSet(key_1, ticket) + countInCartStrict(key_1, key_2, ticket);
                            // now check if the sold in any other dates are more than this...
                            return sold;

                        default:
                            return s;     
                    }        
            }, 0);    
            setTicketPool(ticketpool - s);            
            
       }
       else
       {
            // no override

            s = tickets.reduce((s, ticket) => {
                    switch(booking_type)
                    {
                        case "3": // flexible
                        case "0": // individual
                            return s + parseInt(ticket.sold) + countInCartStrict(key_1, key_2, ticket);
                            
                        case "1": // block
                            let sold = s + getMaxSoldFromSet(key_1, ticket) + countInCartStrict(key_1, key_2, ticket);
                            // now check if the sold in any other dates are more than this...
                            return sold;

                        default:
                            return s;     
                    }        
            }, 0); 
            setTicketPool(ticketpool - s);
       }

        // eslint-disable-next-line
   }, []);

    const isAttached = (t) => {
        return !!attachedTickets.find(ticket => {
            return ticket.id === t.id
        });
    }

    const onSelect = ( ticket ) => {
        
        //setAttachedTickets([]);
       ///setTicketPool(poollimit);
        let x = [];

        x.push(ticket);
        setAttachedTickets(x);
        setTicketPool(poollimit - 1);
    }

    const isAvailable = ( ticket ) => {
        if(ticket.soldout === true)
        {
            return false;
        }
        else
        {
            let sold = parseInt(ticket.sold);
            let stock = parseInt(ticket.stock);
            let avail = 0;

            /*
            if(override && (stock > ticketpool))
            {
                console.log("Overriding stock with ticket pool", override, stock,  ticketpool);
                stock = ticketpool                
            }

            */
    
            console.log("booking type", booking_type);
            switch(booking_type)
            {
                case "3": // flexible
                case "0": // individual
                    console.log("Flex or individual", sold, stock);
                    avail = stock - sold - countInCartStrict(key_1, key_2, ticket);
                break;
                    
                case "1": // block
                    let setavail = getMaxAvailableFromSet(key_1, ticket);
                    const cartcount = countInCartStrict(key_1, key_2, ticket);
                    avail = setavail - cartcount;
                    if(avail > ticketpool)
                    {
                        avail = ticketpool;
                    }
                    console.log("block selection", setavail, cartcount, avail); 
                break;

                default:

                    return 0;     
            }



            // now check the count 
            console.log("final availability", ticket, key_1, key_2, avail)
            if(avail > 0)
            {
                return true;
            }
            return false;
        }
    }


    const onUnselect = ( ticket ) => {
        const x = attachedTickets.filter( t => t.id !== ticket.id);
        setAttachedTickets(x);
        setTicketPool(ticketpool + 1);

    }


    const handleClose = () => {
        modalClose();
        onCancel();
    }

    const onContinue = () => {
        modalClose();
        onUpdate(attachedTickets);
    }




    return (
        <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, display: show ?'block':'none'}}>
            <div className={"modal fade-in2s "  + ( show? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Select a ticket option</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    {
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <p>This event has multiple ticket options. Please select one or more of the following:</p>
                                </div>
                            </div>

             
                                
                            {
                                tickets.map( ticket => ( 
                                    <div className={"row mb-2 " + styles.p__rowCenter } key={"tckt-"+ ticket.id}>
                                        <div className="col-6 pt-2">
                                            {
                                                ticket.soldout === false && 
                                                <span>{ticket.name}</span>
                                            }
                                            {
                                                ticket.soldout === true && 
                                                <span><i>{ticket.name} - sold out</i></span>
                                            }                                            
                                        </div>
                                        <div className="col-3 pt-2">
                                            {isCardandPass(payment_type) && <span>{formatNumber(ticket.price, preference.currency)} or {parseInt(ticket.credits).toFixed(0)} credit(s)</span>}
                                            {isCardOnly(payment_type) && formatNumber(ticket.price, preference.currency)}
                                            {isPassOnly(payment_type) && <span>{parseInt(ticket.credits).toFixed(0)}  credit(s)</span> }                                           
                                            {isFree(payment_type) && "free" }                                          
                                        </div>
                                        <div className="col-3 text-center">
                                        {
                                            !isAttached(ticket) && isAvailable(ticket) &&
                                            <button 
                                            onClick={() => onSelect(ticket)}
                                            className={"btn btn-blk btn-sm rv-sched-booknow rvPrimaryColorBtn " + styles.p__rVieSmallBtn} disabled={false}>Select</button>                                            
                                            
                                        }
                                        {
                                            !isAttached(ticket) && !isAvailable(ticket) &&
                                            <button 
                                            onClick={() => onSelect(ticket)}
                                            className={"btn btn-sm rvPrimaryDisabled  " + styles.p__rVieSmallBtnDisabled} disabled={true}>sold out</button>                                               
                                        }

                                        {
                                            isAttached(ticket) && 
                                            <button 
                                            onClick={() => onUnselect(ticket)}
                                            className={"btn btn-sm rvPrimaryOutline "  + styles.p__rVieSmallBtnOutline}>UnSelect</button>                                            
                                            
                                        }
                                        
                                        </div>
                                    </div>
                                ))
                            } 
                   
                        </div>

                    }
                         
                    </div>
                    <div className="modal-footer">
                        <div className={styles.p__rVieModalFooterText}>click when you have selected a ticket option?</div>
                        <button type="button" className="btn btn-primary" onClick={onContinue} disabled={attachedTickets.length>0?false:true}>Continue</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
  

  export default CartControllerPanelSelectTicket;