import React, { createContext, useReducer } from 'react';
import { BookingFilterReducer } from './BookingFilterReducer';

export const BookingFilterContext = createContext()

const initialState = { 
                        filterlist: {events:[], passes:[]}
                     };



const BookingFilterContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(BookingFilterReducer, initialState);

    const addFilter = payload => {

        dispatch({type: 'ADD_FILTER', payload})

    }

    const isPassFiltered = key_1 => {
        //console.log("checcking filter list", state.filterlist, key_1);
        const r = state.filterlist.passes.find( x => {
            //console.log("Comparing",  x.key_1, key_1);
            //console.log("Comparing",  typeof x.key_1, typeof key_1);
            return parseInt(x.key_1) === parseInt(key_1)
        });
        //console.log("results", r, !!r);        
        return !!r;
    }

    const isEventFiltered = (key_1, key_2) => {
        return !!state.filterlist.events.find( x => {
            return x.key_1 === key_1 && x.key_2 === key_2
        })
    }


    const contextValues = {
        addFilter,
        isPassFiltered,
        isEventFiltered,
        ...state
    } 

    return ( 
        <BookingFilterContext.Provider value={contextValues} >
            { children }
        </BookingFilterContext.Provider>
     );
}
 
export default BookingFilterContextProvider;
