import React from 'react';
import styles from './Redirect.module.scss';

const Redirect = () => {

    return ( 
        <div>
            <h2 className={styles.p__title}>Oops, we have detected a problem</h2>
            <p>Your browser does not appear to allow 3rd party cookies. These are required to enable this website to work. To continue with your bookings, please proceed to our booking portal. Alternatively, please update your browser to allow 3rd party cookies.</p>
            <div className={styles.p__container}>
                <a href={window.rv_reservie_address + "s/-/" +window.rv_sched_data.id} className="btn btn-small btn-primary" target="_blank">Click to proceed to our booking portal.</a>
            </div>
        </div>
     );
}
 
export default Redirect;