import React, { useContext, useState} from 'react';
import styles from './Login.module.scss';
import { ModalContext } from '../contexts/ModalContext';


const CartControllerPanelVideoSelectPassOrCard = ({ show, onCancel, onSelectPass, onSelectCard}) => {

    const [type, setType] = useState('existing');

    const {modalOpen, modalClose} = React.useContext(ModalContext);
  


    React.useEffect(()=>{
        modalOpen();
    }, []);


    const handleClose = () => {
        modalClose();
        onCancel()
    }

    const handlePassSelect = () => {
        modalClose();
        onSelectPass()
    }

    const handleCardSelect = () => {
        modalClose();
        onSelectCard()
    }

    return (
        <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, display: show ?'block':'none'}}>
            <div className={"modal fade-in2s "  + ( show? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                <div className={"modal-dialog modal-lg "+ styles.p__modal} role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Select a payment type</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form >
                        {
                            <div className="container-fluid">
                                <div className={"row " + styles.p__rowCenter}>
                                    <div className="col-sm-12 mb-1">
                                        <p>This event can be booked with either a valid pass with sufficient credit and an expiry that is beyond the event date or via a credit or debit card.</p>
                                    </div>
                                </div>
                                <div className={"row " + styles.p__rowCenter}>
                                    <div className="card col-12 col-md-5 p-2 m-2 box-shadow border-1 text-center"  >
                                        <div className="card-body  d-flex flex-column pt-4 pb-2">
                                            <h5 className="card-title">Use pass</h5>
                                            <p className="card-text">
                                               Choose this option if you would like to use an existing or buy a new pass with which to book this event. 
                                            </p>
                                        </div>
                                        <button type="button" className={"align-self-center btn btn-primary rvPrimaryColorBtn  rv-sched-booknow mt-4 mb-4 " + styles.p__rVieBtn} onClick={handlePassSelect}>Book with a pass</button>
                                    </div> 
                                    <div className="card col-12 col-md-5 p-1 m-2 box-shadow border-1 text-center"  >
                                        <div className="card-body  d-flex flex-column pt-4 pb-2">
                                            <h5 className="card-title">Use credit or debit card</h5>
                                            <p className="card-text">
                                                Choose this option if you would like to pay by credit or debit card for this event.   
                                            </p>
                                        </div>
                                        <button type="button" className={"align-self-center btn btn-primary rvPrimaryColorBtn  rv-sched-booknow mt-4 mb-4  " + styles.p__rVieBtn} onClick={handleCardSelect}>Book with card</button>
                                    </div> 
                                </div>

            
                            </div>

                        }
                    </form>  
                    </div>
                    <div className="modal-footer">
                        &nbsp;
                    </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
  

  export default CartControllerPanelVideoSelectPassOrCard;