import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { PreferenceContext } from '../../contexts/PreferenceContext';
import { isFlexibleBooking, ScheduleContext } from '../../contexts/ScheduleContext';
import { WaitContext } from '../../contexts/WaitContext';
import styles from './ProductsGrid.module.scss';
import { isPassPaymentAllowed, isCardPaymentAllowed, isBlockBookingOnly, isFullyBooked, isCardandPass, isCardOnly, calcAvailability } from '../../contexts/ScheduleContext';
import { CartControllerContext, OBJECT_TYPE_EVENT_INDIVIDUAL, OBJECT_TYPE_EVENT_BLOCK, OBJECT_TYPE_EVENT_FLEXIBLE } from '../../contexts/CartControllerContext';
import { formatNumber } from '../../helpers/utils';
import DetailsPanel from './DetailsPanel';
import { PlusCircleIcon, MinusCircleIcon } from '../../components/icons'
import { v4 as uuidv4 } from 'uuid';
import {BookingFilterContext} from '../../contexts/BookingFilterContext'
import {  settings } from '../../services/scheduleList';

const ProductOption = ({product, options}) => {

    const [ query, setQuery ] = useState(false);
    const { pushEvent, removeItem, isInCart, countInCart, countInCartStrict } = useContext(CartControllerContext);
    const {getAvailabilityForEventTicket, getAvailabilityForEvent} = useContext(ScheduleContext);
    const { preference } = useContext(PreferenceContext);
    const { isEventFiltered } = useContext(BookingFilterContext);
    const show_highest = window.rvconfig?.schedule?.config?.showhighest || false;
    const show_highest_text = window.rvconfig?.schedule?.text?.showhighest || 'Discounts selectable on booking.';
    const show_overide_summary_text_block = window.rvconfig?.schedule?.config?.overide_summary_text_block || false;
    const show_overide_summary_text_other = window.rvconfig?.schedule?.config?.overide_summary_text_other || false;
    const overide_summary_text_block = window.rvconfig?.schedule?.text?.overide_summary_text_block || '';
    const overide_summary_text_other = window.rvconfig?.schedule?.text?.overide_summary_text_other || '';

    const option= options.sort((a,b) => {
        //console.log("checking payment type", product);
        if(isCardandPass(product.payment_type) || isCardOnly(product.payment_type))
        {
            //console.log("price based");
            if(show_highest)
            {
                return b.price - a.price
            }
            else
            {
                return a.price - b.price
            }
        }
        else
        {
            //console.log("credits based");
            if(show_highest)
            {
                return b.credits - a.credits
            }
            else
            {
                return a.credits - b.credits
            }
        }
    })[0];
    const multiOption = options.length > 1? true: false;
    const { schedule} = useContext(ScheduleContext)
    const { setWait, waitList } = useContext(WaitContext);
    const [object, setObject] = React.useState( false );
    const c = countInCart(product.eid, product.did);

    //console.log("option is ", multiOption);

    const isWaitList = (product, option) => {
        return waitList.find( item => item.object.eid === product.eid && item.object.did === product.did && item.option.id === option.id);
    } 


    const onRaiseEventIndividual = (product) => {

        var x = {
            type: OBJECT_TYPE_EVENT_INDIVIDUAL,
            key_1: product.eid,
            key_2: product.did,
            key_3: null,
            id: uuidv4(),
            object: product
        }
        //addProduct(x);
        //console.log("pushing", x);
        pushEvent(x)
    }

    const onRaiseEventFlexible = ( product ) => {
        var x = {
            type: OBJECT_TYPE_EVENT_FLEXIBLE,
            key_1: product.eid,
            key_2: product.did,
            key_3: null,
            id: uuidv4(),
            object: product
        }
        //addProduct(x);
        //console.log("pushing", x);
        pushEvent(x)        
    }


    const onCloneEventIndividual = (c, product) => {

        /*
        var x = {
            type: OBJECT_TYPE_EVENT_INDIVIDUAL,
            key_1: c.objects[0].key_1,
            key_2: c.objects[0].key_2,
//            key_3: c.objects[0].key_3,
            key_3: null,
            id: c.id,
            object: product
        }
        */
        var x = {
            type: OBJECT_TYPE_EVENT_INDIVIDUAL,
            key_1: product.eid,
            key_2: product.did,
            key_3: null,
            id: c.id,
            object: product
        }


        //addProduct(x);
        //console.log('clone', c);
        pushEvent(x)
    }

    const onCloneEventFlexible = (c, product) => {

        /*
        var x = {
            type: OBJECT_TYPE_EVENT_FLEXIBLE,
            key_1: c.objects[0].key_1,
            key_2: c.objects[0].key_2,
//            key_3: c.objects[0].key_3,
            key_3: null,
            id: c.id,
            object: product
        }

        */

        var x = {
            type: OBJECT_TYPE_EVENT_FLEXIBLE,
            key_1: product.eid,
            key_2: product.did,
//            key_3: c.objects[0].key_3,
            key_3: null,
            id: c.id,
            object: product
        }


        //addProduct(x);
        console.log('clone', c);
        pushEvent(x)
    }

    const onPopProduct = (product, specifier) => {
        removeItem(product.id, specifier);
    } 


    const onRaiseEventBlock = (product) => {

        //console.log("addinng", product);
        var x = {
            type: OBJECT_TYPE_EVENT_BLOCK,
            key_1: product.eid,
            key_2: product.did,
            key_3: null,   
            id: uuidv4(),         
            object: product
        }
        //addProduct(x);
        //console.log("pushing", x);

        pushEvent(x)
    }

    const onCloneEventBlock = (c, product) => {

        //console.log("addinng", product);
        /*
        var x = {
            type: OBJECT_TYPE_EVENT_BLOCK,
            key_1: c.objects[0].key_1,
            key_2: c.objects[0].key_2,
//            key_3: c.objects[0].key_3,   
            key_3: null,   
            id: c.id,         
            object: product
        }

        */

        var x = {
            type: OBJECT_TYPE_EVENT_BLOCK,
            key_1: product.eid,
            key_2: product.did,
            key_3: null,   
            id: c.id,         
            object: product
        }

        //addProduct(x);
        //console.log("clone", x);

        pushEvent(x)
    }


    const roundToZero = x => {
        if(x < 0)
        {
            return 0;
        }
        return x;
    }




    const cartRecord = isInCart(product.eid, product.did);
    const max        = calcAvailability(product);

   // console.log('cart record', cartRecord, product.eid, product.did);

    return ( 
        <div>  
            {

            
                    cartRecord && 
                    <div className={styles.p__rVieOptionBlock}>   

                        
                        {
                            // block booking and text override
                            isBlockBookingOnly(product) && show_overide_summary_text_block === true  &&        
                            <div className={styles.p__rVieBtnInfo}>{overide_summary_text_block}</div>    
                        }

                        {
                            // not block booking and text override
                            !isBlockBookingOnly(product) && show_overide_summary_text_other === true  &&        
                            <div className={styles.p__rVieBtnInfo}>{overide_summary_text_other}</div>    
                        }

                        {
                            // standard lowest first 
                            isBlockBookingOnly(product) && isCardPaymentAllowed(product) && !isPassPaymentAllowed(product) && show_highest === false  &&  show_overide_summary_text_block === false  &&        
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    
                            <div className={styles.p__rVieBtnInfo}>Price: <b>{multiOption?'from ': ''}</b> {formatNumber( parseFloat(option.price) * product.associated.length, preference.currency)} per{' '}
                                    {window.rvconfig?.schedule?.text?.course || 'course'} 
                             </div> 
                        }

                        {
                            // highest enabled. 
                            isBlockBookingOnly(product) && isCardPaymentAllowed(product) && !isPassPaymentAllowed(product) && show_highest === true  &&  show_overide_summary_text_block === false  &&        
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            <div className={styles.p__rVieBtnInfo}>Price: {formatNumber( parseFloat(option.price) * product.associated.length, preference.currency)} per{' '}
                            {window.rvconfig?.schedule?.text?.course || 'course'}. <p className="rvHighest">{multiOption? show_highest_text: ''}</p></div>    
                        }



                        {
                            // standard lowest first
                            isBlockBookingOnly(product) &&  isPassPaymentAllowed(product) && !isCardPaymentAllowed(product) && show_highest === false  &&  show_overide_summary_text_block === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    
                            (
                                <div className={styles.p__rVieBtnInfo}>Price: <b>{multiOption?'from ': ''}</b>  {(parseFloat(option.credits) * product.associated.length).toFixed(0)} pass credit(s) per{' '}
                                    {window.rvconfig?.schedule?.text?.course || 'course'} 
                                </div>
                            )

                        }
                        {
                            // highest first
                            isBlockBookingOnly(product) &&  isPassPaymentAllowed(product) && !isCardPaymentAllowed(product) && show_highest === true  &&  show_overide_summary_text_block === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    
                            (
                                <div className={styles.p__rVieBtnInfo}>Price:  {(parseFloat(option.credits) * product.associated.length).toFixed(0)} pass credit(s) per{' '}
                                {window.rvconfig?.schedule?.text?.course || 'course'} 
                                <p className="rvHighest">{multiOption? show_highest_text: ''}</p></div>
                            )

                        }




                        {
                            // standard lowest first
                            isBlockBookingOnly(product) && isPassPaymentAllowed(product) && isCardPaymentAllowed(product) && show_highest === false  &&  show_overide_summary_text_block === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    
                            (
                                <div className={styles.p__rVieBtnInfo}>Price: <b>{multiOption?'from ': ''}</b> {formatNumber(parseFloat(option.price) * product.associated.length, preference.currency)} or {parseFloat(option.credits).toFixed(0)} pass credit(s) per{' '}
                                    {window.rvconfig?.schedule?.text?.course || 'course'} 
                                </div>
                            )

                        }
                        {
                            // highest first
                            isBlockBookingOnly(product) && isPassPaymentAllowed(product) && isCardPaymentAllowed(product) && show_highest === true  &&  show_overide_summary_text_block === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (
                                <div className={styles.p__rVieBtnInfo}>Price:  {formatNumber(parseFloat(option.price) * product.associated.length, preference.currency)} or {parseFloat(option.credits).toFixed(0)} pass credit(s) per{' '}
                                {window.rvconfig?.schedule?.text?.course || 'course'} 
                                <p className="rvHighest">{multiOption? show_highest_text: ''}</p></div>
                            )

                        }




                        {
                            // standard lowest first
                            !isBlockBookingOnly(product) && isCardPaymentAllowed(product) && !isPassPaymentAllowed(product)  && show_highest === false  &&  show_overide_summary_text_other === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    
                            (                        
                                
                                <div className={styles.p__rVieBtnInfo}>Price: <b>{multiOption?'from ': ''}</b> {formatNumber(parseFloat(option.price), preference.currency)}</div>
                            )
                        }

                        {
                            // highest first
                            !isBlockBookingOnly(product) && isCardPaymentAllowed(product) && !isPassPaymentAllowed(product)  && show_highest === true  &&  show_overide_summary_text_other === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (                        
                                
                                <div className={styles.p__rVieBtnInfo}>Price: {formatNumber(parseFloat(option.price), preference.currency)} <p className="rvHighest">{multiOption? show_highest_text: ''}</p> </div>
                            )
                        }


                        {
                            // standard lowest first
                            !isBlockBookingOnly(product) && isPassPaymentAllowed(product) && !isCardPaymentAllowed(product)  && show_highest === false  &&  show_overide_summary_text_other === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    
                            (
                                <div className={styles.p__rVieBtnInfo}>Price: <b>{multiOption?'from ': ''}</b> {parseFloat(option.credits).toFixed(0)} pass credit(s)</div>
                            )

                        }

                        {
                            // highest first
                            !isBlockBookingOnly(product) && isPassPaymentAllowed(product) && !isCardPaymentAllowed(product)  && show_highest === true  &&  show_overide_summary_text_other === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (
                                <div className={styles.p__rVieBtnInfo}>Price: {parseFloat(option.credits).toFixed(0)} pass credit(s) <p className="rvHighest">{multiOption? show_highest_text: ''}</p></div>
                            )

                        }





                        {
                            // standard lowest first
                            !isBlockBookingOnly(product) && isPassPaymentAllowed(product) && isCardPaymentAllowed(product)  && show_highest === false  &&  show_overide_summary_text_other === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (
                                <div className={styles.p__rVieBtnInfo}>Price: <b>{multiOption?'from ': ''}</b>  {formatNumber(parseFloat(option.price), preference.currency)} or {parseFloat(option.credits).toFixed(0)} pass credit(s)</div>
                            )

                        }
                        {
                            // highest first
                            !isBlockBookingOnly(product) && isPassPaymentAllowed(product) && isCardPaymentAllowed(product)  && show_highest === true  &&  show_overide_summary_text_other=== false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (
                                <div className={styles.p__rVieBtnInfo}>Price: {formatNumber(parseFloat(option.price), preference.currency)} or {parseFloat(option.credits).toFixed(0)} pass credit(s)  <p className="rvHighest">{multiOption? show_highest_text: ''}</p></div>
                            )

                        }




                        {
                            isBlockBookingOnly(product) &&
                            <div>
                                    {((settings.event_book_multiples === false) && (settings.event_book_others === false)) && (
                                        <div>
                                            <button onClick={() => {onPopProduct(cartRecord, {type: OBJECT_TYPE_EVENT_BLOCK, key_1: product.eid, key_2: product.did})}}  className={"btn btn-sm btn-blk rv-sched-booknow rvPrimaryColorBtn " + styles.p__rVieBtn}>Selected</button>
                                        </div>
                                    )}

                                    {((settings.event_book_multiples === true) || (settings.event_book_others === true))  && (
                                        <div className={"clearfix " + styles.p__qtyContainer}>
                                            <button 
                                            onClick={() =>  {console.log("block"); onCloneEventBlock(cartRecord, product)}} 
                                            disabled={c >= max ? true: false}
                                            className={"btn btn-sm btn-blk rv-sched-booknow float-left mb-1 rvPrimaryColorBtn"}>
                                                <PlusCircleIcon width={"20px"}/>
                                            </button>
                                            <div className={"text-center " + styles.p__qtyDigit}><div>{c}</div><div className={styles.p__places}>place(s)</div></div>
                                            <button
                                                onClick={() => {onPopProduct(cartRecord, {type: OBJECT_TYPE_EVENT_BLOCK, key_1: product.eid, key_2: product.did})}}
                                                disabled={c < 1 ? true: false}
                                                className={"btn btn-sm btn-blk mb-1 rv-sched-booknow float-right rvSecondaryColorBtn"}>
                                                    <MinusCircleIcon width={"20px"}/>
                                            </button>
                                        </div>   
                                    )}

                                <Link  to="/" onClick={() => {setObject(product)}} className={"btn btn-link btn-sm " + styles.p__rVieInfoLnk}>{window.rvconfig?.schedule?.text?.details || "Details"}</Link>
                            </div>
                        }
                        {
                            !isBlockBookingOnly(product) && !isFlexibleBooking(product) &&
                            <div>
                                    {((settings.event_book_multiples === false)   && (settings.event_book_others === false)) && (
                                        <div>
                                            <button onClick={() => {onPopProduct(cartRecord, {type: OBJECT_TYPE_EVENT_INDIVIDUAL, key_1: product.eid, key_2: product.did})}} className={"btn btn-sm btn-blk rv-sched-booknow rvPrimaryColorBtn " + styles.p__rVieBtn}>Selected</button>
                                        </div>
                                    )}

                                    {((settings.event_book_multiples === true) || (settings.event_book_others === true)) && (

                                        <div className={"clearfix " + styles.p__qtyContainer}>
                                            <button 
                                            onClick={() =>  {onCloneEventIndividual(cartRecord, product)}}
                                            disabled={c >= max ? true: false}
                                            className={"btn btn-sm btn-blk rv-sched-booknow float-left mb-1 rvPrimaryColorBtn "}>
                                                <PlusCircleIcon width={"20px"}/>
                                            </button>
                                            <div className={"text-center " + styles.p__qtyDigit}><div>{c}</div><div className={styles.p__places}>place(s)</div></div>
                                            <button
                                                onClick={() => {onPopProduct(cartRecord, {type: OBJECT_TYPE_EVENT_INDIVIDUAL, key_1: product.eid, key_2: product.did})}}
                                                disabled={c < 1 ? true: false}
                                                className={"btn btn-sm btn-blk mb-1 rv-sched-booknow float-right rvSecondaryColorBtn"}>
                                                    <MinusCircleIcon width={"20px"}/>
                                            </button>
                                        </div>    
                                    )}                            

                                    <Link  to="/" onClick={() => {setObject(product)}} className={"btn btn-link btn-sm " + styles.p__rVieInfoLnk}>{window.rvconfig?.schedule?.text?.details || "Details"}</Link>
                            </div>
                        }
                        {
                            isFlexibleBooking(product) &&
                            <div>

                                    {((settings.event_book_multiples === false) && (settings.event_book_others === false)) && (
                                        <div>
                                            <button onClick={() => {onPopProduct(cartRecord, {type: OBJECT_TYPE_EVENT_FLEXIBLE, key_1: product.eid, key_2: product.did})}} className={"btn btn-sm btn-blk rv-sched-booknow rvPrimaryColorBtn " + styles.p__rVieBtn}>Selected</button>
                                        </div>
                                    )}

                                    {((settings.event_book_multiples === true)  || (settings.event_book_others === true)) && (

                                        <div className={"clearfix " + styles.p__qtyContainer}>
                                            <button 
                                            onClick={() =>  {onCloneEventFlexible(cartRecord, product)}}
                                            disabled={c >= max ? true: false}
                                            className={"btn btn-sm btn-blk rv-sched-booknow float-left mb-1 rvPrimaryColorBtn" }>
                                                <PlusCircleIcon width={"20px"}/>
                                            </button>
                                            <div className={"text-center " + styles.p__qtyDigit}><div>{c}</div><div className={styles.p__places}>place(s)</div></div>
                                            <button
                                                onClick={() => {onPopProduct(cartRecord, {type: OBJECT_TYPE_EVENT_FLEXIBLE, key_1: product.eid, key_2: product.did})}}
                                                disabled={c < 1 ? true: false}
                                                className={"btn btn-sm btn-blk rv-sched-booknow mb-1 float-right rvSecondaryColorBtn"}>
                                                    <MinusCircleIcon width={"20px"}/>
                                            </button>
                                        </div>    
                                    )}                            

                                    <Link  to="/" onClick={() => {setObject(product)}} className={"btn btn-link btn-sm " + styles.p__rVieInfoLnk}>{window.rvconfig?.schedule?.text?.details || "Details"}</Link>
                            </div>
                        }                        
                    </div>
            }

            {

                !cartRecord && (
                    <div className={styles.p__rVieOptionBlock}>   
                        {
                            // block booking and text override
                            isBlockBookingOnly(product) && show_overide_summary_text_block === true  &&        
                            <div className={styles.p__rVieBtnInfo}>{overide_summary_text_block}</div>    
                        }

                        {
                            // not block booking and text override
                            !isBlockBookingOnly(product) && show_overide_summary_text_other === true  &&        
                            <div className={styles.p__rVieBtnInfo}>{overide_summary_text_other}</div>    
                        }                    
                        {

                            // standard lowest
                            !isBlockBookingOnly(product) && isCardPaymentAllowed(product) && !isPassPaymentAllowed(product) && show_highest === false  &&  show_overide_summary_text_other === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (                        
                                <div className={styles.p__rVieBtnInfo}>Price: <b>{multiOption?'from ': ''}</b>  {formatNumber(parseFloat(option.price), preference.currency)}</div>
                            )
                        }

                        {

                            // standard highest
                            !isBlockBookingOnly(product) && isCardPaymentAllowed(product) && !isPassPaymentAllowed(product) && show_highest === true  &&  show_overide_summary_text_other === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (                        
                                <div className={styles.p__rVieBtnInfo}>Price: {formatNumber(parseFloat(option.price), preference.currency)} <p className="rvHighest">{multiOption? show_highest_text: ''}</p></div>
                            )
                        }




                        {

                            // standard lowest
                            !isBlockBookingOnly(product) &&  isPassPaymentAllowed(product) && !isCardPaymentAllowed(product) && show_highest === false  &&  show_overide_summary_text_other === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (
                                <div className={styles.p__rVieBtnInfo}>Price: <b>{multiOption?'from ': ''}</b>  {parseFloat(option.credits).toFixed(0)} pass credit(s)</div>
                            )

                        }
                        {

                            //  highest first
                            !isBlockBookingOnly(product) &&  isPassPaymentAllowed(product) && !isCardPaymentAllowed(product) && show_highest === true  &&  show_overide_summary_text_other === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (
                                <div className={styles.p__rVieBtnInfo}>Price: {parseFloat(option.credits).toFixed(0)} pass credit(s) <p className="rvHighest">{multiOption? show_highest_text: ''}</p></div>
                            )

                        }



                        {
                            // standard lowest
                            !isBlockBookingOnly(product) && isPassPaymentAllowed(product) && isCardPaymentAllowed(product) && show_highest === false  &&  show_overide_summary_text_other === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (
                                <div className={styles.p__rVieBtnInfo}>Price: <b>{multiOption?'from ': ''}</b>  {formatNumber(parseFloat(option.price), preference.currency)} or {parseFloat(option.credits).toFixed(0)} pass credit(s)</div>
                            )

                        }
                        {
                            //  highest first
                            !isBlockBookingOnly(product) && isPassPaymentAllowed(product) && isCardPaymentAllowed(product) && show_highest === true  &&  show_overide_summary_text_other === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (
                                <div className={styles.p__rVieBtnInfo}>Price: {formatNumber(parseFloat(option.price), preference.currency)} or {parseFloat(option.credits).toFixed(0)} pass credit(s) <p className="rvHighest">{multiOption? show_highest_text: ''}</p></div>
                            )

                        }


                        {
                            // standard lowest
                            isBlockBookingOnly(product) && isCardPaymentAllowed(product) && !isPassPaymentAllowed(product)  && show_highest === false  &&  show_overide_summary_text_block === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (                        
                                <div className={styles.p__rVieBtnInfo}>Price: <b>{multiOption?'from ': ''}</b>  {formatNumber( parseFloat(option.price) * product.associated.length, preference.currency)} per{' '}
                                {window.rvconfig?.schedule?.text?.course || 'course'}.</div>
                            )
                        }


                        {
                            //  highest first
                            isBlockBookingOnly(product) && isCardPaymentAllowed(product) && !isPassPaymentAllowed(product)  && show_highest === true  &&  show_overide_summary_text_block === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    
                            (    
                                <div className={styles.p__rVieBtnInfo}>Price: {formatNumber( parseFloat(option.price) * product.associated.length, preference.currency)} per {window.rvconfig?.schedule?.text?.course || 'course'}. <p className="rvHighest">{multiOption? show_highest_text: ''}</p></div>
                            )
                        }



                        {
                            // standard lowest
                            isBlockBookingOnly(product) &&  isPassPaymentAllowed(product) && !isCardPaymentAllowed(product)  && show_highest === false  &&  show_overide_summary_text_block === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (
                                <div className={styles.p__rVieBtnInfo}>
                                    Price: <b>{multiOption?'from ': ''}</b>  {(parseFloat(option.credits) * product.associated.length).toFixed(0)} pass credit(s) per{' '}
                                    {window.rvconfig?.schedule?.text?.course || 'course'} 
                                </div>
                            )

                        }

                        {
                            // highest first
                            isBlockBookingOnly(product) &&  isPassPaymentAllowed(product) && !isCardPaymentAllowed(product)  && show_highest === true  &&  show_overide_summary_text_block === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    
                            (
                                <div className={styles.p__rVieBtnInfo}>Price:   {(parseFloat(option.credits) * product.associated.length).toFixed(0)} pass credit(s) per{' '}
                                {window.rvconfig?.schedule?.text?.course || 'course'} <p className="rvHighest">{multiOption? show_highest_text: ''}</p></div>
                            )

                        }






                        {
                            // standard lowest
                            isBlockBookingOnly(product) && isPassPaymentAllowed(product) && isCardPaymentAllowed(product)   && show_highest === false  &&  show_overide_summary_text_block === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    

                            (
                                <div className={styles.p__rVieBtnInfo}>Price: <b>{multiOption?'from ': ''}</b>  {formatNumber(parseFloat(option.price) * product.associated.length, preference.currency)} or {parseFloat(option.credits).toFixed(0)} pass credit(s) 
                                per{' '} {window.rvconfig?.schedule?.text?.course || 'course'} 
                                </div>
                            )

                        }

                        {
                            // highest first
                            isBlockBookingOnly(product) && isPassPaymentAllowed(product) && isCardPaymentAllowed(product) && show_highest === true  &&  show_overide_summary_text_block === false  &&
                            !window?.rvconfig?.schedule?.config?.hidebookingsummary &&                    
                            (
                                <div className={styles.p__rVieBtnInfo}>Price: {formatNumber(parseFloat(option.price) * product.associated.length, preference.currency)} or {parseFloat(option.credits).toFixed(0)} pass credit(s) 
                                per{' '} {window.rvconfig?.schedule?.text?.course || 'course'} 
                                 <p className="rvHighest">{multiOption? show_highest_text: ''}</p></div>
                            )

                        }




                        {                                   
                                !isBlockBookingOnly(product) && !isFlexibleBooking(product) && !isFullyBooked(product) &&
                                <div>
                                    {
                                        isEventFiltered( product.eid, product.did) === true && settings.event_book_multiples === false /* &&  (settings.event_book_others === false) */ && (
                                            <button onClick={() => {}} disabled className={"btn btn-sm btn-blk rv-sched-booknow rvPrimaryColorBtn " + styles.p__rVieBtn}>Already booked</button>
                                        )
                                    }
                                    {
                                        (settings.event_book_multiples === true /*||  (settings.event_book_others === true) */ || ((isEventFiltered( product.eid, product.did) === false) && settings.event_book_multiples === false /*&& settings.event_book_others === true */ )) && (
                                        <button 
                                            onClick={() => onRaiseEventIndividual(product)}
                                            className={"btn btn-sm btn-blk rv-sched-booknow rvPrimaryColorBtn " + styles.p__rVieBtn}>
                                                {window.rvconfig?.schedule?.text?.select || "Select"}{" "}
                                                {window.rvconfig?.schedule?.text?.event || "Event"}
                                            </button>
                                        )
                                    }
                                    <Link  to="/" onClick={() => {setObject(product)}} className={"btn btn-link btn-sm " + styles.p__rVieInfoLnk}>{window.rvconfig?.schedule?.text?.details || "Details"}</Link>                                    
                                </div>                                
                        }
                        {       
                                                 
                                isFlexibleBooking(product) && !isFullyBooked(product) &&
                                <div>
                                    {
                                        (isEventFiltered( product.eid, product.did) === true && settings.event_book_multiples === false /* && settings.event_book_others === false */) && (
                                            <button onClick={() => {}} disabled className={"btn btn-sm btn-blk rv-sched-booknow rvPrimaryColorBtn " + styles.p__rVieBtn}>Already booked</button>
                                        )
                                    }

                                    {                                        
                                    
                                        (settings.event_book_multiples === true /*|| settings.event_book_others === true */|| (isEventFiltered( product.eid, product.did) === false) && settings.event_book_multiples === false /*&& settings.event_book_others === false */ ) && (
                                        <button 
                                        onClick={() => onRaiseEventFlexible(product)}
                                        className={"btn btn-sm btn-blk rv-sched-booknow rvPrimaryColorBtn " + styles.p__rVieBtn}>
                                            {window.rvconfig?.schedule?.text?.select || "Select"}{" "}
                                            {window.rvconfig?.schedule?.text?.event || "Event"}
                                        </button>)
                                    }
                                    
                                    <Link  to="/" onClick={() => {setObject(product)}} className={"btn btn-link btn-sm " + styles.p__rVieInfoLnk}>{window.rvconfig?.schedule?.text?.details || "Details"}</Link>                                    
                                </div>                                
                        }                        
                        {                                
                                isBlockBookingOnly(product) && !isFullyBooked(product) && product.associated.length > 1 &&
                                <div>
                                    {
                                        isEventFiltered( product.eid, product.did) === true && settings.event_book_multiples === false && (
                                            <button onClick={() => {}} disabled className={"btn btn-sm btn-blk rv-sched-booknow rvPrimaryColorBtn " + styles.p__rVieBtn}>Already booked</button>
                                        )
                                    }  

                                    {       
                                    (settings.event_book_multiples === true || /* settings.event_book_others === true || */ (isEventFiltered( product.eid, product.did) === false && settings.event_book_multiples === false /*&& settings.event_book_others === false */ )) && (
                                        <button
                                            onClick={() => onRaiseEventBlock(product, option)}
                                            className={"btn btn-sm btn-blk rv-sched-booknow rvPrimaryColorBtn " + styles.p__rVieBtn}
                                            >
                                            {window.rvconfig?.schedule?.text?.select || "Select"}{" "}
                                            {window.rvconfig?.schedule?.text?.course || "Course"}
                                        </button>)
                                    }
                                    <Link  to="/" onClick={() => {setObject(product)}} className={"btn btn-link btn-sm " + styles.p__rVieInfoLnk}>{window.rvconfig?.schedule?.text?.details || "Details"}</Link>                                    
                                </div>                                
                        }
                        {                                
                                isBlockBookingOnly(product) && !isFullyBooked(product) && product.associated.length === 1 &&
                                <div>
                                    <button 
                                    onClick={() => onRaiseEventBlock(product)}
                                    className={"btn btn-sm btn-blk rv-sched-booknow rvPrimaryColorBtn " + styles.p__rVieBtn}>
                                        {window.rvconfig?.schedule?.text?.select || "Select"}{" "}
                                        {window.rvconfig?.schedule?.text?.course || "Course"}
                                    </button>
                                    <Link  to="/" onClick={() => {setObject(product)}} className={"btn btn-link btn-sm " + styles.p__rVieInfoLnk}>{window.rvconfig?.schedule?.text?.details || "Details"}</Link>                                    
                                </div>                                
                        }
                        {                                
                                isFullyBooked(product) && !isWaitList( product, option) && 
                                <div>
                                    <button 
                                    onClick={() => setWait({object: product, option: option})}
                                    className={"btn btn-blk btn-sm rv-sched-booknow rv-sched-wait rvPrimaryColorBtn " + styles.p__rVieDangerBtn}>{window.rvconfig?.schedule?.text?.joinwait || "Join waiting list"}</button>
                                    <Link  to="/" onClick={() => {setObject(product)}} className={"btn btn-link btn-sm " + styles.p__rVieInfoLnk}>{window.rvconfig?.schedule?.text?.details || "Details"}</Link>                                    
                                </div>   
                                     
                        }
                        {
                                isFullyBooked(product) && isWaitList( product, option) && 
                                <div>
                                    <button 
                                    onClick={() => setWait({object: product, option: option})}
                                    className={"btn btn-blk btn-sm rv-sched-booknow rvPrimaryColorBtn " + styles.p__rVieDangerBtn} disabled={true}>{window.rvconfig?.schedule?.text?.joinedwait || "Joined waiting list"}</button>
                                    <Link  to="/"  onClick={() => {setObject(product)}} className={"btn btn-link btn-sm " + styles.p__rVieInfoLnk}>{window.rvconfig?.schedule?.text?.details || "Details"}</Link>                                    
                                </div>                              
                        }
                    </div>

                )
                
            } 

            {(!!object) &&
            <DetailsPanel show={true} object={object} onCancel={() => {setObject(false)}}/>
            }
        </div>
     );
}
 
export default ProductOption;