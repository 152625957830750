function sessionSet( what, s )
{
    try{
        if (typeof sessionStorage !== "undefined")
            sessionStorage.setItem(what, s);        
    }
    catch(e)
    {
        /* do nothing */
    }
    return
}


const Storage = (waitItems) => {
    sessionSet('rv-wait', JSON.stringify(waitItems.length > 0 ? waitItems: []));
}


export const storeItems = waitItems => {
    Storage(waitItems);
    let itemCount = waitItems.reduce((total, item) => total + 1, 0);
    return { itemCount }
}


export const WaitReducer = (state, action) => {
    switch (action.type) {
        case "CLEAR_WAIT":
        {
                state.waiting = null;
                return {
                    ...state,
                    waitList: [...state.waitList]
                }                 
        }

        case "SET_WAIT":
            state.waiting = { object: action.payload.object, option: action.payload.option }
            return {
                ...state,
        }

        case "COMMIT_WAIT":
        {
            if(state.waiting !== null)
            {
                state.waitList.push( state.waiting );    
                state.waiting = null;
            }
            return {
                ...state,
                ...storeItems(state.waitList),  
                waitList: [...state.waitList]
            }
        }

        default:
            return state

    }
}