import { act } from "react-dom/test-utils";
import moment from 'moment';
import { scheduleList, scheduleFlatList, facilitatorList } from '../services/scheduleList';

export const filter = (location, facilitator, start, end, classselect) =>
{
    //var current_schedule = JSON.parse(JSON.stringify(sch));
    //current_schedule = sch;
    var eid_list = {};
    let current_schedule =  JSON.parse(JSON.stringify(scheduleList));
    
    for(var i = 0; i < current_schedule.length; i++)
    {
//        console.log("Checking ----> ", current_schedule);
    //        for(var j = current_schedule[i].events.length - 1; j >= 0; j--)
        for(var j = 0; j < current_schedule[i].events.length; j++)
        {           
            var ev = moment(current_schedule[i].events[j].date + ' ' + current_schedule[i].events[j].time, "YYYY-MM-DD HH:mm:ss").subtract(window?.rv_sched_data?.prefences?.checkout_timeout||0, 'minutes');
  //          console.log("Comparing dates", ev, start, end);


            if(ev.isBefore(start))
            {
                current_schedule[i].events.splice(j, 1);
                j--;
                continue;
            }
            if(ev.isAfter(end))
            {
                current_schedule[i].events.splice(j, 1);
                j--;
                continue;
            }
            if(classselect !== '-1')
            {
                if( btoa(encodeURIComponent(current_schedule[i].events[j].name)) !== classselect)
                {
                    current_schedule[i].events.splice(j, 1);
                    j--;
                    continue;
                }
            }
        }
        if(current_schedule[i].events.length === 0)
        {
            current_schedule.splice(i, 1);
            i--
        }        
    }
    
    
    for( i = 0; i < current_schedule.length; i++)
    {
        ////console.log("Checking ----> ", current_schedule);
    //        for(var j = current_schedule[i].events.length - 1; j >= 0; j--)
        for( j = 0; j < current_schedule[i].events.length; j++)
        {           
    
            if(current_schedule[i].events[j].is_block === "1" && (!window.rv_sched_settings.show_future_block_date))
            { 
                if(eid_list.hasOwnProperty(current_schedule[i].events[j].eid)) {
                    if(eid_list[current_schedule[i].events[j].eid] === true)
                    {
                        current_schedule[i].events.splice(j, 1);
                        j--;
                    }
                }
                else
                {
                    eid_list[current_schedule[i].events[j].eid] = true;    
                }
    
    
            }
    
        }
        if(current_schedule[i].events.length === 0)
        {
            current_schedule.splice(i, 1);
            i--
        }        
    }
    
    
    
    if(location != '-1')
    {
        ////console.log("Location set", location);
        for(i = current_schedule.length - 1; i >= 0; i--)
        {
            for(j = current_schedule[i].events.length - 1; j >= 0; j-- )
            {
                if(current_schedule[i].events[j].locationid !== location)
                {
                    current_schedule[i].events.splice(j, 1);
                }
    
            }
            if(current_schedule[i].events.length === 0)
            {
                current_schedule.splice(i, 1);
            }
    
        // //console.log('is', i);
        }
    }
    ////console.log('filter A2: ', current_schedule);

    if(facilitator != '-1')
    {
        for(i = current_schedule.length - 1; i >= 0; i--)
        {
            for(j = current_schedule[i].events.length - 1; j >= 0; j-- )
            {
                var f = false;
                for(var k = 0; k < current_schedule[i].events[j].facilitators.length; k++)
                {
                    if(current_schedule[i].events[j].facilitators[k].id === facilitator)
                    {
                        f = true; 
    
                    }
                }
                if(f === false)
                {
                    current_schedule[i].events.splice(j, 1);
                }
            }
            if(current_schedule[i].events.length === 0)
            {
                current_schedule.splice(i, 1);
            }
        }
    } 


     
    return current_schedule;
}

function sessionSet( what, s )
{
    try{
        if (typeof sessionStorage !== "undefined")
            sessionStorage.setItem(what, s);
    }
    catch(e)
    {
        /* do nothing */
    }
    return
}

export const ScheduleReducer = (state, action) => {


    


    switch (action.type) {



        case "CHANGE_DATE":
        {
            state.start = action.payload.startDate;
            state.end = action.payload.endDate;

            let s = moment(state.start).set({"hour": 0, "minute": 0});
            let e = moment(state.end).set({"hour": 23, "minute": 59});
            if(s.isSame(moment(), 'day'))
            {
                s = moment();
            }
            sessionSet('rv-sdate', JSON.stringify(s));
            sessionSet('rv-edate', JSON.stringify(e));

            state.schedule = filter( state.location, state.facilitator, s, e, state.classselect);
            return {
                ...state
            }            
        }
        break;


        case "CHANGE_FACILITATOR":
        {
            state.facilitator = action.payload;
            state.schedule = filter( state.location, state.facilitator, state.start, state.end, state.classselect);
            return {
                ...state
            }   
        }

        case "CHANGE_LOCATION":
        {
            state.location = action.payload;
            state.schedule = filter( state.location, state.facilitator, state.start, state.end, state.classselect);
            return {
                ...state
            }   
        }



        case 'CHANGE_CLASS':
        {
            state.classselect = action.payload;
            state.schedule = filter( state.location, state.facilitator, state.start, state.end, state.classselect);
            return {
                ...state
            }             
        }


        

        default:
            return state

    }
}