import React, { useContext } from 'react';
import Layout from '../../../components/Layout';
import styles from './Checkout.module.scss';
import { CartControllerContext } from '../../../contexts/CartControllerContext';
import { ImSpinner2 } from 'react-icons/im';
import { configuration } from '../../../services/scheduleList';
import { CookieService } from '../../../services/cookie.service';
import { JwtContext } from '../../../contexts/JwtContext';


const getNavigatorLanguage = () => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
}

const geoFindMe = () => {
    const country = getNavigatorLanguage().split('-');
    if(country.length > 1)
    {
        if(!!country[1])
        {
          console.log(country[1].toUpperCase());
          return country[1].toUpperCase();
        }
        else
        {
          // defaulting
          console.error('Failed to get country code', country);
        }
    }
    return 'GB';
}

const Checkout = ( {onFlush} ) => {
    const myRef = React.useRef(null)
    const { wallets } = useContext(CartControllerContext);
    const [ iframeReady, setIframeReady ] = React.useState( configuration.isServer? true : false);
    const [ frameHeight, setFrameHeight ] = React.useState(20);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const { initializeCart } = useContext(CartControllerContext);
    const {store, jwt} = useContext(JwtContext);

    const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed




  

    React.useEffect(() => {
        const childFrameObj = document.getElementById('rvCartCheckout');
        if(configuration.isServer === false )
        {
                // we are in client mode         
            const onListen = event => {
                try{
                    const data = JSON.parse(event.data);
                    console.log('received', event);
                    switch(data.message)
                    {
                        case 'rvLoaded':
                            console.log("Loaded?", data);
                            setFrameHeight(data.height);
                            childFrameObj.contentWindow.postMessage(JSON.stringify({'message': 'rvInitialize', 'data': wallets, 'country': geoFindMe(), 'token': CookieService.getCookie('rvli') || CookieService.getCookie('trvli') || '', 'jwt': jwt}), '*');

                        break;
                        case 'rvReady':
                            console.log("Loaded?", data);
                            setFrameHeight(data.height);
                            setIframeReady(true);
                        break;
                        case 'rvBusy':
                            console.log("rvBusy?", data);
                            setShowSpinner(true);
                        break;
    
                        case 'rvIdle':
                            console.log("rvIdle?", data);
                            setShowSpinner(false);
                        break;  
                        case 'rvUpdate':
                            // server has asked client to update
                            console.log("received rvUpdate", x);
                            initializeCart( JSON.parse(event.data).data);
                        break;

                        case 'rvRender':
                            setFrameHeight(data.height);

                            
                            if ( window.location !== window.parent.location ) {
                                // The page is in an iframe
                                window.parent.postMessage('rvModal', '*');
                            }
                        break;
                     
                        case 'rvRepostition':
                            setFrameHeight(data.height);

                            switch(data?.position)
                            {
                                case 'middle':
                                case 'responsive':

                                    // to do - split up to send up responsive directive
                                    // middle should always scroll to middle

                                    if(isMobile)
                                    {
                                        // this is currently only being used for 3d secure. The modal behaves differently on a mobile...
                                        myRef.current.scrollIntoView();
                                    }
                                    else
                                    {
                                        /// OK descktop - scroll to the middle
                                        var iframeBoundingClientRect = childFrameObj.getBoundingClientRect();
                                        var iframeContentMidpoint = iframeBoundingClientRect.top + (childFrameObj.offsetHeight / 2);
                                        
                                        // Calculate the scroll position to bring us to the midpoint of the iframe
                                        var scrollPosition = iframeContentMidpoint - (window.innerHeight / 2) + window.pageYOffset;
                                        
                                        // Scroll to the calculated position
                                        window.scrollTo(0, scrollPosition);
    
                                    }
                    
                                break;

                                default:
                                case 'top':
                                    myRef.current.scrollIntoView();
                                break;
                            }
                            
                            

                            if ( window.location !== window.parent.location ) {
                                // The page is in an iframe
                                window.parent.postMessage('rvModal', '*');
                            }
                        break;

                        case 'rvFlush':
                            // server has asked client to flush 
                            console.log("received rvFlush", x);
                            initializeCart( { card: [], pass: [], free:[], subscription:[]} );
                            onFlush();
                        break;  
                        
                        
                        case 'rvLoggedIn':
                            setFrameHeight(data.height);
                            store(data.data.at, false);
                            childFrameObj.contentWindow.postMessage(JSON.stringify({'message': 'rvInitialize', 'data': wallets, 'country': geoFindMe(),  'token': CookieService.getCookie('rvli') || CookieService.getCookie('trvli') || '', 'jwt': data.data.at}), '*');
                        break;
        
    
                        default:
                            console.log('Unexpected proxy message', data.message);
                        
    
                    }
                }
                catch(e)
                {
                    
                }

            }

            window.addEventListener("message", onListen)
        
            // clean up
            return () => {
                window.removeEventListener("message", onListen) 
            }
        }
        else
        {
                // we are in server mode - so if a client speaks to us - respond. 
                var x = {message: 'rvLoaded', data:[], height: document.getElementById('rv_root').clientHeight };
                let url =
                window.location !== window.parent.location
                  ? document.referrer
                  : document.location.href;
                 window.parent.postMessage(JSON.stringify(x), url);
            
                 const onParentCommand = (event) => {
                    const data = JSON.parse(event.data)
                    console.log("Child message", data)
                    switch(data.message)
                    {
                        case 'rvInitialize':
                            // send to the server and then respond with ready or error 
                            x = {message: 'rvReady', data:[], height: document.getElementById('rv_root').clientHeight };
                            console.log("Posting rvInitialize", x);
                            window.parent.postMessage(JSON.stringify(x), url);
                        break;

                        
                        case 'rvUpdate':
                            // server has asked client to update
                            console.log("receiveed rvUpdate", x);
                            initializeCart( event.data.data);
                        break;
                        case 'rvFlush':
                            // server has asked client to flush 
                            console.log("receiveed rvFlush", x);
                            initializeCart( { card: [], pass: [], free:[], subscription:[]} );
                        break;

                        default:
                            console.log("Unexpected parent command", data.message);


                    }
                 };
                 window.addEventListener("message", onParentCommand);
              // clean up
                return () => {
                    window.removeEventListener("message", onParentCommand) 
                }            
            
        }


    }, []);


    const url = (window.rv_server_override? window.rv_server_override + '/' + window.rv_sched_data?.id +'/#/proxy' : window.rv_reservie_address + '/' + 'cart/server/' + window.rv_sched_data?.id + '#/proxy').replace(/([^:]\/)\/+/g, "$1");
    
    return ( 
        <Layout title="Cart" description="Cart wizard" >
            <div className="text-center mt-5">
                {
                    <iframe src={url} ref={myRef} title="rv proxy frame" id="rvCartCheckout" scrolling="no" className={styles.p__rvFrame} style={{'visibility': iframeReady?'visible':'hidden', height: frameHeight + 'px', 'minHeight': (window.innerHeight - 20) + 'px' }} /> 
                }
                {
                    (iframeReady === false || showSpinner === true) && <div className={styles.p__wait}>
                        <ImSpinner2 className="spinner"/>
                    </div>
                }
            </div>
        </Layout>
     );
}
 
export default Checkout;