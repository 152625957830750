import React, { createContext, useState } from 'react';
import { settings } from '../services/scheduleList';
export const PreferenceContext = createContext()

const PreferenceContextProvider = ({children}) => {

    const [preference] = useState(settings);

    return ( 
        <PreferenceContext.Provider value={{preference}} >
            { children }
        </PreferenceContext.Provider>
     );
}
 
export default PreferenceContextProvider;