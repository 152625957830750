import React, { createContext, useState, useReducer } from 'react';
import { classList, scheduleFlatList, facilitatorList, settings, locationList } from '../services/scheduleList';
import moment from 'moment';
import { ScheduleReducer, filter } from './ScheduleReducer';
export const ScheduleContext = createContext()

export const isPassPaymentAllowed = product => {
    var check =  product.payment_type === "1" || product.payment_type === "3"
    return check;
}
export const isCardPaymentAllowed = product => {
    var check =  product.payment_type === "2" || product.payment_type === "3"
    return check;
}

export const isBlockBookingOnly = product => {

    switch(product.booking_mode)
    {
        case 'BOOKING_MODE_TYPE_BLOCK':
            return true;
        default:
            return false;
    }
}

export const isFlexibleBooking = product => {

    switch(product.booking_mode)
    {
        case 'BOOKING_MODE_TYPE_FLEXIBLE':
            return true;
        default:
            return false;
    }
}


export const hasWaitingList = product => {
    switch(product.wait)
    {
        case '1':
            return true;
        default:
            return false;
    }
}


export const soldFromSet = ( key_1, key_3 ) => {
    let sold = 0;
    let tickets = scheduleFlatList.filter( element => { 
        //console.log("comparing ", key_1, key_2, element.eid, element.did);
        return element.eid === key_1;
    }).map( element => {
        return element.filtered_object;
    }).flat().filter( po => {
        return  po.id === key_3.id;
    });;
    
    if(tickets.length > 0)
    {

        sold = tickets.reduce( (sold, po, i) => {
            if(i === 0)
            {
                sold = parseInt(po.sold);
                return sold;
            }
            else
            {
                let x = parseInt(po.sold);

                if(x > sold)
                {
                    return x;
                }
                else
                {
                    return sold;
                }
            }
        }, 0);
    } 

    return sold;    
}


export const stockFromSet = ( key_1, key_3 ) => {
    let stock = 0;
    let tickets = scheduleFlatList.filter( element => { 
        //console.log("comparing ", key_1, key_2, element.eid, element.did);
        return element.eid === key_1;
    }).map( element => {
        return element.filtered_object;
    }).flat().filter( po => {
        return  po.id === key_3.id;
    });;
    
    if(tickets.length > 0)
    {

        stock = tickets.reduce( (stock, po, i) => {
            if(i === 0)
            {
                stock = parseInt(po.stock);
                return stock;
            }
            else
            {
                let x = parseInt(po.stock);

                if(x < stock)
                {
                    return x;
                }
                else
                {
                    return stock;
                }
            }
        }, 0);
    } 

    return stock;    
}

export const isFullyBooked = product => {


    /*
    let avail = 0;
    avail = product.filtered_object.reduce((avail, po, i) => {
        switch(product.is_block)
        {
            case "0": // individual
            case "3": // flexible - in this scenario, we want flexible to act as individual showing only their date if available....
                return avail + (po.stock - po.sold);
            
            case "1": // block
                return  avail + maxAvailableFromSet(product.eid, po);
            
            default:
                return 0;
              
        }        
    }, 0);
    

    */


    let sold = 0;
    sold = product.filtered_object.reduce((sold, po, i) => {
        switch(product.is_block)
        {
            case "0": // individual
            case "3": // flexible - in this scenario, we want flexible to act as individual showing only their date if available....
                return sold +  po.sold;
            
            case "1": // block
                return  sold + soldFromSet(product.eid, po);
            
            default:
                return 0;
              
        }        
    }, 0);



    let stock = 0;
    stock = product.filtered_object.reduce((stock, po, i) => {
        switch(product.is_block)
        {
            case "0": // individual
            case "3": // flexible - in this scenario, we want flexible to act as individual showing only their date if available....
            {
                let s = stock +  po.stock;
                if(po?.max >= 0)
                {
                    if(s > po.max)
                    return po.max;
                }
                return s;
            }

            
            case "1": // block
                let s = stock + stockFromSet(product.eid, po);

                if(po?.max >= 0)
                {
                    if(s >= po.max)
                    {
                        return po.max;
                    }
                }
                return s;
            
            default:
                return 0;
              
        }        
    }, 0);


    /*
    if(avail > 0)
    {
        return false;
    }
    */


    let avail = stock - sold;
    if(avail > 0)
    {
        return false;
    }        
    return true;

}

export const calcAvailability = product => {


    /*
    let avail = 0;
    avail = product.filtered_object.reduce((avail, po, i) => {
        switch(product.is_block)
        {
            case "0": // individual
            case "3": // flexible - in this scenario, we want flexible to act as individual showing only their date if available....
                return avail + (po.stock - po.sold);
            
            case "1": // block
                return  avail + maxAvailableFromSet(product.eid, po);
            
            default:
                return 0;
              
        }        
    }, 0);
    

    */


    let sold = 0;
    sold = product.filtered_object.reduce((sold, po, i) => {
        switch(product.is_block)
        {
            case "0": // individual
            case "3": // flexible - in this scenario, we want flexible to act as individual showing only their date if available....
                return sold +  po.sold;
            
            case "1": // block
                return  sold + soldFromSet(product.eid, po);
            
            default:
                return 0;
              
        }        
    }, 0);



    let stock = 0;
    stock = product.filtered_object.reduce((stock, po, i) => {
        switch(product.is_block)
        {
            case "0": // individual
            case "3": // flexible - in this scenario, we want flexible to act as individual showing only their date if available....
            {
                let s = stock +  po.stock;
                if(po?.max >= 0)
                {
                    if(s > po.max)
                    return po.max;
                }
                return s;
            }

            
            case "1": // block
                let s = stock + stockFromSet(product.eid, po);

                if(po?.max >= 0)
                {
                    if(s >= po.max)
                    {
                        return po.max;
                    }
                }
                return s;
            
            default:
                return 0;
              
        }        
    }, 0);


    /*
    if(avail > 0)
    {
        return false;
    }
    */


    let avail = stock - sold;
    return avail;

}


export const paymentOptions = (product) => {

    let x = [];
    let options = product.object.configuration ? product.object.configuration.options : product.object;
    
    if(options.length > 0)
    {
        x = options.filter(o => !o?.deleted || o?.deleted === false);
    }
    return x;
}



export const isPassOnly = ( payment_type ) => {
    var x = parseInt(payment_type) === 0x01 ? true : false;
    return x;
}

export const isCardOnly = ( payment_type ) => {
    var x = parseInt(payment_type) === 0x02 ? true : false;
    return x;
}

export const isCardandPass = ( payment_type ) => {
    var x = parseInt(payment_type) === 0x03 ? true : false;
    return x;
}



export const isFree = ( payment_type ) => {
    var x = parseInt(payment_type) === 0x04 ? true : false;
    return x;
}


function sessionGet( what )
{
    try{

        if (typeof sessionStorage !== "undefined")
        {
            return JSON.parse(sessionStorage.getItem(what));
        }
        else
        {
            return  false;
        }
    }
    catch(e)
    {
        return  false;
    }
}

const rv_sdate = sessionGet('rv-sdate') || moment();
const rv_edate = sessionGet('rv-edate') || moment().add(settings.showweeks * 7, 'days');

//console.log('dates', rv_sdate, rv_edate);

const initialState = { 
    start: moment(rv_sdate),
    end: moment(rv_edate), 
    facilitators: facilitatorList,
    locations: locationList,
    classes: classList,
    facilitator: '-1',
    location: '-1',
    classselect: '-1',
    schedule: filter('-1', '-1', moment(rv_sdate), moment(rv_edate), '-1' )
};

const ScheduleContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(ScheduleReducer, initialState);


    const changeDate = payload => {
        dispatch( {type: 'CHANGE_DATE', payload} );
    }

    const changeFacilitator = ( payload ) => {
        dispatch( {type: 'CHANGE_FACILITATOR', payload} );

    }

    const changeLocation = ( payload ) => {
        dispatch( {type: 'CHANGE_LOCATION', payload} );

    }

    const changeClass = ( payload ) => {
        dispatch( {type: 'CHANGE_CLASS', payload} );

    }

    const findEvent = ( key_1, key_2) => {
        const search = scheduleFlatList.find( element => { 
            //console.log("comparing ", key_1, key_2, element.eid, element.did);
            return element.eid === key_1 && element.did === key_2;
        });
        return search;
    }

    const getAvailabilityForEventTicket = ( key_1, key_2, key_3 ) => {
        let avail = 0;
        let tickets = scheduleFlatList.filter( element => { 
            //console.log("comparing ", key_1, key_2, element.eid, element.did);
            return element.eid === key_1 && element.did === key_2;
        }).map( element => {
            return element.filtered_object;
        }).flat().filter( po => {
            return  po.id === key_3.id;
        });

        if(tickets.length > 0)
        {
            avail = tickets.reduce( (avail, po, i) => {
                return avail + (parseInt(po.stock) - parseInt(po.sold));
            }, 0);
        }   
        
        return avail;

    }

    const getAvailabilityForEventTicketLimited = ( key_1, key_2, key_3, limit ) => {
        let avail = 0;
        let tickets = scheduleFlatList.filter( element => { 
            //console.log("comparing ", key_1, key_2, element.eid, element.did);
            return element.eid === key_1 && element.did === key_2;
        }).map( element => {
            return element.filtered_object;
        }).flat().filter( po => {
            return  po.id === key_3.id;
        });

        if(tickets.length > 0)
        {
            let stock =  tickets.reduce( (stock, po, i) => {
                return stock + parseInt(po.stock);
            }, 0);
            let sold =  tickets.reduce( (sold, po, i) => {
                return sold + parseInt(po.sold);
            }, 0);

            if((limit > 0) && (stock > limit))
            {
                stock = limit;
            }

            avail = stock - sold;
        }   
        
        return avail;

    }

    const getAvailabilityForEvent = ( key_1, key_2, limit ) => {
        let avail = 0;
        let tickets = scheduleFlatList.filter( element => { 
            //console.log("comparing ", key_1, key_2, element.eid, element.did);
            return element.eid === key_1 && element.did === key_2;
        }).map( element => {
            return element.filtered_object;
        }).flat();

        if(tickets.length > 0)
        {
            let stock =  tickets.reduce( (stock, po, i) => {
                return stock + parseInt(po.stock);
            }, 0);
            let sold =  tickets.reduce( (sold, po, i) => {
                return sold + parseInt(po.sold);
            }, 0);

            if((limit > 0) && (stock > limit))
            {
                stock = limit;
            }

            avail = stock - sold;
        }   
        
        return avail;

    }

    const getMaxAvailableFromSet = ( key_1, key_3) => {


        let avail = 0;
        let tickets = scheduleFlatList.filter( element => { 
            //console.log("comparing ", key_1, key_2, element.eid, element.did);
            return element.eid === key_1;
        }).map( element => {
            return element.filtered_object;
        }).flat().filter( po => {
            return  po.id === key_3.id;
        });;
        
        if(tickets.length > 0)
        {
    
            avail = tickets.reduce( (a, po, i) => {
                if(i === 0)
                {
                    avail = parseInt(po.stock) - parseInt(po.sold);
                    return avail;
                }
                else
                {
                    let x = parseInt(po.stock) - parseInt(po.sold);
                    const max = parseInt(po.max);
    
                    if(x < a)
                    {
                        if(max > 0)
                        {
                            if(x < max)
                            {
                                console.log('Standard date detected', x, a, max);
                                return x;
                                    
                            }
                            else
                            {
                                console.log('Standard date max ceiling detected', x, a, max);
                                return max;
                            }                            
                        }
                        else
                        {
                            console.log('Standard date detected', x, a, max);
                            return x;
                        }
                    
                    }
                    else
                    {
                        if(max > 0)
                        {
                            if(a < max)
                            {
                                console.log('Reduced date detected', x, a, max);
                                return a;
                            }
                            else
                            {
                                console.log('Reduced date detected max ceiling', x, a, max);
                                return max;
                            }
                        }
                        else
                        {
                            console.log('Standard date detected', x, a, max);
                            return a;                            
                        }
                    }

                }
            }, 0);
        } 
        console.log("Final availability", avail);
        return avail;

    }


    const getMaxSoldFromSet = ( key_1, key_3) => {


        let avail = 0;
        let tickets = scheduleFlatList.filter( element => { 
            //console.log("comparing ", key_1, key_2, element.eid, element.did);
            return element.eid === key_1;
        }).map( element => {
            return element.filtered_object;
        }).flat().filter( po => {
            return  po.id === key_3.id;
        });;
        
        if(tickets.length > 0)
        {
    
            avail = tickets.reduce( (a, po, i) => {
                if(i === 0)
                {
                    a =  parseInt(po.sold);
                    return a;
                }
                else
                {
                    let x = parseInt(po.sold);
    
                    if(x < a)
                    {
                        return a;
                    
                    }
                    else
                    {
                       return x;
                    }

                }
            }, 0);
        } 
        console.log("Final availability", avail);
        return avail;

    }


    

    const contextValues = {
        changeDate,
        changeFacilitator,
        changeLocation,
        changeClass,
        findEvent,
        getMaxAvailableFromSet,
        getMaxSoldFromSet,
        getAvailabilityForEventTicket,
        getAvailabilityForEventTicketLimited,
        getAvailabilityForEvent,
        ...state

    }  


    return ( 
        <ScheduleContext.Provider value={contextValues} >
            { children }
        </ScheduleContext.Provider>
     );
}
 
export default ScheduleContextProvider;