

export const JwtReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_JWT":
        {
            //console.log("storing jwt", action.payload);
            state.jwt = action.payload;
            return {
                ...state,
            }                 
        }


        default:
            return state

    }
}