import React, { useContext } from 'react';

import { CartControllerContext, OBJECT_TYPE_EVENT_BLOCK, OBJECT_TYPE_EVENT_FLEXIBLE, OBJECT_TYPE_EVENT_INDIVIDUAL, OBJECT_TYPE_VIDEO } from '../../contexts/CartControllerContext';
import CartItem from './CartItem';
import PassItem from './PassItem';
import styles from './CartProducts.module.scss';
import { PreferenceContext } from '../../contexts/PreferenceContext';
import { formatNumber } from '../../helpers/utils';
import { useHistory } from 'react-router-dom';
import SubscriptionItem from './SubscriptionItem';
import VideoItem from './VideoItem';


const CartProducts = () => {

    const { wallets, cardTotal, passTotal, subscriptionTotal, getCardTransactionValue } = useContext(CartControllerContext);
    const { preference } = useContext(PreferenceContext);
    const history = useHistory();

    const onCheckout = () => {
        history.push('/checkout');        
    }

    const onContinue = ()  => {
        history.push('/');        
    }

    //console.log('card transactions', cardTotal, " pass ", passTotal);    
    return ( 
        <React.Fragment>
            <div className={styles.p__container}>
                <div className="card card-body border-0">
                    <div className="row border-bottom">
                        <div className={"col-12 col-md-9 " + styles.p__colTitle}>
                            Item
                        </div>
                        <div className={"d-none d-sm-block d-md-block d-lg-block d-xl-block col-md-3 text-center " + styles.p__colTitle}>
                            Total
                        </div>
                    </div>
                    {
                        wallets.subscription.map( (item, index) => (
                            <div className="row border-bottom pt-3"   key={'wp-' + item.id}>
                                <div className="col-12 col-md-9">
                                    <div className="container-fluid no-gutters">
                                        <SubscriptionItem product={item} />
                                    </div>
                                </div>
                                <div className="d-none d-sm-block d-md-block d-lg-block d-xl-block  col-md-3 text-center">
                                    <div className="p-3">
                                        <span>{formatNumber((parseFloat(item.price)).toFixed(2), preference.currency, index)}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    } 

                    {
                        wallets.card.filter(transaction => transaction.objects.length > 0 ).map( (transaction, index) => ( 
                            <div className="row border-bottom pt-3" key={'wc-' + transaction.id + '-' + index}>
                                <div className="col-12 col-md-9">
                                    {
                                       (transaction.type === OBJECT_TYPE_EVENT_INDIVIDUAL ||  transaction.type === OBJECT_TYPE_EVENT_FLEXIBLE || transaction.type === OBJECT_TYPE_EVENT_BLOCK) && 
                                        <div className="container-fluid no-gutters">
                                            <CartItem product={transaction} index={index} />
                                        </div>
                                    }
                                    {
                                       (transaction.type === OBJECT_TYPE_VIDEO ) && 
                                        <div className="container-fluid no-gutters">
                                            <VideoItem product={transaction} index={index} />
                                        </div>
                                    }

                                </div>
                                <div className="d-none d-sm-block d-md-block d-lg-block d-xl-block  col-md-3 text-center"><div className="p-3">{formatNumber(getCardTransactionValue(transaction.id, index).toFixed(2), preference.currency)}</div></div>
                            </div>
                        ))
                    }

                    {
                        wallets.pass.filter(item => ((item.transactions.length > 0 && item.mode === 'debit') || item.mode === 'credit')).map( item => (
                            <div className="row border-bottom pt-3"   key={'wp-' + item.id}>
                                <div className="col-12 col-md-9">

                                    
                                        <div className="container-fluid no-gutters">
                                            <PassItem product={item} />
                                        </div>
                                           
                                </div>
                                <div className="d-none d-sm-block d-md-block d-lg-block d-xl-block col-md-3 text-center">
                                    <div className="p-3">
                                    {
                                        item.mode === 'debit' && <div className={styles.p__payInfo}>Already purchased<div><small>(no additional charge)</small></div></div>
                                    }
                                    {
                                        item.mode !== 'debit' && <span>{formatNumber((parseFloat(item.price)).toFixed(2), preference.currency)}</span>
                                    }
                                    </div>
                                </div>
                            </div>
                        ))
                    } 

                    <div className="row border-bottom" >
                        <div className={"col-12 col-md-9 text-right " + styles.p__colTitle}>
                            <div className="pt-2 pb-2"><b>Total</b></div>
                        </div>
                        <div className="d-none d-sm-block d-md-block d-lg-block d-xl-block  col-md-3 text-center">
                            <div className="pt-2 pb-2">{formatNumber(cardTotal + passTotal + subscriptionTotal, preference.currency)}</div>
                        </div>
                    </div>
                    
                    <div className="row border-bottom" >
                        <div className="col-12 pt-4 pb-4 text-right">
                            <div className={styles.p__rVieModalFooterText + " d-none d-sm-block d-md-block d-lg-block d-xl-block "}>What would you like to do?</div>
                            <button type="button" className="btn btn-secondary d-none d-sm-block d-md-block d-lg-block d-xl-block " data-dismiss="modal" onClick={onContinue}>Continue shopping</button>

                            <button type="button" className="btn btn-primary" onClick={onCheckout}>Checkout</button>

                        </div>
                    </div>
                                        
                </div>
            </div>
        </React.Fragment>


     );
}
 
export default CartProducts;