import React, { useContext } from 'react';
import { TrashIcon } from '../../components/icons'
import { CartControllerContext } from '../../contexts/CartControllerContext';
import Moment from 'react-moment';
import ErrorPrompt from './ErrorPrompt';
import styles from './CartProducts.module.scss';

const PassItem = ({product}) => {

    const { detachPass, emptyPass, decrementPassEventQty, fetchPassAvailability } = useContext(CartControllerContext);
    const [ error, setError] = React.useState(false)



    const onDecrementQty = ( obj ) => {
        const x = {
            wallet_id: product.id,
            id: obj.id
        };


        decrementPassEventQty( x );        
    }

    console.log(product);
    return (
        <React.Fragment>
        <div className="row">

            <div className="col-10 col-md-6 pt-2 pb-2">
                <h5 className={"mb-1 " + styles.p__cartItemName}>
                    Pass: {product.name} {!isNaN(product.id) && `(ID: ${parseInt(product.id).toString(16)})`}
                </h5>
                {
                    // new payment
                    product.mode === 'credit' &&
                    product.start_mode !== 1 &&
                    <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits remaining after transaction:  {fetchPassAvailability(product.id).toFixed(0)}</p>
                }
                {
                    // new payment
                    product.mode === 'credit' &&
                    product.start_mode === 1 && product.period === 0 &&
                    product.expires === null &&
                    <p className={"mb-1 " + styles.p__payInfo}>Pass starts: On date/time of first booking. Pass Expires:  {product.expiry_range} day(s) after date of first booking. Credits remaining after transaction after booking:  {fetchPassAvailability(product.id).toFixed(0)}</p>
                }
                {
                    // new payment
                    product.mode === 'credit' &&
                    product.start_mode === 1 && product.period === 1 &&
                    product.expires === null &&
                    <p className={"mb-1 " + styles.p__payInfo}>Pass starts: On date/time of first booking. Pass Expires: {product.expiry_range} month(s) after date of first booking. Credits remaining after transaction:  {fetchPassAvailability(product.id).toFixed(0)}</p>
                }   
                {
                    // new payment
                    product.mode === 'credit' &&
                    product.start_mode === 1 && product.period === 0 &&
                    product.expires !== null &&
                    <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits remaining after transaction:  {fetchPassAvailability(product.id).toFixed(0)}</p>
                }
                {
                    // new payment
                    product.mode === 'credit' &&
                    product.start_mode === 1 && product.period === 1 &&
                    product.expires !== null &&
                    <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits remaining after transaction:  {fetchPassAvailability(product.id).toFixed(0)}</p>
                }  





                {
                    // exisiting payment
                    product.mode === 'debit' &&
                    product.start_mode !== 1 &&
                    <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits remaining after transaction:  {fetchPassAvailability(product.id).toFixed(0)}</p>
                }
                {
                    // existing payment - expiry set
                    product.mode === 'debit' &&
                    product.start_mode === 1 && 
                    product.period === 0 &&
                    product.expires !== null &&
                    <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits remaining after transaction:  {fetchPassAvailability(product.id).toFixed(0)}</p>
                }
                {
                    // existing payment - expiry set
                    product.mode === 'debit' &&
                    product.start_mode === 1 && 
                    product.period === 1 &&
                    product.expires !== null &&
                    <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits remaining after transaction:  {fetchPassAvailability(product.id).toFixed(0)}</p>
                }  
               {
                    // existing payment - expiry not set
                    product.mode === 'debit' &&
                    product.start_mode === 1 && 
                    product.period === 0 &&
                    product.expires === null &&
                    <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires:  {product.expiry_range} day(s) after date of first booking. Credits remaining after transaction after booking:  {fetchPassAvailability(product.id).toFixed(0)}</p>
                }
                {
                    // existing payment - expiry not set
                    product.mode === 'debit' &&
                    product.start_mode === 1 && 
                    product.period === 1 &&
                    product.expires === null &&
                    <p className={"mb-1 " + styles.p__payInfo}>Pass starts: {<Moment format="LLL">{product.starts}</Moment>}. Pass Expires:  {product.expiry_range} month(s) after date of first booking. Credits remaining after transaction after booking:  {fetchPassAvailability(product.id).toFixed(0)}</p>
                }  
                
                             
            </div>
            <div className="col-2 col-md-6 p-0 text-right ">
            {
                    product.mode === 'credit' &&
                     <button
                    onClick={() => detachPass(product)}
                    className={"btn  rvIconButton rvDangerIconButton  " + styles.p__dangerSmallButton}>
                        <TrashIcon width={"20px"}/> <div>remove</div>
                    </button>
                                 
            }  
            {
                    product.mode === 'debit' &&
                     <button
                    onClick={() => emptyPass(product)}
                    className={"btn  rvIconButton rvDangerIconButton  " + styles.p__dangerSmallButton}>
                        <TrashIcon width={"20px"}/> <div>remove</div>
                    </button>
            }                 
            </div>
        </div> 
        <div className="row">
                <div className="col-12 p-0 pb-2">
                    <div className="container-fluid no-gutters">
                        {
                            product.transactions.filter(transaction => transaction.objects.length > 0).map( (transaction, index) => (

                                <div className="row no-gutters" key={'pe-' + index}>
                                    {
                                        transaction.type !== "OBJECT_TYPE_VIDEO" &&
                                        <div className="col-12 col-md-6 pt-1">                                    
                                            <h5 className={"mb-1 ml-4 " + styles.p__cartSubItemName}>{index+1}. {transaction.objects[0].name}</h5>
                                            {
                                                transaction.objects.map( event => (                                                
                                                <React.Fragment key={'tx-' + event.key_1 + '-' + event.key_2 + '-' + event.key_3.id}>

                                                    <p className="mb-1 ml-4 ">{<Moment format="LLL">{event.date + "T" + event.time}</Moment>}: Price: {event.key_3.credits} pass credit(s) per space </p>
                                                </React.Fragment>

                                                ))
                                            }
                                        </div>
                                    }
                                    {
                                        transaction.type === "OBJECT_TYPE_VIDEO" &&
                                        <div className="col-12 col-md-6 pt-1">                                    
                                            {
                                                transaction.objects.map( o => (                                                
                                                <React.Fragment key={'tx-' + o.key_1 + '-' + o.key_2 + '-' + o.key_3.id}>
                                                    <h5 className={"mb-1 ml-4 " + styles.p__cartSubItemName}>{index+1}. {o.object.name}</h5>
                                                    <p className="mb-1 ml-4 ">Price: {o.key_3.credits} pass credit(s) </p>
                                                </React.Fragment>

                                                ))
                                            }
                                        </div>
                                    }
   
                                    <div className="col-12 col-md-6 text-right">
                                        <button
                                        onClick={() => onDecrementQty(transaction)}
                                        className={"btn  rvIconButton rvDangerIconButton " + styles.p__dangerSmallButton}>
                                            <TrashIcon width={"20px"}/> <div>remove</div>
                                        </button>
                                    </div>                                  
                                </div>
                            ))
                        }

                    </div>                    
                </div>
        </div>  
        {
            <ErrorPrompt show={error} onClose={setError}/>
        }         
        </React.Fragment> 

     );
}
 
export default PassItem;