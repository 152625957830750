import React, {useContext} from 'react';
import Layout from '../../components/Layout';
import ProductsGrid from './ProductsGrid';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { withStyles } from '@material-ui/core/styles';

import { DateRangePicker } from 'react-dates';
import {BsArrowRight, BsArrowLeft} from "react-icons/bs"
import { ScheduleContext } from '../../contexts/ScheduleContext';

const styles = {
    root: {
        flexGrow: 1,
      },
      formControl: {
//        margin: theme.spacing(1),
        minWidth: 120,
        width:"100%",
        marginTop: "10px",
        marginBottom: "10px",
      },             
  };



const Schedule = () => {
    const [focusedInput, setFocusedInput] = React.useState(null);
    const { changeDate, start, end, facilitators, changeFacilitator, locations, changeLocation, classes, changeClass } = useContext(ScheduleContext);

    const onDateChange = ( { startDate, endDate } ) => {
        changeDate( {startDate: startDate, endDate: endDate} );
    }

    const onFocusChange = ( focusedInput ) => {
        setFocusedInput( focusedInput )

    }
    
    const onDateClose = ( event ) => {

    }

    const onFacilitatorSelect = ( event ) => {
        changeFacilitator( event.target.value );
    }

    const onLocationSelect = ( event ) => {
        changeLocation(event.target.value);
    }


    const onClassSelect = ( event ) => {
        changeClass( event.target.value );
    }

    const calcWidth = () =>{
        let width = 12;

        if(classes.length > 0 && locations.length > 0 && facilitators.length > 0)
        {
            width = 3;
        }
        else if(classes.length === 0 && locations.length > 0 && facilitators.length > 0)
        {
            width = 4;
        }
        else if(classes.length > 0 && locations.length === 0 && facilitators.length > 0)
        {
            width = 4;
        }
        else if(classes.length > 0 && locations.length > 0 && facilitators.length === 0)
        {
            width = 4;
        }
        else if(classes.length === 0 && locations.length === 0 && facilitators.length > 0)
        {
            width = 6;
        }
        else if(classes.length === 0 && locations.length > 0 && facilitators.length === 0)
        {
            width = 6;
        }
        else if(classes.length > 0 && locations.length === 0 && facilitators.length === 0)
        {
            width = 6;
        }  
        return width;      
    }


    let  wdth = calcWidth();

    const orientation = window.matchMedia("(max-width: 700px)").matches ? 'vertical' : 'horizontal'

    console.log(window.matchMedia("(max-width: 700px)").matches, orientation);
    
    return ( 
        <Layout title="Schedule" description="This is the Schedule page" >
            <div >
                <div className="container-fluid  mb-4 mt-4 rv-navbar">

                    <div className="row rv-filter-select">
                        {
                            classes.length > 0 &&
                            <div className={"pl-md-0 pl-lg-0 pl-xl-0 col-sm-12 col-md-" + wdth}>
                                <div className="form-group">
                                    <select className="form-control" id="classSelect" onChange={onClassSelect}>
                                        <option value="-1">{window.rv_sched_vocab.classselect}</option>
                                        {
                                            classes.length > 0 && classes.map( (cls, index) => (
                                                <option value={btoa(encodeURIComponent(cls))} key={'class-'+index}>{cls}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        }
                        {
                            locations.length > 0 &&
                            <div className={"col-sm-12 col-md-" + wdth}>
                                <div className="form-group">
                                    <select className="form-control" id="locationSelect" onChange={onLocationSelect}>
                                        <option value="-1">{window.rv_sched_vocab.locationselect}</option>
                                        {
                                            locations.length > 0 && locations.map( location => (
                                                <option value={location.id} key={'location-'+location.id}>{location.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>                            
                        }
                        {
                        facilitators.length > 0 &&    
                        <div className={"col-sm-12 col-md-" + wdth}>
                            <div className="form-group">
                                <select className="form-control" id="facilitatorSelect" onChange={ onFacilitatorSelect}>
                                    <option value="-1">{window.rv_sched_vocab.teacherselect}</option>
                                    {
                                        facilitators.length > 0 && facilitators.map( faciltator => (
                                            <option value={faciltator.id} key={"faciltator-" + faciltator.id}>{faciltator.firstname + ' ' + faciltator.lastname}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        }
                        <div className={"text-right pr-0 col-sm-12 col-md-" + wdth}>
                        <DateRangePicker
                            displayFormat="DD/MM/YYYY"
                            startDateId="start_date"
                            endDateId="end_date"
                            startDate={start}
                            endDate={end}
                            appendToBody={true}
                            withPortal={true}
                            noBorder={true}
                            orientation={orientation}
                            customArrowIcon={<BsArrowRight />}
                            onDatesChange= { onDateChange }
                            onClose ={onDateClose}
                            focusedInput={focusedInput}
                            onFocusChange={onFocusChange}
                            navPrev={
                                <div className="rvButtonLeft">
                                    <button type="button" className="btn btn-secondary btn-rv-date-nav"><BsArrowLeft /></button>
                                </div>
                            }
                            navNext={
                                <div className="rvButtonRight">
                                    <button type="button" className="btn btn-secondary btn-rv-date-nav"><BsArrowRight /></button>
                                </div>
                            }
                        />                            
                        </div>
                    </div>

                </div>
                <ProductsGrid/>
            </div>


        </Layout>
     );
}
 
export default withStyles(styles)(Schedule);