import React from 'react';
import styles from './CartProducts.module.scss';


const ErrorPrompt = ({show, onClose}) => {

  
    const handleClose = () => {
        onClose( false );
    }
  
    return (
        <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, "display": show?'block':'none', 'zIndex': 2}}>
            <div className="modal show" style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} >
                <div className={"modal-dialog modal-md " + styles.p__modal} role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Error</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                                You do not have enough credits available for this operation.                        
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
  

  export default ErrorPrompt;