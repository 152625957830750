

import { formatNumber } from '../../helpers/utils';


import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Video.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { CartControllerContext, OBJECT_TYPE_VIDEO } from '../../contexts/CartControllerContext';

import { PreferenceContext } from '../../contexts/PreferenceContext';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

}));

export default function VideoCard( props ) {
  const classes = useStyles();
  const {video} = props;
  const { pushEvent, isVideoInCart } = useContext(CartControllerContext);
  const d = new Date(video.updated);
  const { preference } = useContext(PreferenceContext);

  const onAddToCart = (o) => {

    var x = {
        type: OBJECT_TYPE_VIDEO,
        key_1: o.id,
        id: uuidv4(),
        object: o
    }
    pushEvent(x)           
  }



  return (
    <div className="card col-12 col-md-4 " key={video.id} >
    <div className="card-body  d-flex flex-column">
        <h5 className="card-title">{video.name}</h5>
        <Box style={{paddingTop:2, paddingBottom: 6, marginBottom: 10, maxHeight:200, overflowY: 'hidden'}}>
            <img src={video.preview} alt={video.name} style={{width: '100%'}} />
        </Box>
        <p className="card-text">
        {video.description}
        </p>
    </div>
    <div className="card-body" style={{marginTop:'200px'}}>
      <div className="card-foot">
          {
            video.pass === true && video.card === true && 
            <div className="card-text"><b>Price:</b>  {formatNumber (video.price, preference.currency)}  or {video.credits} credits</div>
          }   
          {
            !video.pass && video.card && 
            <div className="card-text"><b>Price:</b>  {formatNumber (video.price, preference.currency)} </div>
          }   
          {
            video.pass && !video.card && 
            <div className="card-text"><b>Price:</b> {video.credits} credits</div>
          }                                                  
          <div className="card-text"><b>Expires:</b>  {video.expire_state ? `${video.expires} days after purchase` : 'None' }</div>
          
          {
            !isVideoInCart(video.id) && 
            <div className={styles.p__outerButtonContainer + ' card-button'}>
              <button type="button" className={"btn rv-sched-booknow rvPrimaryColorBtn  align-self-end " + styles.p__rVieBtn} onClick={() => onAddToCart(video)}>Select</button>
            </div>
          }
          {
            isVideoInCart(video.id) && 
            <div className={styles.p__outerButtonContainer + ' card-button'}>
              <button type="button" className={"btn rv-sched-booknow rvPrimaryColorBtn  align-self-end " + styles.p__rVieBtn} disabled={true} onClick={() => onAddToCart(video)}>Selected</button>
            </div>
          }
      </div>

    </div>


</div> 
  );
}


