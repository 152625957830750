import React, { createContext, useReducer } from 'react';
import { CookieReducer } from './CookieReducer';

export const CookieContext = createContext();


const initialState = { 
                        allowed: true
                     };



const CookieContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(CookieReducer, initialState);

    const allow3rdparty = payload => {
        dispatch({type: 'ALLOW_3RD_PARTY', payload})
    }

    const disallow3rdparty = payload => {
        dispatch({type: 'DISALLOW_3RD_PARTY', payload})
    }


    const isAllowed = () => {
        return state.allowed;
    }


    const contextValues = {
        allow3rdparty,
        disallow3rdparty,
        isAllowed,
        ...state
    } 

    return ( 
        <CookieContext.Provider value={contextValues} >
            { children }
        </CookieContext.Provider>
     );
}
 
export default CookieContextProvider;
