import React, { useContext } from 'react';
import { TrashIcon } from '../../components/icons'
import { CartControllerContext } from '../../contexts/CartControllerContext';
import Moment from 'react-moment';
import ErrorPrompt from './ErrorPrompt';
import styles from './CartProducts.module.scss';

const VideoItem = ({product, index}) => {

    const { decrementCardEventQty } = useContext(CartControllerContext);
    const [ error, setError] = React.useState(false)



    const onDecrementQty = ( obj, i ) => {
        const x = {
            id: obj.id,
            instance: i,
        };
        decrementCardEventQty( x );        
    }

    console.log(product);
    return (
        <React.Fragment>
        <div className="row">

            <div className="col-10 col-md-6 pt-2 pb-2">
                <h5 className={"mb-1 " + styles.p__cartItemName}>Video: {product.objects[0].object.name}.</h5>
                <p className={"mb-1 " + styles.p__payInfo}>Price: {product.objects[0].object.price}</p>
            </div>
            <div className="col-2 col-md-6 p-0 text-right ">
            {
                     <button
                    onClick={() => onDecrementQty(product, index)}
                    className={"btn  rvIconButton rvDangerIconButton  " + styles.p__dangerSmallButton}>
                        <TrashIcon width={"20px"}/> <div>remove</div>
                    </button>
                 }                 
            </div>
        </div> 
        {
            <ErrorPrompt show={error} onClose={setError}/>
        }         
        </React.Fragment> 

     );
}
 
export default VideoItem;