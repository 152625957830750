import moment from 'moment';

let s = window.rv_sched_data ? window.rv_sched_data.data : [];
let schedule = [];
const now = moment().add( window?.rv_sched_data?.prefences?.checkout_timeout|| 0, 'minutes');

s.forEach( (daterow) => {
    let x = {};
    x['date'] = daterow.date;
    x['events'] = daterow.events.filter( event => {
      return moment(event.date + "T" + event.time).isAfter(now);
    });

    daterow.events.map( event => {
      event.associated = event.associated.filter( assoc => {
       // console.log(moment(assoc.datetime), assoc.datetime);
        return moment(assoc.datetime).isAfter(now);
      } )
    })

    if(x['events'].length > 0 )
    {
      schedule.push(x);
    }

});
let arr = [];


export const scheduleList = schedule;
export const facilitatorList = window.rv_sched_data && window.rv_sched_data.facilitators ? window.rv_sched_data.facilitators : [];
export const locationList = window.rv_sched_data && window.rv_sched_data.locations ? window.rv_sched_data.locations : [];
export const passList = window.rv_sched_data && window.rv_sched_data.passes ? window.rv_sched_data.passes.filter(pass => {return parseFloat(pass.price) >= 0 && parseInt(pass.subscriptionlimited) === 0} ) : [];
export const subscriptionList = window.rv_sched_data && window.rv_sched_data.subscriptions ? window.rv_sched_data.subscriptions : [];
export const scheduleFlatList = scheduleList.filter(o => o.events.length > 0 ).map( o => o.events.map( event =>  event ) ).flat();
export const videoList = window?.rv_sched_data?.videos || [];


for(var k = 0; k < scheduleList.length; k++)
{
    for(var j = 0;  j < scheduleList[k].events.length; j++)
    {
        arr.push(scheduleList[k].events[j].name);

    }
}

export const classList =  [...new Set(arr)];

const  event_book_multiples = window.rv_sched_data.settings?.meta?.hasOwnProperty('event_book_multiples') ? window.rv_sched_data.settings.meta.event_book_multiples : true;
const  event_book_others = window.rv_sched_data.settings?.meta?.hasOwnProperty('event_book_others') ? window.rv_sched_data.settings.meta.event_book_others : true;

export const settings = {
                            'currency': window.rv_sched_data? window.rv_sched_data.prefences.currency : '', 
                            'checkout_timeout': window.rv_sched_data?.prefences?.checkout_timeout || 0, 
                            'showweeks': window.rv_sched_data? window.rv_sched_data.settings.n:1, 
                            'clock': window.rv_sched_data && window.rv_sched_data?.settings?.meta?.clock ? window.rv_sched_data.settings.meta.clock : '12hour', 
                            'event_book_multiples':  event_book_multiples, 
                            'event_book_others':  event_book_others, 
                            'id': window.rv_sched_data?.id || 'no-set',
                        };
export const configuration = {'isServer': window.isServer? window.isServer : false, tokenname: window.tokenname || '', tokenvalue: window.tokenvalue || '' };

