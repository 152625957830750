import React, { useContext} from 'react';
import styles from './Login.module.scss';
import Moment from 'react-moment';
import moment from 'moment';
import { CartControllerContext } from '../contexts/CartControllerContext';
import { ScheduleContext } from '../contexts/ScheduleContext';
import { ModalContext } from '../contexts/ModalContext';
import {BookingFilterContext} from '../contexts/BookingFilterContext'
import {  settings } from '../services/scheduleList';


function SelectBlock({object,  enabled, active, onSelect}) {

    //console.log(enabled);
    return (
        <React.Fragment>
        {
            enabled === false && active === true &&
            <div className={styles.p__dateBox} onClick={(e) => {onSelect(true, object)}}>
                <div className={styles.p__dateBoxDay}><Moment format="DD MMM">{object.datetime}</Moment></div>
                <div className={styles.p__dateBoxYear}><Moment format="YYYY">{object.datetime}</Moment></div>
                <div className={styles.p__dateBoxTime}><Moment format="hh:mma">{object.datetime}</Moment></div>
            </div>               
        }
        {
        enabled === true && active === true &&
        <div className={styles.p__dateBoxSelected} onClick={(e) => {onSelect(false, object)}}>
            <div className={styles.p__dateBoxDay}><Moment format="DD MMM">{object.datetime}</Moment></div>
            <div className={styles.p__dateBoxYear}><Moment format="YYYY">{object.datetime}</Moment></div>
            <div className={styles.p__dateBoxTime}><Moment format="hh:mma">{object.datetime}</Moment></div>
        </div>               
        }
        {
        active === false &&
        <div className={styles.p__dateBoxInactive} onClick={(e) => {}}>
            <div className={styles.p__dateBoxDay}><Moment format="DD MMM">{object.datetime}</Moment></div>
            <div className={styles.p__dateBoxYear}><Moment format="YYYY">{object.datetime}</Moment></div>
            <div className={styles.p__dateBoxTime}>at <Moment format="hh:mma">{object.datetime}</Moment></div>
        </div>              
        }
        </React.Fragment>


    )
  }


const CartControllerPanelSelectAssociated = ({show, dates, this_obj, onCancel, onAddDates}) => {

   // const { cache, clearCache, paymentOptions } = useContext(CartContext);

   const [attachedDates, setAttachedDates] = React.useState([]);
   const {countInCartStrict, countInCart} = useContext(CartControllerContext);
   const {getAvailabilityForEventTicket, getAvailabilityForEventTicketLimited} = useContext(ScheduleContext);
   const {modalOpen, modalClose} = React.useContext(ModalContext);
   const { isEventFiltered } = useContext(BookingFilterContext);



   React.useEffect(()=>{
       modalOpen();
       // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


   const isAvailable = ( product ) =>{
        let ticket_avail = 0;
        let pending_cart = 0;
        if(this_obj?.key_3?.max >= 0)
        {
            ticket_avail = getAvailabilityForEventTicketLimited(product.eid, product.id, this_obj?.key_3, this_obj?.key_3?.max );
            pending_cart = countInCartStrict(product.eid, product.id, this_obj.key_3);
            if(ticket_avail)
            {
                if(ticket_avail > this_obj?.key_3?.max )
                {
                    ticket_avail = this_obj?.key_3?.max;
                }
            }
        }
        else
        {
            ticket_avail = getAvailabilityForEventTicket(product.eid, product.id, this_obj.key_3 );
            pending_cart = countInCartStrict(product.eid, product.id, this_obj.key_3);
        }
        const avail = ticket_avail - pending_cart;
        //console.log("isAvailable",  avail)
        if(avail > 0)
        {
            console.log("Settings", settings.event_book_multiples, isEventFiltered( product.eid, product.id));
            if((isEventFiltered( product.eid, product.id) === true || (countInCartStrict(product.eid, product.id, this_obj.key_3) > 0)) && settings.event_book_multiples === false && settings.event_book_others === false)
            {
                return false;
            }
            return true;
        } 
        return false;
   }
   //console.log('dates ',dates);
   const isFuture = ( obj ) => {

        var now = moment()
        if(moment(obj.datetime).isAfter(now))
        {
            return true;
        }
        return false;
    }
    const isAttached = (product) => {
        return !!attachedDates.find(item => {
            return item.key_1 === product.eid && item.key_2 === product.id
        });
    }


    const handleClose = () => {
        modalClose();
        onCancel();
    }

    const onContinue = () => {
        var x = JSON.parse(JSON.stringify(attachedDates));
        x.unshift(this_obj);
        modalClose();
        onAddDates(x, 1);
    }
  

    const onBlockSelect = ( state, o ) => {
        //console.log('selected', o);
        var x = JSON.parse(JSON.stringify(attachedDates));
        if(state === true)
        {

            const the_obj = {
                key_1: o.eid,
                key_2: o.id,
                key_3: this_obj.key_3,
            }
            //x = x.filter(obj => !(obj.key_1 === o.eid && obj.key_2 === o.id && obj.key_3.id === this_obj.key_3.id));
            x.push(the_obj);
            setAttachedDates(x);
        }
        else
        {
            x = x.filter(obj => !(obj.key_1 === o.eid && obj.key_2 === o.id && obj.key_3.id === this_obj.key_3.id));
            setAttachedDates(x);

        }

    }

    const thisdate = () => {
        let d = dates.filter(p => {
            return ((p.eid === this_obj.key_1 && p.id === this_obj.key_2))
        });
        if(d.length > 0)
        {
            return d[0].datetime;

        }
        return null;
    }

    const onSelectAll = () => {
        const d = dates.filter(p => {
            console.log(p, this_obj.key_1, p.id, this_obj.key_2);
            return (!(p.eid === this_obj.key_1 && p.id === this_obj.key_2))
        });
        var x = [];
        for(var i = 0; i < d.length; i++)
        {
            const the_obj = {
                key_1: d[i].eid,
                key_2: d[i].id,
                key_3: this_obj.key_3,
            }
            x.push(the_obj);            
        }        
        setAttachedDates(x);
    }

    return (
        <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, display: show ?'block':'none'}}>
            <div className={"modal "  + ( show? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Associated dates</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    {
                        <div>
                            <p className="text-center mt-2"><b>Thank you for selecting <Moment format="LLL">{thisdate()}</Moment></b>. This event has additional associated dates. Please select any additional dates and when you are ready click the continue button below:</p>

                            <div className="container-fluid no-gutters">
                                <div className={"row justify-content-center " }>
                                {
                                    dates.filter(p => {
                                        //console.log(p, this_obj.key_1, p.id, this_obj.key_2);
                                        return (!(p.eid === this_obj.key_1 && p.id === this_obj.key_2))
                                    }).map( product => (    
                                        isFuture( product ) &&
                                        <div className={"col-4 col-sm-4 col-md-3 col-lg-2 mb-1 " + styles.p__dateRowBox}  key={product.eid + '-' + product.did + '-' + product.id}>
                                            <SelectBlock object={product} onSelect={onBlockSelect} enabled={isAttached(product)} active={isAvailable( product )}/>
                                        </div>       
                                    ))
                                }  
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center mt-2">{attachedDates.length} additional dates selected.</div>
                                </div>   
                                <div className="row">
                                    <div className="col-12 text-right mt-2"><a href="#" className={styles.p__selectMore} onClick={(e) => {e.preventDefault(); onSelectAll()}}>Select all</a></div>
                                </div>                                                                   
                            </div>          
                        </div>

                    }
                         
                    </div>
                    <div className="modal-footer">
                        <div className={styles.p__rVieModalFooterText}>What would you like to do?</div>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={onContinue}>Continue</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
  

  export default CartControllerPanelSelectAssociated;