import React, { useContext } from 'react';
import { TrashIcon } from '../../components/icons'
import { CartControllerContext } from '../../contexts/CartControllerContext';
import Moment from 'react-moment';
import ErrorPrompt from './ErrorPrompt';
import styles from './CartProducts.module.scss';

const SubscriptionItem = ({product}) => {

    const { detachSubscription, decrementPassEventQty } = useContext(CartControllerContext);
    const [ error, setError] = React.useState(false)


    const onDecrementQty = ( obj ) => {
        const x = {
            wallet_id: product.id,
            id: obj.id
        };


        decrementPassEventQty( x );        
    }

    return (
        <React.Fragment>
        <div className="row">

            <div className="col-10 col-md-6 pt-2 pb-2">
                <h5 className={"mb-1 " + styles.p__cartItemName}>Subscription: {product.name}.</h5>
                {
                    parseInt(product.period) === 0 && 
                    <p className={"mb-1 " + styles.p__payInfo}>Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits:  {product.credits}. Auto-renews: every {product.expire_days} day(s)</p>
                }
                {
                    parseInt(product.period) === 1 && 
                    <p className={"mb-1 " + styles.p__payInfo}>Expires: {<Moment format="LLL">{product.expires}</Moment>}. Credits:  {product.credits}. Auto-renews: every {product.expire_days} month(s)</p>
                }
            </div>
            <div className="col-2 col-md-6 p-0 text-right ">
            {
                     <button
                    onClick={() => detachSubscription(product)}
                    className={"btn rvIconButton rvDangerIconButton  " + styles.p__dangerSmallButton}>
                        <TrashIcon width={"20px"}/> <div>remove</div>
                    </button>
                 }                 
            </div>
        </div> 
        <div className="row">
                <div className="col-12 p-0 pb-2">
                    <div className="container-fluid no-gutters">
                        {
                            product.transactions.filter(transaction => transaction.objects.length > 0).map( (transaction, index) => (

                                <div className="row no-gutters" key={'pe-' + index}>
                                    <div className="col-12 col-md-6 pt-1">
                                        <h5 className={"mb-1 ml-4 " + styles.p__cartSubItemName}>{index+1}. {transaction.objects[0].name}</h5>
                                        {
                                            transaction.objects.map( event => (                                                
                                            <React.Fragment key={'tx-' + event.key_1 + '-' + event.key_2 + '-' + event.key_3.id}>
                                                    <p className="mb-1 ml-4 ">{<Moment format="LLL">{event.date + "T" + event.time}</Moment>}: Price: {event.key_3.credits} pass credit(s) per space </p>
                                            </React.Fragment>

                                            ))
                                        }
                                    </div>
   
                                    <div className="col-12 col-md-6 text-right">
                                        <button
                                        onClick={() => onDecrementQty(transaction)}
                                        className={"btn  rvIconButton rvDangerIconButton " + styles.p__dangerSmallButton}>
                                            <TrashIcon width={"20px"}/> <div>remove</div>
                                        </button>
                                    </div>                                  
                                </div>
                            ))
                        }

                    </div>                    
                </div>
        </div>  
        {
            <ErrorPrompt show={error} onClose={setError}/>
        }         
        </React.Fragment> 

     );
}
 
export default SubscriptionItem;