import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import ProductsContextProvider from './contexts/ProductsContext';
import WaitContextProvider from './contexts/WaitContext';
import ScheduleContextProvider from './contexts/ScheduleContext';
import PreferenceContextProvider from './contexts/PreferenceContext';
import CartControllerContextProvider from './contexts/CartControllerContext';
import {ModalContext} from './contexts/ModalContext';
import Routes from './routes';


import './index.scss';
import './index.css';
import BookingContextProvider from './contexts/BookingFilterContext';

const App = () => {

    const {isOpen} = React.useContext(ModalContext);

    return ( 
        <div className="rvNS">
            <div className={"owrap " + (isOpen()?'mopen': '')}>
                <div className="iwrap">
                <HelmetProvider>
                    <ProductsContextProvider>
                        <BookingContextProvider>
                            <ScheduleContextProvider>
                                <PreferenceContextProvider>
                                    <CartControllerContextProvider> 
                                        <WaitContextProvider>
                                            <Routes />
                                        </WaitContextProvider>
                                    </CartControllerContextProvider>
                                </PreferenceContextProvider>
                            </ScheduleContextProvider>
                        </BookingContextProvider>
                    </ProductsContextProvider>
                </HelmetProvider>
                </div>
            </div>
        </div>
    
     );
}
 
export default App;