import React, { useContext } from 'react';
import { TrashIcon } from '../../components/icons'
import { CartControllerContext } from '../../contexts/CartControllerContext';
import { PreferenceContext } from '../../contexts/PreferenceContext';
import { isFree, isCardOnly, isPassOnly, isCardandPass } from '../../contexts/ScheduleContext';

import { formatNumber } from '../../helpers/utils';
import Moment from 'react-moment';
import styles from './CartProducts.module.scss';

const CartItem = ({product, index}) => {

    const { decrementCardEventQty } = useContext(CartControllerContext);
    const { preference } = useContext(PreferenceContext);


    const onDecrementQty = ( obj, i ) => {
        const x = {
            id: obj.id,
            instance: i,
        };
        decrementCardEventQty( x);        
    }


    return ( 
        <div className="row">

            <div className="col-10 col-md-6 pt-2 pb-2">
                <h5 className={"mb-1 rVieItemTitle " + styles.p__cartItemName}>{product.objects[0].name}</h5>
                <p className="mb-1">Quantity: {product.objects[0].qty}</p>
                {
                    isPassOnly(product.objects[0].payment_type) && !isCardOnly(product.objects[0].payment_type) && !isCardandPass(product.objects[0].payment_type)&& !isFree(product.objects[0].payment_type)&& <p className="mb-1">Price: {product.objects[0].key_3.credits} pass credit(s) per date/time </p>
                    
                }
                {
                    isPassOnly(product.objects[0].payment_type) && !isCardOnly(product.objects[0].payment_type)&& !isCardandPass(product.objects[0].payment_type)&& !isFree(product.objects[0].payment_type)&& <p className="mb-1">Price: {formatNumber(product.objects[0].key_3.price, preference.currency)} per date/time</p>
                    
                }     
                {
                    !isPassOnly(product.objects[0].payment_type) && isCardOnly(product.objects[0].payment_type)&& !isCardandPass(product.objects[0].payment_type)&& !isFree(product.objects[0].payment_type)&&
                        <p className="mb-1">Price: {formatNumber(product.objects[0].key_3.price, preference.currency)} per date/time</p>
                    
                } 
                {
                    !isPassOnly(product.objects[0].payment_type) && !isCardOnly(product.objects[0].payment_type)&& isCardandPass(product.objects[0].payment_type)&& !isFree(product.objects[0].payment_type)&&
                        <p className="mb-1">Price: {formatNumber(product.objects[0].key_3.price, preference.currency)} or {product.objects[0].key_3.credits} pass credit(s) per date/time</p>
                    
                }   
                {
                    !isPassOnly(product.objects[0].payment_type) && !isCardOnly(product.objects[0].payment_type)&& !isCardandPass(product.objects[0].payment_type)&& isFree(product.objects[0].payment_type)&& 
                        <p className="mb-1">Price: Free </p>
                    
                }
                <ul className={styles.p__dateList}>
                {
                    product.objects.map( object => (
                        <li className={"mb-1 " + styles.p__date} key={'tx-' + object.key_1 + '-' + object.key_2}>{<Moment format="LLL">{object.date + "T" + object.time}</Moment>} </li>
                    ))
                } 
                </ul> 



            </div>

            <div className="col-2 col-md-6 p-0 text-right ">
                    <button
                    onClick={() => onDecrementQty(product, index)}
                    className={"btn rvIconButton rvDangerIconButton " + styles.p__dangerSmallButton}>
                        <TrashIcon width={"20px"}/> <div>remove</div>
                    </button>
                
            </div>
        </div>
     );
}
 
export default CartItem;