import React, { useContext, useState} from 'react';
import styles from './Login.module.scss';
import Moment from 'react-moment';
import { ModalContext } from '../contexts/ModalContext';

const CartControllerPanelConfirmPassesAdded = ({ show, passes, onDone, onContinue}) => {

    const [type, setType] = useState('existing');


    const {modalOpen, modalClose} = React.useContext(ModalContext);
  


    React.useEffect(()=>{
        modalOpen();
    }, []);

    const handleClose = () => {
        modalClose();
        onDone();
    }


    const onDoNext = ( ) => {
        modalClose();
        onContinue(true);
    }


    return (
        <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, display: show ?'block':'none'}}>
            <div className={"modal fade-in2s "  + ( show? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                <div className={"modal-dialog modal-lg "+ styles.p__modal} role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Passes fetched</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form >
                        {
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 mb-2 mt-2">
                                        Thank you, we have fetched the following pass(es) from your account. These can now be used to book compatible events. We now need to check this pass against your choosen event.
                                    </div>
                                </div>
                                <div className="card card-body border-1">
                                    <div className="row">
                                        <div className="col-sm-12 mb-1 mt-2">
                                            <ul>
                                                {
                                                    passes.map(pass => (
                                                        <li key={pass.id}>{pass.name}. Credits available: {pass.credit_available}. Expires: <Moment format="LL">{pass.expires}</Moment></li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    
                                </div>



                            </div>

                        }
                    </form>  
                    </div>
                    <div className="modal-footer">
                        <div className={styles.p__rVieModalFooterText}>What would you like to do?</div>
                        <button type="button" className="btn btn-primary" onClick={onDoNext}>Proceed</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    );
  }
  

  export default CartControllerPanelConfirmPassesAdded;