import React, { useContext} from 'react';
import styles from './Login.module.scss';
import Moment from 'react-moment';
import moment from 'moment';
import { formatNumber } from '../helpers/utils';
import { PreferenceContext } from '../contexts/PreferenceContext';
import { ModalContext } from '../contexts/ModalContext';


const CartControllerPanelSelectCourseAssociated = ({show, dates, objects, onAddDates, max, onCancel}) => {

   // const { cache, clearCache, paymentOptions } = useContext(CartContext);

   const { preference } = useContext(PreferenceContext);
   const [q, setQ] = React.useState(max>0?1:0);
   const {modalOpen, modalClose} = React.useContext(ModalContext);
  


   React.useEffect(()=>{
       modalOpen();
   }, []);

   const isFuture = ( obj ) => {

        var now = moment()
        if(moment(obj.datetime).isAfter(now))
        {
            return true;
        }
        return false;
    }

    const calculatePriceCard = () => {
        let t = 0;
        t = objects.reduce( (t, o)=>{
            //console.log("card price reducer", o, dates);
            return t + (parseFloat(o.key_3.price) * parseFloat(o.qty) * dates.length);
        }, 0);
        return t;
    } 


    const calculatePassCard = () => {
        let t = 0;
        t = objects.reduce( (t, o)=>{
            //console.log("pass price reducer", o, dates);
            return t + (parseFloat(o.key_3.credits) * parseFloat(o.qty) * dates.length);
        }, 0);
        return t;
    } 




    const handleClose = () => {
        modalClose();
        onCancel();
    }

    const onContinue = () => {

        const date_objects_to_add = objects.map( o => {
            return dates.filter( product => isFuture( product ) ).map( product => {
                return {
                    key_1: product.eid,
                    key_2: product.id,
                    key_3: o.key_3,
                }
            })
        }).flat();

        //console.log("adding dates", date_objects_to_add)
        modalClose();
        onAddDates(date_objects_to_add, 1);
    }


    console.log('objects', objects);
  
    return (
        <form>
            <div className="overlay" style={{'backgroundColor': 'rgba(0,0,0,0.8)', 'position': 'fixed', 'top': 0, "bottom": 0, "left": 0, 'right': 0, display: show ?'block':'none'}}>
                <div className={"modal fade-in2s "  + ( show? 'show': '')} style={{'display': 'block', 'position': 'fixed', 'overflowY': 'auto'}} role="dialog" >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Associated dates</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 mb-2 mt-2">
                                        <p>The selected event is part of a course. When booking a course, you must select all associated dates.</p>
                                    </div>
                                </div>
                                    <div className="row">
                                        <div className="col-8 col-md-3 mb-2">
                                            <h6 className={styles.p__strong}>Date</h6>
                                        </div>
                                        <div className="d-none d-sm-block d-md-block col-md-6 text-left mb-2">
                                            <h6 className={styles.p__strong}>Time</h6>
                                        </div>                                   
                                        <div className="col-4 col-md-3 text-center mb-2">
                                            <h6 className={styles.p__strong}>Price</h6>
                                        </div>
                                    </div>
                                    {
                                        objects.map( object => (
                                            dates.map( product => ( 
                                                isFuture( product ) &&
                                                <div className="row" key={product.id}>
                                                    <div className="col-8 col-md-3 mb-1"><Moment format="LL">{product.datetime}</Moment> <span className="d-block d-sm-none"><Moment format="hh:mm a">{product.datetime}</Moment></span></div>
                                                    <div className="d-none d-sm-block d-md-block col-md-6 text-left mb-1"><Moment format="hh:mm a">{product.datetime}</Moment></div>
                                                    {
                                                        parseInt(object.payment_type) === 0x01 &&
                                                        <div className="col-4 col-md-3 text-center mb-1">{object.key_3.credits}</div>
                                                    }
                                                    {
                                                        parseInt(object.payment_type) === 0x02 &&
                                                        <div className="col-4 col-md-3  text-center mb-1">{formatNumber(object.key_3.price, preference.currency)}</div>
                                                    }
                                                    {
                                                        parseInt(object.payment_type) === 0x03 &&
                                                        <div className="col-4 col-md-3 text-center mb-1">{formatNumber(object.key_3.price, preference.currency)} or {object.key_3.credits} credits</div>
                                                    }
                                                    {
                                                        parseInt(object.payment_type) === 0x04 &&
                                                        <div className="col-4 col-md-3  text-center mb-1">{formatNumber(0, preference.currency)}</div>
                                                    }                                            
                                                </div>
        
                                            ))  
                                        ))

                                    }
 
                                    <div className="row border-top pt-2">
                                        <div className="col-8 col-md-9 mb-2 text-right">
                                            <p className={styles.p__strong}>Total</p>
                                        </div>                                  
                                        <div className="col-4 col-md-3 text-center mb-2">
                                            {
                                                    parseInt(objects[0].payment_type) === 0x01 &&
                                                    dates.length > 0 && <p className={styles.p__strong}>{calculatePassCard()}</p>
                                            }
                                            {
                                                    parseInt(objects[0].payment_type) === 0x02 &&
                                                    dates.length > 0 && <p className={styles.p__strong}>{formatNumber(calculatePriceCard(), preference.currency)}</p>
                                            }
                                            {
                                                    parseInt(objects[0].payment_type) === 0x03 &&
                                                    dates.length > 0 && <p className={styles.p__strong}>{formatNumber(calculatePriceCard(), preference.currency)} or {calculatePassCard()}</p>
                                            }  
                                            {
                                                    parseInt(objects[0].payment_type) === 0x04 &&
                                                    dates.length > 0 && <p className={styles.p__strong}>Free</p>
                                            }                                                                                
                                        </div>
                                    </div>                                 
                                </div>
                        </div>
                        <div className="modal-footer">
                            <div className={styles.p__rVieModalFooterText}>What would you like to do?</div>
                            <button type="submit" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>Cancel</button>
                            <button type="submit" className="btn btn-primary" onClick={onContinue}>Add all dates to cart</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    );
  }
  

  export default CartControllerPanelSelectCourseAssociated;